<template>
  <div class="post radius-box-8 padding-box-16">
    <ModalEmbed
      v-on:points-change="$emit('points-change')"
      @change="onChange"
      ref="modal-embed" />

    <div class="header">
      <div class="avatar">
        <img :src="post.user.thumbnail" referrerpolicy="no-referrer"/>
      </div>
      <div class="title">
        <div>
          <h4>{{post.user.first_name}} {{post.user.last_name}}</h4>
          <p>{{$moment(post.created_at).fromNow()}}</p>
          <div class="reward-badge tags pb-2">
            <b-badge
              v-if="isInternational"
              variant="primary">
              <v-icon name="globe" />
              Internacional
            </b-badge>
            <b-badge
              v-if="post.reward > 0"
              variant="primary">
              <v-icon name="gem" />
              {{post.reward}} Aportes de recompensa
            </b-badge>
            <b-button
              size="sm"
              v-if="$store.state.login.email === post.user.email && !post.comments.length && post.reward > 0"
              variant="secondary"
              @click="restore_points"
            >
              Cancelar
            </b-button>
          </div>
        </div>
      </div>
      <div class="right-options">
        <div v-if="!post.can_like && $store.state.login.token" class="assessment on">
          <span class="text-primary bold">
            Valorado
            <v-icon name="c-star-on" scale="1.5" class="text-primary icon" />
          </span>
        </div>
        <div v-else class="assessment off">
          <span @click="like_post(1, post.id)">
            Valorar
            <v-icon name="c-star-off" scale="1.5" class="icon" />
          </span>
        </div>
        <div class="more">
          <b-dropdown size="sm" variant="black" no-caret>
            <template v-slot:button-content>
              <v-icon name="ellipsis-h" />
            </template>
            <b-dropdown-group header="Opciones del post">
              <b-dropdown-item @click="markAsSpam" v-if="$store.state.login.token">Es Pubicidad</b-dropdown-item>
              <b-dropdown-item @click="report_post" v-if="$store.state.login.token">Reportar</b-dropdown-item>
              <b-dropdown-item
                v-if="$store.state.login.first_name === post.user.first_name &&
                    $store.state.login.last_name === post.user.last_name"
                @click="delete_post"
              >Eliminar</b-dropdown-item>
            </b-dropdown-group>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-group header="Compartir">
              <b-dropdown-item @click="copy(currentPage+'post/'+post.id)">
                <v-icon name="copy" />
                Copiar al portapapeles
              </b-dropdown-item>
              <!-- <b-dropdown-item>
                <facebook-button :url="currentPage+'post/'+post.id" :description="post.text" />
              </b-dropdown-item>
              <b-dropdown-item>
                <twitter-button :url="currentPage+'post/'+post.id" :description="post.text" />
              </b-dropdown-item> -->
            </b-dropdown-group>
          </b-dropdown>
        </div>
      </div>
    </div>

    <div class="tags">
      <b-button
        disabled
        v-for="(subtag, index) in post.subtags"
        :key="'subtag-'+index"
        size="sm"
        v-if="!isInternational || !countries.includes(subtag.text)"
        variant="light"
        @click="get_posts_by_text(subtag.text)"
      ><div class="black-font">{{subtag.text}}</div></b-button>
      <b-button
        size="sm"
        :id="'post-'+post.id+'subtags'"
        v-if="$store.state.login.token"
        variant="secondary"
      >
        <v-icon name="plus" scale="0.75" />
      </b-button>
      <b-popover triggers="click" ref="popover" :target="'post-'+post.id+'subtags'">
        <template v-slot:title>
          <b-button @click="$refs.popover.$emit('close')" variant="outline" class="close" aria-label="Close">
            <span class="d-inline-block" aria-hidden="true">&times;</span>
          </b-button>
          Categoriza el post!
        </template>
        <multiselect
          v-model="subtags_to_add"
          :options="tags"
          :multiple="true"
          group-values="subtags"
          group-label="text"
          :group-select="true"
          :custom-label="customLabel"
          placeholder="Añadir más etiquetas"
          select-label="Seleccionar"
          deselectLabel="Presiona INTRO para remover"
          track-by="text"
        >
          <span slot="noOptions">No puedes agregar mas categorias</span>
          <span slot="noResult">No se ha encontrado ninguna categoria</span>
        </multiselect>
        <b-button
          size="sm"
          @click="add_subtags">
          Agregar
        </b-button>
      </b-popover>
    </div>

    <p class="content" style="white-space: pre-wrap;" v-html="textWithUrl" />
    <p class="content d-inline"
      v-for="(url, index) in urlsImageVideos"
      :key="index"
      v-html="transformInEmbed(url)"/>
    <div v-if="post.files && post.files.length" class="docs-data">
      <ul>
        <li
          v-for="(doc, index) in post.files"
          :key="'doc-'+index"
          v-on:click="$refs['modal-embed'].open({
            postid: post.id,
            openUrl: doc.view_link,
            title: doc.short_name,
            stars: doc.rate,
            fileid: doc.id,
            subtags: doc.subtags
          })"
        >
          <v-icon name="c-doc" class="text-primary" />
          {{doc.short_name}}
        </li>
      </ul>
    </div>

    <div v-if="post.has_poll" class="poll-data">
      <h4>
        {{post.poll.question}}
        <span class="total-votes">{{getVotesCount()}} votos</span>
      </h4>
      <b-container>
        <b-row
          v-for="(option, index) in post.poll.answers"
          :key="'option-'+index"
          v-bind:class="{bold: isMajor(option.votes)}">
          <b-container>
            <b-row>
              <strong>{{option.text}}</strong>
            </b-row>
            <b-row>
              <b-col cols="9" class="pt-2">
                <b-progress
                  :value="option.votes"
                  :max="getVotesCount()==0? 1:getVotesCount()"
                  variant="primary"
                  show-value></b-progress>
              </b-col>
              <b-col cols="2">
                {{Math.round(option.votes * 100 / (getVotesCount()==0? 1:getVotesCount()))}}%
              </b-col>
              <b-col cols="1">
                <b-button
                  variant="primary"
                  size="sm"
                  @click="vote_poll({value: option.value})"
                  :disabled="!post.can_vote"
                >
                  <v-icon name="vote-yea" scale="0.5" />
                </b-button>
              </b-col>
            </b-row>
          </b-container>
        </b-row>
        <b-row v-if="$store.state.login.token">
          <b-col cols="9">
            <b-form-input
              v-model="post_option"
              placeholder="Nueva opción..."
              v-on:keyup.enter="addPollOption"
              size="sm"
              v-if="addOption"
            />
          </b-col>
          <b-col cols="3" class="progressbar add-option no-padding" @click="showOrAddOption">
            <span style="cursor: pointer;">Añadir alternativa</span>
            <b-button variant="light" size="sm">
              <v-icon name="plus" scale="0.5" />
            </b-button>
          </b-col>
        </b-row>
      </b-container>

    </div>

    <b-overlay :show="loading" rounded="sm">
      <div class="comment">
        <div class="input-content">
          <b-form-input
            v-model="post_comment"
            placeholder="Escribir comentario..."
            class="input"
            v-on:keyup.enter="publish_comment"
          />
          <v-icon
            name="c-attach"
            scale="1.75"
            class="icon"
            v-b-tooltip.hover
            title="Adjuntar archivos (Recuerda no adjuntar material protegido por propiedad intelectual)"
            @click="$refs.file.$el.childNodes[0].click()"
          />
          <b-form-file
            v-model="files"
            ref="file"
            style="display:none;"
            accept=".docx, .doc, .xlsx, .xls, .pdf, .ppt, .pptx, .png, .jpg, .jpeg"
            multiple
          ></b-form-file>
          <b-button
            class="button radius-box-24 bold"
            variant="primary"
            @click="publish_comment"
          >Comentar</b-button>
        </div>
        <div class="simple-info simple-list">
          <ul>
            <li>
              <v-icon name="c-star-on" scale="0.75" class="text-primary" />
              {{post.likes}}
            </li>
            <li @click="toggleComments" style="cursor: pointer">{{comments.length}} Comentarios</li>
          </ul>
        </div>
      </div>
    </b-overlay>

    <div v-if="files.length > 0" class="docs-data">
      <ul>
        <li v-for="(doc, index) in files" :key="'doc-'+index">
          <v-icon name="c-doc" class="text-primary" />
          {{doc.name}} - {{doc.size | prettyBytes}}
        </li>
      </ul>
    </div>

    <div v-if="comments.length && showComments" class="list-comments">
      <hr />
      <div
        v-for="(comment, index) in this.comments"
        class="item-comment"
        :key="'comment-'+index"
      >
        <CommentComponent @change="onChange" v-on:points-change="$emit('points-change')" :comment="comment" :index="index" :post="post"/>
      </div>
      <p class="load-comments">Ver más comentarios</p>
    </div>
  </div>
</template>

<script>
import ModalEmbed from "../../utopie/modals/ModalEmbed";
import postService from "../../../services/postService";
import informationService from "../../../services/informationService";
import CommentComponent from "./CommentComponent";
// import FacebookButton from "vue-share-buttons/src/components/FacebookButton";
// import TwitterButton from "vue-share-buttons/src/components/TwitterButton";
import anchorme from "anchorme";

export default {
  components: {
    ModalEmbed,
    CommentComponent,
    // FacebookButton,
    // TwitterButton
  },
  props: {
    post: { type: Object, default: () => Object() },
    show_results: { type: Boolean, default: () => false },
    self_index: { default: () => -1 },
    custom_tags: { type: Array, default: () => []},
    countries: {type: Array, default: () => []}
  },
  watch: {
    custom_tags: function () { this.get_tags() },
    urlID: function(newValue) {
      this.get_post_data(newValue);
    }
  },
  created() {
    if (this.$route.params.id) {
      this.get_post_data(this.$route.params.id);
      this.getCountries()
    }
    this.get_tags();
  },
  computed: {
    isInternational: function() {
      var subtagsTexts = this.get_tags();
      const isInSubtags = (countryTag) => subtagsTexts.includes(countryTag)
      return (this.countries.length && this.countries.every(isInSubtags))
    },
    urlID: function() {
      return this.$route.params.id;
    },
    textWithUrl: function() {
      return anchorme(this.post.text)
    },
    urlsImageVideos: function() {
      //eslint-disable-next-line
      var words = this.post.text.replace("\r", " ").replace("\t", " ").replace("\n", " ").split(" ");
      var videosAndImages = [];
      for(var i=0; i<words.length;i++) {
        //eslint-disable-next-line
        if (words[i].match(/youtube\.com\/watch\?v=/) !== null || words[i].match(/.*\.(png|jpg|gif)$/) !== null)
          videosAndImages.push(words[i])
      }
      return videosAndImages;
    }
  },
  data() {
    return {
      post_comment: "",
      files: [],
      subtags_to_add: [],
      tags: [],
      comments: this.post.comments,
      showComments: false,
      addOption: false,
      post_option: "",
      currentPage: window.location.href,
      loading: false,
    };
  },
  methods: {
    transformInEmbed: function(url) {
      return anchorme({ input: url,
        options: {
          exclude: true,
          specialTransform: [
            {
                //eslint-disable-next-line
                test: /.*\.(png|jpg|gif)$/,
                transform: s =>
                    `<img src="${s.startsWith("http://") ? s : `http://${s}`}">`
            },
            {
                //eslint-disable-next-line
                test: /youtube\.com\/watch\?v\=/,
                transform: str =>
                    `<iframe allowfullscreen src="https://www.youtube.com/embed/${str.replace(
                        /.*watch\?v=(.*)$/,
                        "$1"
                    )}"/>`
            },
        ],
      }})
    },
    getCountries: function() {
      informationService.getCountries().then(
        data => {
          var countries = Object.keys(data.countries).map(function(key){
              return data.countries[key];
          });
          this.countries = countries;
        }
      )
    },
    copy: function(text) {
      navigator.clipboard.writeText(text);
      this.$toasted.success('Enlace copiado!');
    },
    clearCommentFields: function() {
      this.post_comment = "";
      this.files = []
    },
    customLabel({ text }) {
      return `#${text}`;
    },
    toggleComments: function() {
      this.showComments = !this.showComments;
    },
    getVotesCount() {
      let count = 0;

      for (const key in this.post.poll.answers) {
        count += this.post.poll.answers[key].votes;
      }
      return count;
    },
    isMajor(votes) {
      let major = -1;

      for (const key in this.post.poll.answers) {
        if (this.post.poll.answers[key].votes > major) {
          major = this.post.poll.answers[key].votes;
        }
      }

      return major === votes;
    },
    add_subtags: function() {
      postService.add_subtags({ subtags: this.subtags_to_add, post_id: this.post.id}).then(
        data => {
            this.subtags_to_add = []
            this.get_post_data(this.post.id)
            this.$refs.popover.$emit('close')
            if (data.add) {
              this.$emit("points-change")
            }
            this.$toasted.success(data.message)
            this.get_tags()
        }
      )
    },
    get_post_data: function(id) {
      postService.get_post_by_id(id).then(
        data => {
          this.post = data[0];
          this.comments = this.post.comments;
        },
        error => {
          console.error(error);
        }
      );
    },
    onChange: function() {
      this.get_post_data(this.post.id);
    },
    restore_points: function() {
      postService.restore_points({ id: this.post.id}).then(
        () => {
          this.onChange()
          this.$emit("points-change")
        }
      )
    },
    showOrAddOption: function() {
      if (!this.addOption)
        this.addOption=true;
      else
        this.addPollOption()
    },
    addPollOption: function() {
      if (this.post_option === '') {
        this.$toasted.error('Debes ingresar texto!')
        return;
      }
      postService.addPollOption({option: this.post_option, post_id: this.post.id}).then(
        data => {
          this.$toasted.success(data.message)
          this.onChange()
          this.addOption = false;
          this.post_option = "";
        }
      )
    },
    publish_comment: function() {
      this.loading = true;
      let formData = new FormData();
      formData.append("text", this.post_comment);
      formData.append("post", this.post.id);
      // add files to the form data
      for (var j = 0; j < this.files.length; j++) {
        formData.append("files", this.files[j]);
      }
      if (this.$store.state.login.token) {
        if (this.post_comment) {
          postService.publish_comment(formData).then(
            data => {
              this.$toasted.success(data.message);
              this.clearCommentFields();
              this.loading = false;
              this.get_comments();
              this.showComments = true;
            },
            error => {
              this.loading = false;
              this.$toasted.error("Ha ocurrido un error al publicar");
              console.error(error);
            }
          );
        } else {
          this.loading = false;
          this.$toasted.error("Debes escribir algo!");
        }
      } else {
        this.loading = false;
        this.$toasted.error("Debes estar inscrito en la plataforma!");
      }
    },
    get_comments: function() {
      postService.get_comments({ post: this.post.id }).then(
        data => {
          this.comments = data.comments;
        },
        error => {
          console.error(error);
        }
      );
    },
    show_collapse: function() {
      this.expanded = !this.expanded;
      if (this.expanded) {
        this.get_comments();
      }
      this.$scrollTo(this.$el);
    },
    get_posts_by_text: function(text) {
      this.$emit("get-posts-by-text", text);
    },
    like_post: function(like, post) {
      if (this.$store.state.login.token) {
        postService.like_post({like: like, post: post}).then(
          data => {
            this.$toasted.success(data.message);
            this.post.likes = data.likes;
            this.post.can_like = false;
          },
          error => {
            var message = "Ha ocurrido un error al valorar el post";
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              message = error.response.data.message;
            }
            this.$toasted.error(message);
          }
        );
      } else {
        this.$toasted.error("Debes estar inscrito en la plataforma!");
      }
    },
    like_comment: function(like, comment) {
      if (this.$store.state.login.token) {
        postService.like_comment({ like: like, comment: comment }).then(
          data => {
            this.$toasted.success(data.message);
          },
          error => {
            var message = "Ha ocurrido un error al publicar";
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              message = error.response.data.message;
            }
            this.$toasted.error(message);
          }
        );
      } else {
        this.$toasted.error("Debes estar inscrito en la plataforma!");
      }
    },
    calculate_like: function(likes, dislikes) {
      return likes - dislikes;
    },
    vote_poll: function(obj) {
      postService.vote_poll(obj).then(
        data => {
          this.$toasted.success(data.message);
          this.onChange()
        },
        error => {
          var message = "Ha ocurrido un error al votar";
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            message = error.response.data.message;
          }
          this.$toasted.error(message);
        }
      );
    },
    view_file: function(links) {
      this.$emit("view-file", links);
    },
    hide_login_dropdown: function() {
      this.$refs.loginDropdown.hide();
    },
    show_modal_report: function() {
      this.$refs["modal-report"].show();
    },
    show_modal_delete: function() {
      this.$refs["modal-delete"].show();
    },
    report_post: function() {
      postService
        .report_post({ post: this.post.id, report_type: 1 })
        .then(
          data => {
            this.$toasted.success(data.message);
            this.$emit("reload-posts");
          },
          error => {
            var message = "Ha ocurrido un error al denunciar.";
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              message = error.response.data.message;
            }
            this.$toasted.error(message);
          }
        );
    },
    get_tags: function() {
      this.tags = []
      var subtags_text = []
      for (var i=0; i < this.post.subtags.length; i++){
        subtags_text.push(this.post.subtags[i].text)
      }
      for (var j=0; j < this.custom_tags.length; j++) {
        var subtags = []
        for (var k=0; k < this.custom_tags[j].subtags.length; k++) {
          if (!subtags_text.includes(this.custom_tags[j].subtags[k].text)) {
            subtags.push(this.custom_tags[j].subtags[k])
          }
        }
        this.tags.push({ text: this.custom_tags[j].text, subtags: subtags})
      }
      return subtags_text
    },
    markAsSpam: function() {
      postService.markAsSpam({ post: this.post.id }).then(
        data => {
          this.$toasted.success(data.message);
          this.$emit("reload");
        }
      )
    },
    delete_post: function() {
      postService.delete_post({ post: this.post.id }).then(
        data => {
          this.$toasted.success(data.message);
          this.$emit("reload-posts");
        },
        error => {
          var message = "Ha ocurrido un error.";
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            message = error.response.data.message;
          }
          this.$toasted.error(message);
        }
      );
    }
  }
};
</script>

<style lang="scss">
.post {
  width: 100%;
  background-color: white;

  .reward-badge {
    margin-bottom: 5px;
  }

  .simple-list {
    padding: 0;
    padding-left: 20px;

    ul {
      list-style: none;
      height: 100%;
      margin: 0;
      padding: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;

      li {
        display: inline;
        font-weight: bold;
        padding: 0 12px;
        margin: 0;
        font-size: 11px;
        border-left: solid 1px #ccc;
      }
      li:first-child {
        border-left: none;
      }
    }
  }

  .header {
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: space-between;

    .avatar {
      width: 48px;
      height: 100%;

      img {
        width: 100%;
        border-radius: 50%;
      }
    }
    .title {
      width: calc(100% - 48px - 106px);
      height: 100%;
      padding: 0 12px;
      display: flex;
      align-items: center;

      h4,
      p {
        margin: 0;
        padding: 0;
      }
      h4 {
        font-size: 14px;
        font-weight: bold;
      }
      p {
        font-size: 11px;
      }
    }
    .right-options {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;

      .assessment {
        transition: all 0.256s;
        cursor: pointer;
        width: 106px;
        margin-right: 32px;
        text-align: right;
        font-size: 14px;

        .icon {
          margin-top: -6px;
        }
      }
      .assessment.on {
      }
      .assessment.off:hover {
        transition: all 0.256s;
        color: $secondary-dark;
      }

      .more {
        position: absolute;
        top: -12px;
        right: -12px;
      }
    }
  }

  .tags {
    button {
      font-size: 10px;
      padding-left: 12px;
      padding-right: 12px;
      height: 24px;
      font-weight: bold;
    }
  }
  .black-font {
    color: black;
  }

  .content {
    width: 100%;
    padding: 8px 0;
    margin: 0;
    font-size: 18px;
    text-align: justify;
  }

  .docs-data {
    width: 100%;
    padding: 0 24px 12px 24px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding: 6px 6px 6px 12px;
        font-size: 12px;
        font-weight: bold;
        border-left: solid 1px #000;
        cursor: pointer;
      }
    }
  }

  .poll-data {
    position: relative;
    padding: 0 12px 12px 12px;

    .total-votes {
      position: absolute;
      right: 0;
      font-size: 0.8em;
    }

    h4 {
      width: 100%;
      font-weight: bold;
      font-size: 14px;
    }
    table {
      width: 100%;

      tr {
        width: 100%;

        td.description {
          width: 128px;
          padding-left: 12px;
          padding-right: 12px;
          font-size: 11px;
        }
        td.progressbar {
          padding-left: 12px;
          padding-right: 24px;
        }
        td.percent {
          width: 32px;
          font-size: 11px;
        }

        td.add-option {
          text-align: center;
          font-weight: bold;
          cursor: pointer;

          span {
            font-size: 11px;
          }
          button {
            padding: 0 6px;
          }
        }
      }
    }
  }

  .comment {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .input-content {
      width: 100%;
      white-space: nowrap;

      .icon {
        color: $light !important;
        cursor: pointer;
        transition: all 0.256s;
      }
      .icon:hover {
        color: $secondary !important;
        transition: all 0.256s;
      }

      .input {
        width: calc(100% - 78px);
        border: none;
        border-bottom: solid 2px #eee;
        display: inline;
      }
      .button {
        width: 78px;
        font-size: 11px;
        display: inline;
        padding: 3px 0;
      }
    }
  }

  .list-comments {
    width: 100%;

    .item-comment {
      margin: 0 12px;
      padding: 0 12px;
      border-left: solid 2px $primary;

      .header {
        height: 32px;

        .avatar {
          width: 32px;
        }

        .title {
          width: calc(100% - 32px - 106px);
          height: 100%;
        }

        .right-options {
          display: inline;
          text-align: right;
          width: 128px;

          span {
            display: block;
            font-size: 11px;
            font-weight: bold;
          }
        }
      }

      .to-answer.simple-list {
        display: flex;
        justify-content: flex-end;
      }

      .message-row {
        padding-top: 10px !important;
        padding-bottom: 10px !important;

        .message-col {
          padding-left: 1px !important;
          padding-right: 5px !important;
        }
      }
    }

    .load-comments {
      width: 100%;
      margin: 0;
      padding: 0;
      padding-top: -12px;
      text-align: center;
      font-size: 11px;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
</style>
