<template>
  <b-container>
    <b-spinner variant="success" type="grow" label="Spinning" v-if="loading_notifications" />
    <div class="my-notifications radius-box-8 padding-box-16" v-else>
      <ul>
        <li
          :class="notification.seen ? 'mark-as-read' : 'mark-as-unread'"
          v-for="(notification, index) in $store.state.login.notifications"
          :key="'notification-'+index"
        >
          <span class="capfirst" v-html="notification.message">{{notification.message}}&nbsp;</span>
          <span class="time-ago capfirst float-right">{{$moment(notification.created_at).fromNow()}}.</span>
        </li>
      </ul>
      <b-button class="button btn btn-primary" @click="load_notifications">Ver más</b-button>
    </div>

  </b-container>
</template>

<script>
import notificationService from "../../../services/notificationService";

export default {
  created() {
    const vue_scope = this;
    this.get_my_notifications();
    // sockets
    this.$options.sockets.onmessage = payload => {
      const data = JSON.parse(payload.data).message;
      if (data.kind === 'notification'){
        vue_scope.$toasted.success(data.text).goAway(1500);
        this.get_new_notifications();
        this.get_my_last_notifications();
      }
      if (data.kind === 'mission_completed') {
        this.$store.state.login.missions.filter(m => m.mission.mission_code === data.mission)[0].completed = true;
      }
    }
  },
  data: function() {
    return {
      notifications: [],
      last_notification: null,
      older_notification: null,
      loading_notifications: true
    };
  },
  methods: {
    get_my_notifications: function() {
      notificationService.get_my_notifications().then(
        data => {
          const notifications = data.notifications;
          if (notifications.length > 0) {
            this.last_notification = notifications[0];
            this.older_notification = notifications[notifications.length - 1];
          }
          this.$store.commit("login/setNotifications", notifications);
          this.loading_notifications = false;
        },
        error => {
          console.error(error);
          this.loading_notifications = false;
        }
      );
    },
    get_my_last_notifications: function() {
      notificationService.get_my_notifications({
        last: this.last_notification.created_at
      }).then(
        data => {
          const notifications = data.notifications;
          if (notifications.length > 0){
            this.last_notification = notifications[0];
          }
          this.$store.commit("login/addNotification", notifications);
        },
        error => {
          console.error(error);
        }
      );
    },
    load_notifications: function() {
      notificationService.get_my_notifications({
        previous: this.older_notification.created_at
      }).then(
        data => {
          const notifications = data.notifications;
          if (notifications.length > 0){
            this.older_notification = notifications[notifications.length - 1];
          }
          this.$store.commit("login/pushNotification", notifications);
          // TODO hide button when notifications.length == 0
        },
        error => {
          console.error(error);
        }
      );
    }
  }
};
</script>

<style lang="scss">
.my-notifications {
  width: 100%;
  background-color: white;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      position: relative;
      width: 100%;
      padding: 6px 12px;
      font-size: 12px;
      border-bottom: solid 1px $light;

      strong {
        font-size: 1.1em;
      }

      span.time-ago {
        font-size: 0.8em;
        font-style: italic;
        color: $dark;
      }
    }

    li.mark-as-unread::before {
      content: " ";
      position: absolute;
      width: 8px;
      height: 8px;
      left: -4px;
      top: 50%;
      margin-top: -4px;
      border-radius: 4px;
      background-color: $primary;
    }
    li.mark-as-read {
    }
  }
}
</style>
