<template>
  <b-container>
    <div class="posts" :key="posts.length">
      <div
        class="padding-box-normal"
        v-for="(post, index) in posts"
        :key="'post-component-'+index+'-'+post.id"
      >
        <PostComponent
          :countries="countries"
          :post="post"
          v-on:view-file="view_file"
          :show_results="true"
          :self_index="index"
        />
      </div>
      <infinite-loading @infinite="infiniteHandler"></infinite-loading>
    </div>
  </b-container>
</template>

<script>
import PostComponent from "../miscellaneous/PostComponent";
import postService from "../../../services/postService";
import informationService from "../../../services/informationService";

export default {
  components: {
    PostComponent,
  },
  created() {
    this.get_my_posts();
    this.getCountries();
  },
  data: function() {
    return {
      posts: [],
      page: 1,
      mode: 'posts',
      countries: [],
    };
  },
  methods: {
    get_service: function() {
      return postService.get_my_posts;
    },
    get_args: function() {
      return {user: this.$store.state.login.user_id, page: this.page};
    },
    infiniteHandler: function($state) {
      const service = this.get_service();
      const args = this.get_args();
      if(!args.user){
        this.$toasted.error("Ha ocurrido un error con tu sesión, conéctate nuevamente");
        return;
      }
      service(args).then(
        data => {
          if (data.length > 0) {
            this.page += 1;
            this.posts = this.posts.concat(data);
            $state.loaded();
          }
          else {
            $state.complete();
          }
        },
        error => {
          this.$toasted.error(
            "Ha ocurrido un error al obtener las publicaciones"
          );
          console.error(error);
        }
      );
    },
    set_mode_posts: function() {
      this.page = 1;
      this.mode = 'posts';
      this.posts = [];
    },
    get_my_posts: function() {
      this.set_mode_posts();
    },
    getCountries: function() {
      informationService.getCountries().then(
        data => {
          var countries = Object.keys(data.countries).map(function(key){
              return data.countries[key];
          });
          this.countries = countries;
        }
      )
    },
    view_file: function(links) {
      this.$emit("view-file", links);
    }
  },
};
</script>
