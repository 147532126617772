<template>
	<div class="custom-text">
		<b-card class="text-center">

			<div v-if="text.content_type === 0"> 
				<h4>{{ text.title }}</h4>
				<p class="font-italic font-weight-light">Por {{ text.author }}</p>
				<p class="text-justify format">{{ text.content }}</p>
			</div>
			<div v-if="text.content_type === 1">
				<CustomAudioSlider :content="text.story_chapters" />
			</div>
		</b-card>        
	</div>
</template>
<script>
import CustomAudioSlider from "@/components/custom-materials/miscellaneous/CustomAudioSlider";

export default {
		props: {
			text: {
				type: Object,
				required: true
			}
		},
		components: {
			CustomAudioSlider
		}
}
</script>
<style scoped lang="scss">
.custom-text {
	background-color: #ffffff;
	color: #000000;
	
}

.format{
	white-space: pre-wrap;
}
</style>