<template>
  <b-overlay :show="initial_st.length === 0">
    <b-container>
      <b-row>
        <b-col cols="12" class="no-padding">
          <div class="text-center">
            <h4>Archivos subidos por la comunidad</h4>
          </div>
        </b-col>
      </b-row>
      <b-overlay :show="false">
        <b-row>
          <b-col class="text-left">
            <h5>
              <v-icon
              scale="1.5"
              @click="$router.push({name: 'home'})"
              class="text-primary cursor-pointer d-block-inline"
              name="arrow-circle-left" />
              Filtra rápidamente</h5>
          </b-col>
        </b-row>
        <div class="p-0 filters radius-box-8 ml-1">
          <!-- <b-dropdown size="sm" class="normal-dropdown" variant="outline">
            <template v-slot:button-content class="m-0">
              Qué es
            </template>
            <b-dd-form>
              <b-form-checkbox
                v-for="(subtag, index) in
                  (customTags.filter(tag => tag.title.includes('Qué es esto'))[0] !== undefined?
                    customTags.filter(tag => tag.title.includes('Qué es esto'))[0].options : [])"
                v-bind:key="index+'-subtag'"
                v-model="whatIsArray"
                :value="subtag.title">{{subtag.title}}</b-form-checkbox>
            </b-dd-form>
          </b-dropdown> -->

          <b-dropdown size="sm" class="normal-dropdown" variant="outline">
            <template v-slot:button-content>
              Diagnóstico
            </template>
            <b-dd-form>
              <b-form-checkbox
                v-for="(subtag, index) in
                  (customTags.filter(tag => tag.title.includes('diagnóstico'))[0] !== undefined?
                    customTags.filter(tag => tag.title.includes('diagnóstico'))[0].options : [])"
                v-bind:key="index+'-subtag'"
                v-model="diagArray"
                :value="subtag.title">{{subtag.title}}</b-form-checkbox>
            </b-dd-form>
          </b-dropdown>

          <div class="d-none d-xl-inline d-lg-inline">
            <b-dropdown size="sm" class="normal-dropdown" variant="outline">
              <template v-slot:button-content>
                Curso
              </template>
              <b-dd-form>
                <b-form-checkbox
                  v-for="(subtag, index) in
                    (customTags.filter(tag => tag.title.includes('curso'))[0] !== undefined?
                      customTags.filter(tag => tag.title.includes('curso'))[0].options:[])"
                  v-bind:key="index+'-subtag'"
                  v-model="gradeArray"
                  :value="subtag.title">{{subtag.title}}</b-form-checkbox>
              </b-dd-form>
            </b-dropdown>

            <b-dropdown size="sm" class="normal-dropdown" variant="outline">
              <template v-slot:button-content>
                Profesión
              </template>
              <b-dd-form>
                <b-form-checkbox
                  v-for="(subtag, index) in
                    (customTags.filter(tag => tag.title.includes('Profesión'))[0] !== undefined?
                      customTags.filter(tag => tag.title.includes('Profesión'))[0].options:[])"
                  v-bind:key="index+'-subtag'"
                  v-model="professionArray"
                  :value="subtag.title">{{subtag.title}}</b-form-checkbox>
              </b-dd-form>
            </b-dropdown>

            <b-dropdown size="sm" class="normal-dropdown" variant="outline">
              <template v-slot:button-content>
                Habilidad
              </template>
              <b-dd-form>
                <b-form-checkbox
                  v-for="(subtag, index) in
                    (customTags.filter(tag => tag.title.includes('habilidad'))[0] !== undefined?
                      customTags.filter(tag => tag.title.includes('habilidad'))[0].options:[])"
                  v-bind:key="index+'-subtag'"
                  v-model="habilityArray"
                  :value="subtag.title">{{subtag.title}}</b-form-checkbox>
              </b-dd-form>
            </b-dropdown>

            <b-dropdown size="sm" class="normal-dropdown" variant="outline">
              <template v-slot:button-content>
                Tipo de material
              </template>
              <b-dd-form>
                <b-form-checkbox
                  v-for="(subtag, index) in
                    (customTags.filter(tag => tag.title.includes('material'))[0] !== undefined?
                      customTags.filter(tag => tag.title.includes('material'))[0].options:[])"
                  v-bind:key="index+'-subtag'"
                  v-model="materialArray"
                  :value="subtag.title">{{subtag.title}}</b-form-checkbox>
              </b-dd-form>
            </b-dropdown>
          </div>
          <div class="d-inline d-lg-none d-xl-none">
            <b-button v-b-toggle.collapse-filter size="sm" pill variant="unknown">Ver más...</b-button>
          </div>
          <b-collapse id="collapse-filter" class="m-0 p-0">
            <b-dropdown size="sm" class="normal-dropdown" variant="outline">
              <template v-slot:button-content>
                Curso
              </template>
              <b-dd-form>
                <b-form-checkbox
                  v-for="(subtag, index) in
                    (customTags.filter(tag => tag.title.includes('curso'))[0] !== undefined?
                      customTags.filter(tag => tag.title.includes('curso'))[0].options:[])"
                  v-bind:key="index+'-subtag'"
                  v-model="gradeArray"
                  :value="subtag.title">{{subtag.title}}</b-form-checkbox>
              </b-dd-form>
            </b-dropdown>

            <b-dropdown size="sm" class="normal-dropdown" variant="outline">
              <template v-slot:button-content>
                Profesión
              </template>
              <b-dd-form>
                <b-form-checkbox
                  v-for="(subtag, index) in
                    (customTags.filter(tag => tag.title.includes('Profesión'))[0] !== undefined?
                      customTags.filter(tag => tag.title.includes('Profesión'))[0].options:[])"
                  v-bind:key="index+'-subtag'"
                  v-model="professionArray"
                  :value="subtag.title">{{subtag.title}}</b-form-checkbox>
              </b-dd-form>
            </b-dropdown>

            <b-dropdown size="sm" class="normal-dropdown" variant="outline">
              <template v-slot:button-content>
                Habilidad
              </template>
              <b-dd-form>
                <b-form-checkbox
                  v-for="(subtag, index) in
                    (customTags.filter(tag => tag.title.includes('habilidad'))[0] !== undefined?
                      customTags.filter(tag => tag.title.includes('habilidad'))[0].options:[])"
                  v-bind:key="index+'-subtag'"
                  v-model="habilityArray"
                  :value="subtag.title">{{subtag.title}}</b-form-checkbox>
              </b-dd-form>
            </b-dropdown>

            <b-dropdown size="sm" class="normal-dropdown" variant="outline">
              <template v-slot:button-content>
                Tipo de material
              </template>
              <b-dd-form>
                <b-form-checkbox
                  v-for="(subtag, index) in
                    (customTags.filter(tag => tag.title.includes('material'))[0] !== undefined?
                      customTags.filter(tag => tag.title.includes('material'))[0].options:[])"
                  v-bind:key="index+'-subtag'"
                  v-model="materialArray"
                  :value="subtag.title">{{subtag.title}}</b-form-checkbox>
              </b-dd-form>
            </b-dropdown>
          </b-collapse>
            
          <br/>
          <div class="pl-1 pr-1">
            <b-badge
              v-for="(whatIsSubtag, index) in whatIsArray"
              :key="'whatIsSubtag-'+index"
              variant="info"
              class="cursor-pointer mr-1"
              @click="whatIsArray.splice(whatIsArray.indexOf(whatIsSubtag), 1)"
            >{{whatIsSubtag}} ×</b-badge>

            <b-badge
              v-for="(diagSubtag, index) in diagArray"
              :key="'diagSubtag-'+index"
              variant="info"
              class="cursor-pointer mr-1"
              @click="diagArray.splice(diagArray.indexOf(diagSubtag), 1)"
            >{{diagSubtag}} ×</b-badge>

            <b-badge
              v-for="(gradeSubtag, index) in gradeArray"
              :key="'gradeSubtag-'+index"
              variant="info"
              class="cursor-pointer mr-1"
              @click="gradeArray.splice(gradeArray.indexOf(gradeSubtag), 1)"
            >{{gradeSubtag}} ×</b-badge>

            <b-badge
              v-for="(professionSubtag, index) in professionArray"
              :key="'professionSubtag-'+index"
              variant="info"
              class="cursor-pointer mr-1"
              @click="professionArray.splice(professionArray.indexOf(professionSubtag), 1)"
            >{{professionSubtag}} ×</b-badge>

            <b-badge
              v-for="(habilitySubtag, index) in habilityArray"
              :key="'habilitySubtag-'+index"
              variant="info"
              class="cursor-pointer mr-1"
              @click="habilityArray.splice(habilityArray.indexOf(habilitySubtag), 1)"
            >{{habilitySubtag}} ×</b-badge>

            <b-badge
              v-for="(materialSubtag, index) in materialArray"
              :key="'materialSubtag-'+index"
              variant="info"
              class="cursor-pointer mr-1"
              @click="materialArray.splice(materialArray.indexOf(materialSubtag), 1)"
            >{{materialSubtag}} ×</b-badge>
          </div>
        </div>
        <template v-slot:overlay>
          <div class="text-center">
            <strong>Pronto!</strong>
          </div>
        </template>
      </b-overlay>
      <b-card no-body class="w-100" v-if="initial_st.length > 0">
        <b-card-body class="px-1 py-2 text-left">
          <b-card-group columns>
            <div v-for="(file, index) in files" :key="file.id+'-'+index">
              <MediaFile 
                :file="file"
                :index="index"
                :watchIndex="watchIndex"
                @watch-next="index===files.length-1? $toasted.info('No puedes ir más adelante!'):watchIndex=index+1"
                @watch-previous="index===0? $toasted.info('No puedes ir más atrás!'):watchIndex=index-1"
                @change="onChange"
                @points-change="$emit('points-change')"/>
            </div>
          </b-card-group>
          <infinite-loading :identifier="infiniteID" @infinite="infiniteHandler" ref="InfiniteLoading"></infinite-loading>
        </b-card-body>
      </b-card>
    </b-container>
  </b-overlay>
</template>

<script>
import ModalEmbed from "../modals/ModalEmbed";
import postService from "../../../services/postService";
import ProfileSidebarComponent from "../miscellaneous/ProfileSidebarComponent";
import MediaFile from "../dashboard/MediaFile";

export default {
  components: {
    ProfileSidebarComponent,
    ModalEmbed,
    MediaFile
  },
  created() {
    // if (!this.$store.state.login.token) {
    //   this.$router.push({ name: "home" });
    // }
    this.get_tags();
  },
  data: function() {
    return {
      watchIndex: 0,
      page: 1,
      loading_files: false,
      files: [],
      searching_by: { tags: [], subtags: [], text: "" },
      tags: [],
      subtags: [],
      initial_st: [],
      initial_t: [],
      name: "",
      customTags: [],
      selectedClassify: null,
      no_more: false,
      infiniteID: 0,
      whatIsArray: [],
      diagArray: [],
      gradeArray: [],
      professionArray: [],
      habilityArray: [],
      materialArray: [],
    };
  },
  computed: {
    token: function() {
      return this.$store.state.login.token;
    },
    searchedSubtags: function() {
      return this.whatIsArray.concat(
        this.diagArray, this.gradeArray, this.professionArray, this.habilityArray, this.materialArray);
    }
  },
  methods: {
    infiniteHandler: function($state) {
      var st = null
      if (this.searching_by.subtags.length===0){
        st = this.initial_st;
      }
      else {
        st = this.searching_by.subtags;
      }
      let subtags = [];
      st.forEach(
          x => subtags.push(x.replace(/\s+/g, '__'))
      )
      postService
        .get_files(
          {
            page: this.page,
            subtag: st}
            // ...(subtags.length > 0 && {subtags__in: subtags.join('__')}),
            // ...(this.name !== undefined && this.name !== '' && {search: this.name})}
        )
        .then(
          data => {
            this.loading_files = false;
            if (data.files.length > 0) {
              this.files = this.files.concat(data.files);
              this.page = this.page + 1;
              $state.loaded()
            }
            else {
              $state.complete()
            }
          },
          () => {
            this.$toasted.error("Ha ocurrido un error al obtener los archivos");
            $state.complete()
          }
        );
    },
    subtagClick(option) {
      if (!this.searching_by.subtags.includes(option.title)){
        this.add_subtag(option.title);
      }
      else {
        this.remove_subtag(this.searching_by.subtags.indexOf(option.title))
      }
    },
    addSubtagFromFile(subtag) {
      for (var i=0; i < this.customTags.length; i++){
        for (var j=0; j < this.customTags[i].options.length; j++){
          if (this.customTags[i].options[j].title === subtag)
            this.customTags[i].options[j].checked = this.customTags[i].options[j].id;
        }
      }
      this.add_subtag(subtag);
    },
    remove_subtag: function(subtag_index) {
      this.searching_by.subtags.splice(subtag_index, 1);
      this.reset();
    },
    add_subtag: function(subtag) {
      if (!this.searching_by.subtags.includes(subtag))
        this.searching_by.subtags.push(subtag);
        this.reset();
    },
    onChange: function(index, rate) {
      this.files[index].rate = rate;
    },
    view_file: function(links) {
      this.$emit("view-file", links);
    },
    get_files: function(subtags) {
      this.loading_files = true;
      var st = null
      if (subtags.length===0){
        st = this.initial_st;
      }
      else {
        st = subtags;
      }
      postService
        .get_files(
          {
            page: this.page,
            subtag: st}
            // ...(st.length > 0 && {subtags__in: st}),
            // ...(this.name !== undefined && this.name !== '' && {search: this.name})}
        )
        .then(
          data => {
            this.files = this.files.concat(data.files);
            this.loading_files = false;
            this.page = this.page + 1;
            if (data.files.length == 0) {
              this.$toasted.error("No hay más resultados para esta busqueda!");
              this.no_more = true;
            }
          },
          () => {
            this.$toasted.error("Ha ocurrido un error al obtener los archivos");
            this.loading_files = false;
          }
        );
    },
    reset: function() {
      this.page = 1;
      this.files = [];
      this.infiniteID += 1;
      // this.no_more = false;
      // this.get_files(this.searching_by.subtags);
    },
    reset_all: function() {
      this.searching_by = { tags: [], subtags: [], text: "" };
      this.name = "";
      this.reset();
    },
    get_tags: function() {
      postService.get_tags().then(
        data => {
          for (var i = 0; i < data.tags.length; i++) {
            for (var j = 0; j < data.tags[i].subtags.length; j++) {
              this.initial_st.push(data.tags[i].subtags[j].text);
            }
            this.initial_t.push(data.tags[i].text);
            this.searching_by.tags.push(data.tags[i].text);
          }
          this.tags = data.tags;
        },
        error => {
          console.error(error);
        }
      );
    },
    search: function(name) {
      this.loading_files = true;
      this.name = name;
      this.reset();
    }
  },
  watch: {
    searchedSubtags() {
      this.searching_by.subtags = this.searchedSubtags;
      this.reset();
      //this.get_files(this.searching_by.subtags);
    },
    tags() {
      for (var i = 0; i < this.tags.length; i++){
        let tag = {};
        if (!this.tags[i].text.includes('A qué colegio aplica?') &&
            !this.tags[i].text.includes('No asignado')
          ) {
          tag.title = this.tags[i].text;
          tag.options = [];
          for (var j = 0; j < this.tags[i].subtags.length; j++) {
            let subtag = {};
            if (this.tags[i].subtags[j].text !== 'Publicidad') {
              subtag.id = this.tags[i].subtags[j].id;
              subtag.title = this.tags[i].subtags[j].text;
              subtag.checked = false;
              tag.options.push(subtag);
            }
          }
          this.customTags.push(tag)
        }
      }
    },
  }
};
</script>

<style lang="scss">
.classify-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .reward {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  p {
    font-weight: bold;
    font-size: .8em;
    display: inline-block;
    padding: 0;
    margin: 0;
  }
  .reward-value {
    display: inline-block;
    padding: 0 !important;
    margin: 0 !important;

    input {
      width: 42px;
      padding: 0;
      text-align: center;
    }
  }
}

.classify-options {
  .classify-main-options {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 11px;

    li {
      padding: 3px 6px;
      position: relative;
      cursor: pointer;

      span {
        font-size: 1.2em;
      }

      .icon-right {
        position: absolute;
        right: 6px;
      }
    }
    li:hover, li.active {
      background-color: white !important;
      font-weight: bold;
    }
  }

  .classify-sub-options {
    font-size: .9em;
  }
}
.verticalLine {
  border-left: 1px solid white;
}
.icon {
  color: $primary;
}
.bd-sidebar {
  border-right: 1px solid #eee;
}
.bd-toc {
  border-left: 1px solid #eee;
}
.no-hover-info {
  border-color: #17a2b8;
  color: #17a2b8;
}
.no-hover-info:hover {
  border-color: #17a2b8;
  color: #17a2b8;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-disabled {
  cursor: not-allowed;
}
.div-file {
  position: relative;
}
.hover-file {
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  width: 160px;
  display: none;
  height: 100%;
  padding-top: 55px;
  background-color: rgba(10, 10, 10, 0.5);
  color: white;
  text-shadow: -1px 0 #444, 0 1px #444, 1px 0 #444, 0 -1px #444;
}
.div-file:hover .hover-file {
  display: block;
}
</style>
