<template>
  <b-container>
    <b-row>
      <b-col cols="1" class="p-0">
        <b-button
          :disabled="currentPage === previousPage"
          class="mx-auto btn-rounded mt-3 px-1"
          :variant="currentPage === previousPage ? 'light' : ''"
          @click="goBackPage()"
        >
          <v-icon class="pb-2" name="chevron-left" scale="1.5" />
        </b-button>
      </b-col>
      <b-col class="text-center p-0" cols="10">
        <carousel
          ref="redirect-carousel"
          @page-change="updatePages()"
          :per-page-custom="[
            [100, 3],
            [480, 3],
            [768, 3],
            [1024, 4],
            [1366, 4],
          ]"
          :mouse-drag="false"
          :navigationEnabled="false"
          :paginationEnabled="false"
        >
          <div v-for="(option, index) in options" :key="'option-' + index">
            <slide
              v-if="!option.require || (option.require && option.requisit)"
            >
              <b-container
                @click="
                  option.emit
                    ? emitEvent(option.redirect_to)
                    : redirectToPage(option.redirect_to)
                "
                style="cursor: pointer"
              >
                <b-row>
                  <b-col class="text-center">
                    <!-- <v-icon class="icon-group">
                                            <v-icon :class="option.circle_class" name="circle" scale="2"></v-icon>
                                            <v-icon class="text-ultralight" :name="option.icon_name"></v-icon>
                                        </v-icon> -->
                    <b-img
                      rounded="circle"
                      :width="option.width"
                      :src="option.icon_name"
                      :class="
                        option.extra_margin ? 'm-' + option.extra_margin : ''
                      "
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="text-center">
                    <strong style="font-size: 15px">
                      {{ option.name }}
                    </strong>
                  </b-col>
                </b-row>
              </b-container>
            </slide>
          </div>
        </carousel>
      </b-col>
      <b-col cols="1" class="p-0">
        <b-button
          :disabled="currentPage === nextPage"
          class="mx-auto btn-rounded mt-3 px-1"
          :variant="currentPage === nextPage ? 'light' : ''"
          @click="advancePage()"
        >
          <v-icon class="pb-2" name="chevron-right" scale="1.5" />
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  computed: {
    options() {
      return [
        {
          circle_class: "text-primary",
          icon_name: require("../../../assets/img/utopie_icons/2.svg"),
          redirect_to: "create-school",
          emit: true,
          name: "Corregir Test",
          width: 80,
        },
        {
          circle_class: "text-primary",
          icon_name: require("../../../assets/img/utopie_icons/5.svg"),
          redirect_to: "/comprar#/",
          emit: false,
          name: "Conoce la plataforma PIE",
          width: 80,
        },
        // {
        //     circle_class: "secondary",
        //     icon_name: require('../../../assets/img/utopie_icons/2.svg'),
        //     redirect_to: "open-test-modal",
        //     emit: true,
        //     name: "Corregir Test"
        // },
        {
          circle_class: "redpie",
          icon_name: require("../../../assets/img/utopie_icons/3.svg"),
          redirect_to: "create-school-docs",
          emit: true,
          name: "Redacta Informes",
          width: 80,
        },
        {
          circle_class: "text-primary",
          icon_name: require("../../../assets/img/utopie_icons/1.svg"),
          redirect_to: "/utopie#/media/",
          emit: false,
          name: "Material educativo",
          width: 80,
        },
        {
          circle_class: "text-primary",
          icon_name: require("../../../assets/img/botpie/icono-clase.svg"),
          redirect_to: "/botpie#/",
          emit: false,
          name: "Planificación de clase",
          require: true,
          extra_margin: "2",
          requisit: this.$store.state.login.school,
          width: 60,
        },
        // {
        //     circle_class: "text-primary",
        //     icon_name: require('../../../assets/img/utopie_icons/4.svg'),
        //     redirect_to: "open-eureka",
        //     emit: true,
        //     name: "Aplicar Eureka",
        //     require: true,
        //     requisit: this.$store.state.login.school,
        //     width: 80
        // },
        // {
        //     circle_class: "tertiary",
        //     icon_name: require('../../../assets/img/utopie_icons/4.svg'),
        //     redirect_to: "",
        //     emit: false,
        //     name: "Aula Virtual"
        // },
      ];
    },
  },
  data() {
    return {
      a: 0,
      previousPage: 0,
      currentPage: 0,
      nextPage: 0,
    };
  },
  methods: {
    goBackPage() {
      this.$refs["redirect-carousel"].advancePage("backward");
    },
    advancePage() {
      this.$refs["redirect-carousel"].advancePage();
    },
    updatePages() {
      this.nextPage = this.$refs["redirect-carousel"].getNextPage();
      this.previousPage = this.$refs["redirect-carousel"].getPreviousPage();
      this.currentPage = this.$refs["redirect-carousel"].currentPage;
    },
    redirectToPage(optionRedirect) {
      window.location.href = optionRedirect;
      // console.log('holaa');
    },
    emitEvent(event) {
      this.$emit(event);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.updatePages();
    });
  },
};
</script>
<style lang="scss">
.icon-group {
  width: 52px;
  height: 52px;

  .primary {
    color: $primary !important;
  }

  .secondary {
    color: $secondary !important;
  }

  .tertiary {
    color: $tertiary !important;
  }
}

.item:hover {
  background-color: $ultralight !important;
  cursor: pointer;
}

.btn-rounded {
  width: 30px;
  height: 30px;
  padding: 5px 8px;
  border-radius: 20px !important;
  font-size: 9px;
  text-align: center;
}
</style>
