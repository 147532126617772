<template>
    <div>
        <ViewVideoModal ref="view-video-modal" />
        <b-container>
            <b-spinner variant="success" type="grow" label="Spinning" v-if="loadingMissions" />
            <div class="radius-box-8 padding-box-16" v-else>
                <h3>Misiones pendientes</h3>
                <ul>
                    <li
                        v-for="(mission, index) in $store.state.login.missions"
                        :key="'umission-'+index"
                    >
                        <b-container class="m-0 p-0">
                            <b-row class="m-0 p-0">
                                <h5 class="text-primary mr-2">{{mission.mission.title}}</h5>
                                <div 
                                    style="border: 2px solid; border-radius: 10px; width: 30px; cursor: pointer; display: block-inline;"
                                    class="text-primary text-center"
                                    @click="openVideoModal(mission.mission.video)"
                                >
                                    <v-icon
                                        class="text-primary"
                                        name="play"/>
                                </div>
                            </b-row>
                            <b-row class="m-0 p-0">
                                {{mission.mission.description}}
                            </b-row>
                        </b-container>
                    </li>
                </ul>
                <h3>Misiones completadas</h3>
                <ul>
                    <li
                        v-for="(mission, index2) in completedMissions"
                        :key="'cmission-'+index2"
                    >
                        <b-container class="m-0 p-0">
                            <b-row class="m-0 p-0">
                                <h5 class="text-primary mr-2">{{mission.mission.title}}</h5>
                                <div 
                                    style="border: 2px solid; border-radius: 10px; width: 30px; cursor: pointer; display: block-inline;"
                                    class="text-primary text-center"
                                    @click="openVideoModal(mission.mission.video)"
                                >
                                    <v-icon
                                        class="text-primary"
                                        name="play"/>
                                </div>
                            </b-row>
                            <b-row class="m-0 p-0">
                                {{mission.mission.description}}
                            </b-row>
                        </b-container>
                    </li>
                </ul>
            </div>
        </b-container>
    </div>
</template>

<script>
import missionService from "../../../services/missionService";
import ViewVideoModal from "../modals/ViewVideoModal";

export default {
    components: {
        ViewVideoModal,
    },
    created() {
        this.getCompletedMissions();
    },
    data: function() {
        return {
            completedMissions: [],
            loadingMissions: false,
        };
    },
    methods: {
        getCompletedMissions: function() {
            this.loadingMissions = true;
            missionService.getCompletedMissions().then(
                data => {
                    this.completedMissions = data;
                    this.loadingMissions = false;
                }
            )
        },
        openVideoModal: function(link) {
            this.$refs["view-video-modal"].open(link);
        },
    }
};
</script>

<style lang="scss">

</style>