<template>
  <div class="advertising radius-box-8 padding-box-16">
    <div class="ad">Espacio Publicitario</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style lang="scss">
.advertising {
  width: 100%;
  background-color: white;

  .ad {
    width: 100%;
    height: 612px;
    padding: 24px;
    display: flex;
    align-items: center;
    background-color: $light;
    font-size: 21px;
    font-weight: bold;
    justify-content: center;
    text-align: center;
  }
}
</style>
