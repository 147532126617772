import api from "@/services/api";
import multiform from "@/services/multiform";

export default {
  delete_all_posts() {
    return api.post("delete_all_posts/").then(response => response.data);
  },
  getCommentData(id) {
    return api.get(`get_comment_data/${id}/`).then(response => response.data);
  },
  get_my_posts(payload) {
    return api.get("search/posts/", {params: payload}).then(response => response.data);
  },
  get_posts(payload) {
    return api.get("search/posts/", {params: payload}).then(response => response.data);
  },
  get_post_by_id(id) {
    return api.get(`search/posts/?id=${id}`).then(response => response.data);
  },
  get_posts_by_tag(payload) {
    return api
      .get("search/posts/", {params: payload})
      .then(response => response.data);
  },
  get_posts_by_subtag(payload) {
    return api
      .get("search/posts/", {params: payload})
      .then(response => response.data);
  },
  get_posts_by_search(payload) {
    return api
      .get("search/posts/", {params: payload})
      .then(response => response.data);
  },
  change_subtag_filter(payload) {
    return api
      .post("change_subtag_filter/", payload)
      .then(response => response.data);
  },
  publish_post(form_data) {
    return multiform
      .post("publish_post/", form_data)
      .then(response => response.data);
  },
  publish_comment(form_data) {
    return multiform
      .post("publish_comment/", form_data)
      .then(response => response.data);
  },
  report_post(payload) {
    return api.post("report_post/", payload).then(response => response.data);
  },
  delete_post(payload) {
    return api.post("delete_post/", payload).then(response => response.data);
  },
  report_comment(payload) {
    return api.post("report_comment/", payload).then(response => response.data);
  },
  delete_comment(payload) {
    return api.post("delete_comment/", payload).then(response => response.data);
  },
  report_message(payload) {
    return api.post("report_message/", payload).then(response => response.data);
  },
  delete_message(payload) {
    return api.post("delete_message/", payload).then(response => response.data);
  },
  vote_poll(payload) {
    return api.post("vote_poll/", payload).then(response => response.data);
  },
  get_comments(payload) {
    return api.post("get_comments/", payload).then(response => response.data);
  },
  like_comment(payload) {
    return api.post("like_comment/", payload).then(response => response.data);
  },
  like_post(payload) {
    return api.post("like_post/", payload).then(response => response.data);
  },
  get_tags(payload) {
    return api.post("get_tags/", payload).then(response => response.data);
  },
  like_message(payload) {
    return api.post("like_message/", payload).then(response => response.data);
  },
  publish_message(payload) {
    return api
      .post("publish_message/", payload)
      .then(response => response.data);
  },
  get_messages(payload) {
    return api.post("get_messages/", payload).then(response => response.data);
  },
  rate_file(payload) {
    return api.post("rate_file/", payload).then(response => response.data);
  },
  get_my_file_rate(payload) {
    return api
      .post("get_my_file_rate/", payload)
      .then(response => response.data);
  },
  get_files(payload) {
    return api
      .post("get_files/", payload) //{params: payload})
      .then(response => response.data);
  },
  add_subtags(payload) {
    return api.post("add_subtags/", payload).then(response => response.data);
  },
  restore_points(payload) {
    return api.post("restore_points/", payload).then(response => response.data);
  },
  give_points(payload) {
    return api.post("give_points/", payload).then(response => response.data);
  },
  addPollOption(payload) {
    return api.post("add_poll_option/", payload).then(response => response.data);
  },
  markAsSpam(payload) {
    return api.post("mark_as_spam/", payload).then(response => response.data);
  }
};
