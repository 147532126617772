<template>
  <b-overlay :show="loading" rounded="sm">
    <div class="publish radius-box-8 padding-box-16">
      <div class="left">
        <div class="input">
          <b-form-textarea
            class="input-form"
            v-model="text"
            placeholder="Escribir aquí..."
            maxlength="3000"
            @click="showTags = true"
          />
        </div>
        <div class="end">
          <b-container class="no-pads-container pb-0 pt-0 m-0">
            <b-row v-if="value.length>0" class="">
              <b-col>
                <b-button
                  pill
                  v-for="(subtag, index) in value"
                  :key="'subtag-'+index"
                  size="sm"
                  variant="light"
                  @click="removeTag(subtag)"
                ><div class="black-font">{{subtag.text}} x</div></b-button>
              </b-col>
            </b-row>
            <b-row style="font-size: 14.5px;">
              <b-col cols="5" @click="showTags = !showTags" style="cursor: pointer; padding-right: 0px !important">
                <b-row>
                  <b-col cols="9" style="padding-right: 0px !important;">
                    <strong>Clasifica tu post</strong>
                  </b-col>
                    <div v-if="!showTags">
                      ▾
                    </div>
                    <div v-else>
                      ▴
                    </div>
                </b-row>
              </b-col>
              <!-- <b-col cols="7" style="padding-left: 0px !important;" v-if="!has_poll">
                <div class="input">
                  <b-container class="no-pads-container">
                    <b-row>
                      <b-col cols="6" class="no-pads-column">
                        <strong>Ofrece recompensa:</strong>
                      </b-col>
                      <b-col cols="3" class="no-pads-column text-left">
                        <b-form-input
                          v-model="reward"
                          type="number"
                          variant="primary"
                          min="0"
                          :max="this.$store.state.login.points"
                          step="1"
                        />
                      </b-col>
                    </b-row>
                    <b-alert :show="reward>0" variant="warning" dismissible>
                      Colocar recompensa en este post será irreversible una vez te respondan.
                    </b-alert>
                  </b-container>
                </div>
              </b-col> -->
            </b-row>
            <b-collapse v-model="showTags" id="tags-collapse" class="mt-2">
            <b-card no-body class="overflow-hidden" style="max-width: 540px;">
              <b-row no-gutters>
                <b-col md="4">
                  <b-nav align="center" vertical tabs class="tags-nav">
                    <b-nav-item
                      v-for="(tag, index) in tags"
                      :key="'item-'+index"
                      @click="setTag(tag)"
                      :active="selectedTag.text===tag.text"
                    >
                      <div class="tag-name">
                        <strong v-if="selectedTag.text===tag.text">
                          <div style="display: inline;">
                            {{tag.text}}
                          </div>
                          <span class="selected-arrow" style="display: inline;">
                            ▸
                          </span>
                        </strong>
                        <span v-else>
                          <div style="display: inline;">
                            {{tag.text}}
                          </div>
                          <span style="display: inline;">
                            ▸
                          </span>
                        </span>
                      </div>
                    </b-nav-item>
                  </b-nav>
                </b-col>
                <b-col md="8">
                  <b-card-body>
                    <b-card-text>
                      <b-form-group>
                        <div v-for="(subtag, index) in selectedTag.subtags" 
                            :key="'subtag-'+index">
                          <b-form-checkbox
                            v-model="value"
                            :value="subtag"
                            name="flavour-3a"
                            v-if="subtag.text != 'Publicidad'"
                          >
                            {{ subtag.text }}
                          </b-form-checkbox>
                        </div>
                      </b-form-group>
                    </b-card-text>
                  </b-card-body>
                </b-col>
              </b-row>
            </b-card>
            </b-collapse>
          </b-container>
        </div>
        <hr />
        <b-container class="no-pads-container mt-0">
          <b-row>
            <b-col cols="9">
              <b-form-file
                v-model="files"
                ref="file"
                style="display:none;"
                accept=".docx, .doc, .xlsx, .xls, .pdf, .ppt, .pptx, .png, .jpg, .jpeg"
                multiple
              ></b-form-file>
              <div v-if="files.length > 0" class="docs-data">
                <ul>
                  <li v-for="(doc, index) in files" :key="'doc-'+index">
                    <v-icon name="c-doc" class="text-primary" />
                    {{doc.name}} - {{doc.size | prettyBytes}}
                  </li>
                </ul>
              </div>
            </b-col>
            <b-col cols="3" class="text-right">
              <div class="input">
                <div class="button">
                  <b-button
                    variant="primary"
                    @click="publishPost"
                    class="radius-box-24 padding-box-16-lr border-none bold"
                  >Publicar</b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
        <b-collapse id="poll" v-model="has_poll">
          <b-card no-body class="mt-1">
            <b-card-body>
              <b-form @submit.stop.prevent>
                <label for="text-password">Encuesta</label>
                <b-input
                  type="text"
                  v-model="question"
                  :state="question.length>0"
                  placeholder="Ej: ¿Cuál es tu color favorito?"
                  aria-describedby="poll-help-block"
                ></b-input>
                <b-form-text id="password-help-block">
                  <code>La encuesta debe tener por lo menos 2 alternativas</code>
                </b-form-text>
                <b-input-group class="mb-1" v-for="(qoption, index) in question_options" :key="index">
                  <b-form-input
                    :state="qoption.value.length>0"
                    :placeholder="'Ej: Opción ' + (index+1)"
                    v-model="qoption.value"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button variant="outline-danger" @click="removeOption(index)">Eliminar</b-button>
                  </b-input-group-append>
                </b-input-group>
                <b-button variant="outline-info" class="w-100" @click="addOption()">Agregar opción</b-button>
              </b-form>
            </b-card-body>
          </b-card>
        </b-collapse>
      </div>
      <div class="right">
        <div class="options">
          <v-icon
            name="c-attach"
            scale="1.75"
            class="icon"
            v-b-tooltip.hover
            title="Adjuntar archivos"
            @click="$refs.file.$el.childNodes[0].click()"
          />
          <v-icon
            name="c-poll"
            scale="1.75"
            class="icon"
            v-b-tooltip.hover
            :title="has_poll ? 'Quitar encuesta' : 'Agregar encuesta'"
            @click="has_poll = !has_poll"
          />
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>

export default {
  data: function() {
    return {
      text: "",
      files: [],
      has_poll: false,
      reward: 0,
      question: "",
      question_options: [{ value: "" }, { value: "" }],
      value: [],
      selectedTag: {},
      showTags: false,
      loading: false,
    };
  },
  props: {
    tags: { type: Array, default: () => [] },
  },
  created(){
    console.log(this.changeLoading)
    this.$emit('loadingFunc', this.changeLoading)
  },
  methods: {
    changeLoading: function() {
      this.loading = !this.loading;
    },
    setTag(tag) {
      this.selectedTag=tag
    },
    customLabel({ text }) {
      return `#${text}`;
    },
    addOption: function() {
      this.question_options.push({ value: "" });
    },
    removeTag: function(subtag) {
      this.value.splice(this.value.indexOf(subtag), 1);
    },
    removeOption: function(index) {
      if (this.question_options.length > 2) {
        this.question_options.splice(index, 1);
      } else {
        this.errorToast("Debes tener mínimo 2 opciones!");
      }
    },
    resetValues: function() {
      this.text = ""
      this.files = []
      this.has_poll = false
      this.question = ""
      this.question_options = [{ value: "" }, { value: "" }]
      this.value = []
    },
    publishPost: function() {
      if (this.has_poll) {
        this.$emit("publish-post", {
          text: this.text,
          files: this.files,
          has_poll: this.has_poll,
          question: this.question,
          options: this.question_options,
          subtags: this.value,
          reward: 0,
        });
      }
      else {
        this.$emit("publish-post", {
          text: this.text,
          files: this.files,
          has_poll: this.has_poll,
          question: this.question,
          options: this.question_options,
          subtags: this.value,
          reward: this.reward
        });
      }
      this.resetValues()
    }
  },
  watch: {
  }
};
</script>

<style lang="scss">
.selected-arrow {
  color: $primary;
}
.tag-name {
  color: black;
  display: flex;
  justify-content: space-between !important;
}
.black-font {
  color: black;
}
.no-bullets-list {
  list-style: none;
  padding-left: 0px !important;
}
.no-pads-container {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.no-pads-column {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.tags-nav {
  background-color: lightgray;
  color: black;
}

.active-item {
  font-weight: bold !important;
}

.publish {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;

  .left {
    width: calc(100% - 32px);

    .input {
      width: 100%;

      .input-form {
        height: 84px !important;
      }
    }
    .end {
      margin-top: 0px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      .tags {
        width: calc(100% - 144px);
      }
      .button {
        width: 144px;
        padding-left: 12px;
        margin-top: 3px;

        button {
          width: 100%;
        }
      }
    }
  }

  .right {
    width: 32px;

    .options {
      width: 32px;
      text-align: center;

      .icon {
        color: $light !important;
        cursor: pointer;
        transition: all 0.256s;
      }
      .icon:hover {
        color: $secondary !important;
        transition: all 0.256s;
      }
    }
  }
}
</style>
