<template>
  <div class="global-container">
    <TutorialModal ref="tutorial-modal"/>
    <SidebarComponent>
      <TutorialComponent v-on:get-filtered-posts="get_posts_by_subtag"/>
    </SidebarComponent>
    <HeaderComponent 
      style="z-index: 999;" v-on:search-by-text="search_by_text" />
    <div id="mainContainer" class="main-container profile-container" :class="setPadding()">
      <b-container>
        <b-row>
          <!-- left -->
          <b-col
            cols="0"
            sm="0"
            md="3"
            lg="3"
            xl="3"
            class="no-padding d-none d-sm-none d-md-block d-lg-block d-xl-block"
          >
            <div class="padding-box-normal">
              <ProfileSidebarComponent />
            </div>
          </b-col>
          <!-- center -->
          <b-col cols="12" sm="12" md="9" lg="6" xl="6" class="no-padding">
            <div class="me-header">
              <ul>
                <li
                  @click="$router.push({ name: 'profile' })"
                  style="border-left: none;"
                  v-bind:class="{ current: this.$router.currentRoute.path === '/profile' }"
                >Mis Publicaciones</li>
                <li
                  @click="$router.push({ name: 'profile_notifications' })"
                  v-bind:class="{ current: this.$router.currentRoute.path === '/profile/notifications' }"
                >Notificaciones</li>
                <li
                  @click="$router.push({ name: 'profile_missions' })"
                  v-bind:class="{ current: this.$router.currentRoute.path === '/profile/missions' }"
                >Misiones</li>
                <li
                  @click="$router.push({ name: 'profile_tests' })"
                  v-bind:class="{ current: this.$router.currentRoute.path === '/profile/tests' }"
                >Pruebas realizadas</li>
                <li
                  @click="$router.push({ name: 'profile_points' })"
                  v-bind:class="{ current: this.$router.currentRoute.path === '/profile/points' }"
                >Mis Puntos</li>
                <li
                  @click="$router.push({ name: 'profile_delete' })"
                  v-bind:class="{ current: this.$router.currentRoute.path === '/profile/delete' }"
                >Eliminar cuenta</li>
              </ul>
            </div>
            <transition name="fade">
              <router-view
                ref="main"
                v-on:view-file="view_file"
              />
            </transition>
          </b-col>
          <b-col
            cols="0"
            sm="0"
            md="0"
            lg="3"
            xl="3"
            class="no-padding d-none d-sm-none d-md-none d-lg-block d-xl-block"
          >
            <RightSidebarComponent/>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-modal id="bv-modal-view-file" size="xl" hide-footer>
      <template v-slot:modal-title>
        <b-spinner variant="success" type="grow" label="Spinning" v-if="rate_loading" />
        <star-rating v-model="bound_rating" @rating-selected="rating_selected" v-else />
      </template>
      <iframe :src="view_link" class="w-100" style="height:66vh;" />
      <a :href="download_link">
        <b-button variant="info" class="mt-1" @click="downloaded" block>Descargar</b-button>
      </a>
    </b-modal>
    <BottomButton @open-tutorial="openTutorial"/>
    <div id="divFeedbackBtn">
      <FeedbackButtonComponent />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import authService from "../../services/authService";
import fileService from "../../services/fileService";
import FeedbackButtonComponent from "../../components/utopie/modals/FeedbackButtonComponent";
import HeaderComponent from "../../components/utopie/miscellaneous/HeaderComponent";
import notificationService from "../../services/notificationService";
import missionService from "../../services/missionService";
import postService from "../../services/postService";
import ProfileSidebarComponent from "../../components/utopie/miscellaneous/ProfileSidebarComponent";
import RightSidebarComponent from "../../components/utopie/miscellaneous/RightSidebarComponent";
import SidebarComponent from "../../components/utopie/miscellaneous/SidebarComponent";
import TutorialComponent from "../../components/utopie/miscellaneous/TutorialComponent";
import BottomButton from "../../components/utopie/miscellaneous/BottomButton";
import TutorialModal from "../../components/utopie/modals/TutorialModal";

export default {
  components: {
    HeaderComponent,
    FeedbackButtonComponent,
    TutorialComponent,
    SidebarComponent,
    ProfileSidebarComponent,
    RightSidebarComponent,
    BottomButton,
    TutorialModal,
  },
  computed: mapState(["token"]),
  created() {
    if (!this.$store.state.login.token) {
      this.$router.push({ name: "home" });
    }
    const vue_scope = this;
    if (this.$store.state.login.token) {
      this.verify_token();
      if(!window.webpackHotUpdate){
        this.$connect();
      }
    }
    this.get_my_notifications();
    this.getUncompletedMissions();
    this.$options.sockets.onmessage = data => {
      vue_scope.$toasted.success(JSON.parse(data.data).message);
    }
    // eslint-disable-next-line
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      this.view_link = "";
      this.download_link = "";
    });
    if (process.browser) {
      window.addEventListener("resize", this.set_main_container_position);
      this.set_main_container_position();

      this.$nextTick(() => {
        this.set_main_container_position();
      });
    }
  },
  data: function() {
    return {
      text: "",
      view_link: "",
      download_link: "",
      bound_rating: 0,
      id: "",
      rate_loading: false,
      windowWidth: 0,
    };
  },
  destroyed: function() {
    if (process.browser) {
      window.removeEventListener("resize", this.set_main_container_position);
    }
  },
  methods: {
    downloaded() {
      fileService.materialDownload();
    },
    setPadding() {
      if (this.windowWidth > 1800) {
        return 'big-padding';
      }
      else if (this.windowWidth > 1500) {
        return 'medium-padding'
      }
    },
    openTutorial: function() {
      this.$refs["tutorial-modal"].open();
    },
    get_my_notifications: function() {
      notificationService.get_my_notifications().then(
        data => {
          this.$store.commit("login/setNotifications", data);
        },
        error => {
          console.error(error);
        }
      );
    },
    getUncompletedMissions: function() {
      missionService.getUncompletedMissions().then(
        data => {
          this.$store.commit("login/setMissions", data);
        }
      )
    },
    verify_token: function() {
      authService.verify_token().then(
        // eslint-disable-next-line
        data => {
          // console.log("verifying token...");
        },
        // eslint-disable-next-line
        error => {
          this.$toasted.error("Tu sesión ha expirado.");
          this.$store.commit("login/setUserEmpty");
        }
      );
    },
    get_posts_by_subtag: function(subtag) {
      if (this.$router.currentRoute.name==='home')
        this.$refs.main.get_posts_by_subtag(subtag);
      else {
        this.$store.commit('login/setSubtag',{subtag: subtag})
        this.$router.push({ name: 'home' })
      }
    },
    set_main_container_position() {
      this.windowWidth = window.innerWidth;
      const header = document.querySelector("#header");
      const mainContainer = document.querySelector("#mainContainer");

      if (header && mainContainer) {
        mainContainer.style.paddingTop = `${header.offsetHeight}px`;
      }
    },
    search_by_text: function(text) {
      this.$refs.main.search(text);
    },
    view_file: function(links) {
      this.rate_loading = true;
      this.download_link = links.download_link;
      this.view_link = links.view_link;
      this.$bvModal.show("bv-modal-view-file");
      this.id = links.id;
      postService.get_my_file_rate({ id: this.id }).then(
        data => {
          this.bound_rating = data.rate;
          this.rate_loading = false;
        },
        // eslint-disable-next-line
        error => {
          this.$toasted.error("Ha ocurrido un error obteniendo tu voto.");
          this.bound_rating = 0;
          this.rate_loading = false;
        }
      );
    },
    close_login_dropdown: function() {
      this.$refs.loginDropdown.hide();
    },
    rating_selected: function() {
      postService
        .rate_file({ bound_rating: this.bound_rating, id: this.id })
        .then(
          data => {
            this.$toasted.success(data.message);
          },
          // eslint-disable-next-line
          error => {
            this.$toasted.error(
              "Ha ocurrido un error guardando tu voto, inténtalo más tarde."
            );
            this.bound_rating = 0;
          }
        );
    }
  },
  watch: {
    // eslint-disable-next-line
    $route(to, from) {
      if (this.$store.state.login.token) {
        this.verify_token();
      }
    },
    // eslint-disable-next-line
    token(new_value, old_value) {
      if (new_value) {
        this.get_my_notifications();
      }
    }
  }
};
</script>

<style lang="scss">
.profile-container {
  padding-top: 85px !important;
}
.current {
  font-weight: bold;
}
.current::before,
.me-header ul li:hover::before {
  content: "";
  position: absolute;
  width: 84px;
  height: 3px;
  background-color: $secondary;
  left: 50%;
  margin-left: -42px;
  bottom: -4px;
}

.me-header {
  width: 100%;
  margin: 12px 0 24px;

  a {
    text-decoration: none;
    color: inherit;
  }

  ul {
    text-align: center;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      display: inline-block;
      padding: 0 15px;
      margin: 0;
      font-size: 14px;
      border-left: solid 1px black;
    }
  }
}
.big-padding {
  padding-left: 12rem !important;
  padding-right: 12rem !important;
}
.medium-padding {
  padding-left: 6rem !important;
  padding-right: 6rem !important;
}
body {
  font-family: Ubuntu, "times new roman", times, roman, serif !important;
}
</style>
