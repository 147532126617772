<template>
  <div>
    <!-- <RootComponent></RootComponent> -->
  </div>
</template>

<script>
// import RootComponent from '@/components/utopie/marketplace/RootComponent'

export default {
	components: {
    // RootComponent
  }
};
</script>

<style lang="scss" scoped>
// body {
//   margin: 0;
// }
</style>