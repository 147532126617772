<template>
  <b-container v-if="this.invitation.state===0">
    <b-row>
      <h3>
        Hola {{this.invitation.email}}
      </h3>
    </b-row>
    <b-row>
      <p v-if="!exists">
        Completa el siguiente formulario para aceptar la invitación al colegio {{school.name}}:
      </p>
      <p v-else>
        Has sido invitado para participar en el colegio {{school.name}}
      </p>
    </b-row>
    <div v-if="!exists">
      <b-row>
        <b-col cols="5" class="input-col">
          <b-form-input placeholder="Nombre" v-model="r_first_name" type="text"></b-form-input>
          <b-form-input placeholder="Apellido" v-model="r_last_name" type="text"></b-form-input>
          <b-form-input placeholder="Contraseña" v-model="r_password" type="password"></b-form-input>
          <b-form-input placeholder="Repetir contraseña" v-model="r_password2" type="password"></b-form-input>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-row class="success-button">
        <div class="right-padd">
          <b-button
            variant="danger"
            @click="rejectInvitation"
            >
            Rechazar invitación
          </b-button>
        </div>
        <b-button variant="success"
          @click="acceptInvitation"
          v-if="exists"
          >
          Aceptar invitación!
        </b-button>
        <b-button variant="success"
          v-else
          @click="register({first_name: r_first_name,
                            last_name: r_last_name,
                            email: invitation.email,
                            password1: r_password,
                            password2: r_password2,
                            invitation_uid: $route.params.uid})"
          >
          Aceptar invitación<br/>
          y registrarme!
        </b-button>
      </b-row>
    </div>
  </b-container>
  <b-container v-else>
    <b-row>
      Esta invitación ya ha sido aceptada o rechazada
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from "vuex";
import authService from "../../../services/authService";

export default {
  components: {
  },
  computed: mapState(["token"]),
  created() {
    this.getInvitationData()
  },
  data: function() {
    return {
      email: "",
      password: "",
      r_password: "",
      r_password2: "",
      r_first_name: "",
      r_last_name: "",
      invitation: {},
      school: {},
      exists: false,
    };
  },
  methods: {
    getInvitationData: function() {
      authService.getInvitationData(this.$route.params.uid).then(
        data => {
          this.invitation = data.invitation;
          this.school = data.school;
          this.exists = data.exists;
        }
      )
    },
    register: function(payload) {
      authService.registerWithoutMail(payload).then(
        data => {
          this.$toasted.success(data.message);
          this.$store.commit('login/setUser', data.user)
          this.$connect();
          window.location.href = "/redpie#/tutorial";
        },
        errors => {
          this.$toasted.error(errors.response.data.message);
        }
      )
    },
    acceptInvitation: function() {
      authService.acceptInvitation({ uid: this.$route.params.uid }).then(
        data => {
          this.$toasted.success(data.message);
          if (this.$store.state.login.school)
            window.location.href = "/redpie#/";
          else
            window.location.href = "/redpie#/tutorial";
        }
      )
    },
    rejectInvitation: function() {
      authService.rejectInvitation({ uid: this.$route.params.uid }).then(
        data => {
          this.$toasted.success(data.message);
          this.$router.push({ name: "home" })
        }
      )
    }
  },
};
</script>

<style>
.success-button {
  padding-top: 10px !important;
}
.input-col {
  padding-left: 0px !important;
}
.right-padd {
  padding-right: 5px !important;
}
</style>
