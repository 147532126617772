<template>
  <b-container>
    <b-card-text>
      <b-spinner variant="success" type="grow" label="Spinning" v-if="loading_tests" />
      <b-table
        responsive
        fixed
        v-else
        :striped="true"
        :bordered="true"
        :small="true"
        :hover="true"
        :items="tests"
        :fields="fields"
      >
        <template slot="name" slot-scope="data">
          <TestNameComponent :data="data" />
        </template>
        <template slot="link" slot-scope="data">
          <a
            :href="`${'http://45.55.68.70/'+data.item.test_name+'/download/'+data.item.redpsi_id}`"
          >Descargar</a>
        </template>
      </b-table>
    </b-card-text>
  </b-container>
</template>

<script>
import TestNameComponent from "../miscellaneous/TestNameComponent";
import testService from "../../../services/testService";

export default {
  components: {
    TestNameComponent
  },
  created() {
    this.get_my_tests();
  },
  data: function() {
    return {
      tests: [],
      fields: [
        { key: "name", label: "Nombre" },
        { key: "created_at", label: "Fecha creación" },
        { key: "test_name", label: "Test" },
        { key: "link", label: "Descarga" }
      ],
      loading_tests: true
    };
  },
  methods: {
    get_my_tests: function() {
      this.test_loading = true;
      testService.get_my_tests().then(
        data => {
          this.tests = data.tests;
          this.loading_tests = false;
        },
        error => {
          this.$toasted.error("Ha ocurrido un error al obtener los tests");
          console.error(error);
        }
      );
    }
  }
};
</script>