import informationService from "../services/informationService";
const USER_CONNECTED = "user_connected";
const USER_ONLINE = "user_online";
const USER_DISCONNECTED = "user_disconnected";
const documentMaterialSocket = {
  created() {},
  computed: {
    socketConnected() {
      return this.$store.state.sockets.socket.isConnected;
    },
    actualMessage() {
      return this.$store.state.sockets.actualMessage;
    },
  },

  data() {
    return {
      noInternet: false,
      checkingInternet: false,
      usersOnline: [],
      keyFocus: null,
    };
  },
  watch: {
    actualMessage(new_val, old_val) {
      console.log(new_val, old_val);
      const message = new_val;
      switch (message.type) {
        case USER_CONNECTED:
          if (
            !this.usersOnline.includes(message.user_name) ||
            message.user_name === "Anon"
          ) {
            if (
              message.user_name === this.$store.state.login.email ||
              message.user_name === "Anon"
            ) {
              if (!this.usersOnline.includes(message.user_name)) {
                this.$toasted.success(
                  "Te has conectado a trabajar en esta aplicación"
                );
                this.usersOnline.push(message.user_name);
              }
            } else {
              this.$socket.sendObj({ type: USER_ONLINE, key: this.keyFocus });
              this.usersOnline.push(message.user_name);
              this.$toasted.success(
                message.user_name.concat(" se ha conectado a la aplicación")
              );
            }
          }
          break;
        case USER_DISCONNECTED:
          if (
            message.user !== this.$store.state.login.email &&
            message.user !== "Anon"
          ) {
            console.log("OUT");
            this.usersOnline.splice(this.usersOnline.indexOf(message.user), 1);
            this.$toasted.success(
              message.user.concat(" se ha desconectado de la aplicación")
            );
          }

          break;
      }
    },
  },
  methods: {
    checkInternet: function() {
      console.log("check internet");
      setTimeout(() => {
        this.checkingInternet = false;
        informationService.ping().then(
          () => {
            this.noInternet = false;
          },
          () => {
            this.checkingInternet = true;
          }
        );
      }, 3000);
    },
    createDocumentReport: function() {
      informationService.ping().then(() => {
        console.log("ping");
      });
    },
    joinApplication: function(document_id) {
      informationService.ping().then(
        () => {
          this.$store.dispatch("joinApplication", document_id);
        },
        () => {
          this.noInternet = true;
          this.$disconnect();
          this.checkInternet();
        }
      );
    },
    leaveApplication: function() {
      this.$store.dispatch("leaveApplication");
    },
  },
};

export default documentMaterialSocket;
