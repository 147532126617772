var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-container',{staticClass:"box-question rounded text-right"},[_c('b-row',{staticClass:"box-list-question my-2"},[_c('b-col',[_c('p',{staticClass:"rounded",domProps:{"innerHTML":_vm._s(_vm.text)}}),(_vm.hint && _vm.showHint)?_c('p',{ref:`msg-hint-${_vm.name}`,staticClass:"question-hint",domProps:{"innerHTML":_vm._s('Indicación: ' + _vm.hint)}}):_vm._e()])],1)],1),_c('b-container',[(
          _vm.optionalCheckbox !== null &&
          typeof _vm.optionalCheckbox !== 'undefined' &&
          _vm.showAnswers
        )?_c('b-row',[_c('b-col',[_c('b-form-checkbox',{staticClass:"optional-checkbox",attrs:{"name":"optionalCheckbox"},model:{value:(_vm.optionalCheckbox),callback:function ($$v) {_vm.optionalCheckbox=$$v},expression:"optionalCheckbox"}},[_c('p',{staticClass:"rounded",domProps:{"innerHTML":_vm._s(_vm.optionalCheckboxText)}})])],1)],1):_vm._e()],1),_c('b-container',[(_vm.answersLoaded && _vm.showAnswers)?_c('b-row',{class:{
          'options-type': _vm.type === 'options',
          'cards-type': _vm.type === 'cards',
          'options-counter-type': _vm.type === 'options-counter',
          'hybrid-options-type': _vm.type === 'hybrid-options',
          'selector-options-type': _vm.type === 'selector-options',
          'multiple-text-type': _vm.type === 'multiple-text',
          'text-area-type': _vm.type === 'text-area',
          'custom-component-type': _vm.type === 'custom-component',
        }},_vm._l((_vm.answers),function(answer,ansidx){return _c('b-col',{key:ansidx,staticClass:"col-4",class:answer.class},[(_vm.type === 'cards')?_c('div',[_c('b-card',{staticClass:"m-2 option-card text-center font-weight-bold w-100",class:{
                'selected-card': answer.isSelected,
              },attrs:{"header":answer.title},on:{"click":function($event){_vm.disabled
                  ? _vm.$emit('select-card', answer, _vm.msgidx, ansidx, _vm.max)
                  : null}}},[_c('b-card-text',{staticClass:"font-weight-normal",class:answer.bootstrap_classes},[_vm._v(" "+_vm._s(answer.name)+" ")])],1)],1):_vm._e(),(_vm.type === 'options')?_c('div',{staticClass:"w-100"},[(
                answer.isSelected ||
                !_vm.singleChoice ||
                (_vm.singleChoice &&
                  _vm.answers.filter((ans) => ans.isSelected).length < 1)
              )?_c('b-card',{staticClass:"text-center option-option format-text p-1",class:{ 'selected-option': answer.isSelected },on:{"click":function($event){_vm.disabled
                  ? _vm.$emit('select-card', answer, _vm.msgidx, ansidx, _vm.max)
                  : null}}},[_c('div',{class:{
                  'd-flex': answer.price || answer.priority,
                  'align-items-center': !answer.price && !answer.priority,
                  'justify-content-between': answer.price || answer.priority,
                }},[(answer.title)?_c('div',{staticClass:"font-weight-bold",domProps:{"innerHTML":_vm._s(answer.title)}}):_vm._e(),_c('div',{class:answer.bootstrap_classes,domProps:{"innerHTML":_vm._s(answer.name)}}),(answer.priority == 1)?_c('div',{staticClass:"d-flex align-items-center m-1"},[_c('v-icon',{attrs:{"name":"custom-star","scale":"1.5"}})],1):_vm._e()])]):_vm._e()],1):_vm._e(),(_vm.type === 'options-counter')?_c('div',[_c('b-row',{staticClass:"py-2 mb-1"},[_c('p',{domProps:{"innerHTML":_vm._s(answer.name_section)}})]),(
                answer.has_warning && !(answer[answer.key] >= answer.required)
              )?_c('b-row',{staticClass:"py-2 mb-1"},[_c('b-alert',{staticClass:"w-100",attrs:{"show":"","variant":"warning"}},[_vm._v(_vm._s(answer.warning_text))])],1):_vm._e(),_vm._l((answer.options),function(opt,optidx){return _c('b-row',{key:optidx,staticClass:"py-2 my-1"},[_c('b-col',{staticClass:"counter-container col-sm-2 my-auto mr-3",attrs:{"cols":"3"}},[_c('v-icon',{staticClass:"counter-button",attrs:{"name":"minus","scale":"0.8"},on:{"click":function($event){_vm.disabled ? _vm.$emit('decrease', opt, answer) : null}}}),_c('b-input',{staticClass:"no-arrow input-no-border input-counter",attrs:{"type":"number","disabled":""},model:{value:(opt.count),callback:function ($$v) {_vm.$set(opt, "count", $$v)},expression:"opt.count"}}),_c('v-icon',{staticClass:"counter-button",attrs:{"name":"plus","scale":"0.8"},on:{"click":function($event){_vm.disabled ? _vm.$emit('increase', opt, answer) : null}}})],1),_c('b-col',{staticClass:"my-auto counter-text-container",class:{ 'selected-option': opt.count > 0 || opt.isSelected }},[_c('p',{staticClass:"my-1",domProps:{"innerHTML":_vm._s(opt.name)}})])],1)})],2):_vm._e(),(_vm.type === 'hybrid-options')?_c('div',[_c('b-row',{staticClass:"mb-1"},[(!_vm.optionalCheckbox)?_c('b-col',{staticClass:"counter-container col-sm-2 my-auto mr-5",attrs:{"cols":"3"}},[_c('v-icon',{staticClass:"counter-button",attrs:{"name":"minus","scale":"1"},on:{"click":function($event){_vm.disabled ? _vm.handleDecreaseHybrid(answer) : null}}}),_c('b-input',{staticClass:"no-arrow input-no-border input-counter",attrs:{"type":"number","disabled":""},model:{value:(answer.count),callback:function ($$v) {_vm.$set(answer, "count", $$v)},expression:"answer.count"}}),_c('v-icon',{staticClass:"counter-button",attrs:{"name":"plus","scale":"1"},on:{"click":function($event){_vm.disabled ? _vm.handleIncreaseHybrid(answer) : null}}})],1):_vm._e(),_c('b-col',{staticClass:"my-auto counter-text-container",class:{
                  'selected-option': answer.count > 0 || answer.isSelected,
                },on:{"click":() => {
                    !_vm.optionalCheckbox
                      ? _vm.handleIncreaseHybrid(answer)
                      : _vm.$emit('select-card', answer, _vm.msgidx, ansidx, _vm.max);
                  }}},[_c('b-row',[_c('b-col',[_c('p',{staticClass:"my-auto",domProps:{"innerHTML":_vm._s(answer.name)}})]),(answer.priority == 1)?_c('b-col',{staticClass:"my-auto mr-3",attrs:{"cols":"2","md":"1"}},[_c('v-icon',{attrs:{"name":"custom-star","scale":"1.5"}})],1):_vm._e()],1)],1)],1)],1):_vm._e(),(_vm.type === 'selector-options')?_c('div',[_c('selector-component',{attrs:{"options":answer.options.map((opt) => {
                  return {
                    name: opt,
                  };
                }),"default":{
                name: answer.selected,
              },"placeholder":answer.placeholder},on:{"input":(option) => _vm.$emit('selector-change', _vm.msgidx, ansidx, option)}})],1):_vm._e(),(_vm.type === 'multiple-text')?_c('div',[_c('b-form-input',{staticClass:"text-input-answer mb-1",attrs:{"type":answer.type,"placeholder":answer.label},model:{value:(answer.value),callback:function ($$v) {_vm.$set(answer, "value", $$v)},expression:"answer.value"}})],1):_vm._e()])}),1):_vm._e()],1),(_vm.type === 'text-area')?_c('div',{staticClass:"box-answer mb-2"},[_c('b-form-textarea',{staticClass:"w-100 text-area-answer",attrs:{"rows":3,"placeholder":"Inserta tu respuesta aquí ..."},model:{value:(_vm.textInput),callback:function ($$v) {_vm.textInput=$$v},expression:"textInput"}})],1):_vm._e(),(!_vm.showAnswers && !_vm.answersLoaded)?_c('ul',{staticClass:"w-100"},[_c('b-row',[_c('b-col',{staticClass:"text-center"},[_c('b-spinner',{staticStyle:{"width":"3rem","height":"3rem"},attrs:{"type":"grow","label":"Loading..."}})],1)],1)],1):_vm._e(),(_vm.type === 'custom-component' && _vm.showAnswers)?_c('div',{staticClass:"box-answer mb-2"},[_c(_vm.component,{tag:"component",attrs:{"context":_vm.context}})],1):_vm._e(),_c('b-container',[(!_vm.singleChoice && _vm.answersLoaded && !_vm.isFinal && _vm.showAnswers)?_c('b-row',{staticClass:"continue-button py-2",class:_vm.answered ? 'continue-off' : 'continue-on',on:{"click":function($event){return _vm.handleContinue()}}},[_c('b-col',[_vm._v(" "+_vm._s(_vm.nextMessage ? _vm.nextMessage : "¡Listo! Siguiente")+" ")]),_c('b-col',{staticClass:"col-sm-1",attrs:{"cols":"2"}},[_c('v-icon',{attrs:{"name":_vm.answered
                ? 'continue-arrow-white'
                : 'continue-arrow-color-materials'}})],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }