<template>
  <div>
    <div class="item">
      <div class="toggle">
        <toggle-button
          variant="primary"
          @change="change_exclude_tag(tag.text)"
          :value="!exclude_tag"
          :sync="true"
          :width="38"
        />
      </div>
      <div class="description" v-b-toggle="'accordion-'+index">
        <span class="subtitle">{{ tag.text }}</span>
      </div>
    </div>
    <hr class="filter-divider" />
    <b-collapse :id="'accordion-'+index">
      <div v-for="(subtag, subindex) in tag.subtags" class="item ml-2" :key="'item-'+subtag.id">
        <div class="toggle">
          <toggle-button
            variant="primary"
            @change="change_subtag_value(subindex)"
            :value="!subtag.exclude"
            :sync="true"
            :width="38"
          />
        </div>
        <div class="description" @click="get_posts_by_subtag(subtag.text)">
          <span>{{ subtag.text }}</span>
        </div>
      </div>
    </b-collapse>
    <hr class="filter-divider" />
  </div>
</template>

<script>
import postService from "../../../services/postService";
export default {
  data: function() {
    return {
      exclude_tag: true
    };
  },
  created() {
    this.set_exclude_tag();
  },
  props: ["index", "tag"],
  methods: {
    get_posts: function() {
      this.$emit("get-posts");
    },
    get_posts_by_tag: function(tag) {
      this.$emit("get-posts-by-tag", tag);
    },
    get_posts_by_subtag: function(subtag) {
      this.$emit("get-posts-by-subtag", subtag);
    },
    change_subtag_value: function(subindex) {
      this.tag.subtags[subindex].exclude = !this.tag.subtags[subindex].exclude;
      // send ajax to save the preference
      postService
        .change_subtag_filter({
          text: this.tag.subtags[subindex].text,
          exclude: this.tag.subtags[subindex].exclude,
          type: "subtag"
        })
        .then(
          data => {
            this.get_posts();
            this.set_exclude_tag();
            this.$toasted.success(data.message);
          },
          error => {
            this.$toasted.error("Ha ocurrido un error al guardar la opción.");
            console.error(error);
          }
        );
    },
    change_exclude_tag: function(text) {
      this.exclude_tag = !this.exclude_tag;
      this.tag.subtags.forEach(subtag => {
        subtag.exclude = this.exclude_tag;
      });
      postService
        .change_subtag_filter({
          text: text,
          exclude: this.exclude_tag,
          type: "tag"
        })
        .then(
          data => {
            this.get_posts();
            this.$toasted.success(data.message);
          },
          error => {
            this.exclude_tag = !this.exclude_tag;
            this.tag.subtags.forEach(subtag => {
              subtag.exclude = this.exclude_tag;
            });
            this.$toasted.error("Ha ocurrido un error al guardar la opción.");
            console.error(error);
          }
        );
    },
    set_exclude_tag: function() {
      let check = false;
      this.tag.subtags.forEach(subtag => {
        check = check || subtag.exclude;
      });
      this.exclude_tag = check;
    }
  },
  watch: {
    // eslint-disable-next-line
    tag(new_value, old_value) {
      this.set_exclude_tag();
    }
  }
};
</script>

<style lang="scss">
hr.filter-divider {
  border-top: 1px solid rgb(248, 181, 0);
}

.item {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .toggle {
    width: 38px;
  }
  .description {
    width: calc(100% - 38px);
    padding-left: 12px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 22px;
  }
}
</style>