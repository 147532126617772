import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "../../store";
// import store from "../../store/modules/login.js";

//layouts
import Default from "../../layouts/utopie/Default";
import Design from "../../layouts/utopie/Design";
import Profile from "../../layouts/utopie/Profile";
import Fullscreen from "../../layouts/utopie/Fullscreen";
import NoSideProfile from "../../layouts/utopie/NoSideProfile";
import OnlyText from "../../layouts/utopie/OnlyText";
import Marketplace from "../../layouts/utopie/Marketplace"

// other libs
import axios from "axios";
import BootstrapVue from "bootstrap-vue";
import Echarts from "vue-echarts";
import Icon from "vue-awesome/components/Icon";
import InfiniteLoading from 'vue-infinite-loading';
import Multiselect from "vue-multiselect";
import SocialSharing from "vue-social-sharing";
import StarRating from "vue-star-rating";
import Toasted from "vue-toasted";
import ToggleButton from "vue-js-toggle-button";
import VueAuthenticate from "vue-authenticate";
import VueAxios from "vue-axios";
import vueFilterPrettyBytes from "vue-filter-pretty-bytes";
import VueMoment from "vue-moment";
import VueNativeSock from "vue-native-websocket";
import VueScrollTo from "vue-scrollto";
import VueCarousel from "vue-carousel";
import VueContext from "vue-context";
// import VueAnalytics from "vue-analytics";
import VueGtag from 'vue-gtag'
import IdleVue from "idle-vue";

// css
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";
import "echarts/lib/chart/bar";
import "vue-awesome/icons";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "@storefront-ui/vue/styles.scss";

const moment = require('moment')
require('moment/locale/es')


// svg icons
Icon.register({
  "c-attach": {
    width: 150,
    height: 150,
    raw: `<path class="st0" d="M104.41,35.59c0-5.6-2.08-13.71-6.64-18.27c-4.28-4.28-9.98-6.64-16.03-6.64c-6.06,0-11.76,2.36-16.05,6.65l0,0
	c-4.29,4.29-6.65,9.99-6.64,16.05l0,64.14c0,4.41,1.85,8.67,5.08,11.68c2.97,2.77,6.83,4.29,10.89,4.28c4.27,0,8.27-1.66,11.29-4.68
	c3.02-3.02,4.68-7.03,4.67-11.29l0-49.12c0.01-1.23-0.45-2.47-1.39-3.4c-1.86-1.86-4.87-1.86-6.72,0c-0.93,0.93-1.39,2.14-1.39,3.36
	h0l0,49.17c0,1.75-0.78,3.4-2.01,4.59c-1.18,1.14-2.76,1.86-4.45,1.86c-1.64,0-3.2-0.62-4.39-1.72c-1.33-1.24-2.06-2.92-2.06-4.73
	l0-64.14c0-3.52,1.37-6.83,3.86-9.33l0,0c2.49-2.49,5.81-3.86,9.33-3.86c3.52,0,6.82,1.37,9.31,3.85c2.09,2.09,3.85,7.38,3.86,11.54
	l0.01,74.31c0,10.89-8.79,19.76-19.63,19.9l-0.72,0c-10.77-0.24-19.46-9.08-19.46-19.91l0-61.5c0-1.23-0.46-2.45-1.39-3.39
	c-1.86-1.86-4.87-1.86-6.73,0c-0.93,0.93-1.39,2.14-1.39,3.36h0l0,61.53c0.01,16.14,13.07,29.29,29.18,29.42l0.73,0
	c15.99-0.26,28.93-13.36,28.92-29.41L104.41,35.59z"/>`
  }
});
Icon.register({
  "c-poll": {
    width: 150,
    height: 150,
    raw: `<path class="st0" d="M22.84,116.2c3.15,0,5.7-2.55,5.7-5.7V56.61c0-6.29,5.12-11.4,11.4-11.4h70.1c6.29,0,11.4,5.12,11.4,11.4
		v53.88c0,3.15,2.55,5.7,5.7,5.7c3.15,0,5.7-2.55,5.7-5.7V56.61c0-12.58-10.23-22.81-22.81-22.81h-70.1
		c-12.58,0-22.81,10.23-22.81,22.81v53.88C17.14,113.64,19.69,116.2,22.84,116.2z"/>
	<path class="st0" d="M110.05,73.72c0-3.15-2.55-5.7-5.7-5.7H61.04c-3.15,0-5.7,2.55-5.7,5.7c0,3.15,2.55,5.7,5.7,5.7h43.31
		C107.5,79.42,110.05,76.87,110.05,73.72z"/>
	<circle class="st0" cx="45.62" cy="96.52" r="5.7"/>
	<circle class="st0" cx="45.62" cy="73.72" r="5.7"/>
	<path class="st0" d="M61.04,90.82c-3.15,0-5.7,2.55-5.7,5.7c0,3.15,2.55,5.7,5.7,5.7h20.21c3.15,0,5.7-2.55,5.7-5.7
		c0-3.15-2.55-5.7-5.7-5.7H61.04z"/>`
  }
});
Icon.register({
  "c-star-on": {
    width: 150,
    height: 150,
    raw: `<polygon class="st0" points="75,117.57 33.38,139.45 41.33,93.11 7.66,60.29 54.19,53.52 75,11.36 95.81,53.52 142.34,60.29
	108.67,93.11 116.62,139.45 "/>`
  }
});
Icon.register({
  "c-star-off": {
    width: 150,
    height: 150,
    raw: `<path class="st0" d="M75,17.57l18.18,36.84l0.68,1.38l1.52,0.22l40.66,5.91L106.63,90.6l-1.1,1.07l0.26,1.52l6.95,40.5l-36.37-19.12
	L75,113.86l-1.36,0.72l-36.37,19.12l6.95-40.5l0.26-1.52l-1.1-1.07L13.95,61.93l40.66-5.91l1.52-0.22l0.68-1.38L75,17.57 M75,10.95
	L54.19,53.12L7.66,59.88L41.33,92.7l-7.95,46.34L75,117.17l41.62,21.88l-7.95-46.34l33.67-32.82l-46.53-6.76L75,10.95L75,10.95z"/>`
  }
});
Icon.register({
  "c-doc": {
    width: 150,
    height: 150,
    raw: `
<g>
	<path class="st0" d="M113.55,139.86H22.99c-2.06,0-3.73-1.67-3.73-3.73V75.84c0-2.06,1.67-3.73,3.73-3.73s3.73,1.67,3.73,3.73
		v56.55h83.09v-8.24c0-2.06,1.67-3.73,3.73-3.73c2.06,0,3.73,1.67,3.73,3.73v11.97C117.28,138.19,115.61,139.86,113.55,139.86z"/>
	<g>
		<path class="st0" d="M127.01,62.7h-9.73V15.09c0-2.06-1.67-3.73-3.73-3.73H62.09c-0.05,0-0.09,0.01-0.13,0.01
			c-0.04,0-0.09,0-0.13,0.01c-0.16,0.01-0.32,0.03-0.48,0.06c0,0-0.01,0-0.01,0c-0.16,0.03-0.31,0.08-0.46,0.13
			c-0.04,0.01-0.08,0.03-0.11,0.04c-0.15,0.06-0.29,0.12-0.43,0.19c0,0-0.01,0-0.01,0.01c-0.14,0.08-0.28,0.17-0.41,0.26
			c-0.03,0.02-0.07,0.05-0.1,0.07c-0.13,0.1-0.26,0.21-0.38,0.33l-39.1,39.52c-0.09,0.09-0.17,0.18-0.25,0.28
			c-0.05,0.06-0.1,0.13-0.14,0.2c-0.02,0.03-0.05,0.06-0.07,0.1c-0.05,0.08-0.1,0.16-0.15,0.25c-0.01,0.02-0.03,0.04-0.04,0.06
			c-0.05,0.09-0.09,0.17-0.12,0.26c-0.01,0.02-0.02,0.04-0.03,0.07c-0.03,0.08-0.06,0.17-0.09,0.26c-0.01,0.03-0.02,0.06-0.03,0.09
			c-0.02,0.08-0.04,0.16-0.06,0.24c-0.01,0.04-0.02,0.08-0.03,0.12c-0.01,0.07-0.02,0.15-0.03,0.22c-0.01,0.05-0.02,0.09-0.02,0.14
			c-0.01,0.07-0.01,0.14-0.01,0.22c0,0.05-0.01,0.09-0.01,0.14v1.2c0,2.06,1.67,3.73,3.73,3.73h39.1c2.06,0,3.73-1.67,3.73-3.73
			V18.83h43.99V62.7h-57.1c-2.06,0-3.73,1.67-3.73,3.73v41.07c0,2.06,1.67,3.73,3.73,3.73h74.3c2.06,0,3.73-1.67,3.73-3.73V66.43
			C130.75,64.37,129.07,62.7,127.01,62.7z M58.36,52.08H30.75L44.87,37.8l13.48-13.63L58.36,52.08L58.36,52.08z M123.28,103.77
			H56.44v-33.6h66.84V103.77z"/>
		<path class="st0" d="M72.49,75.08c4.33,0,7.71,2.01,7.71,7.4v8.12c0,5.39-3.39,7.4-7.71,7.4h-5.55c-1.29,0-2.13-0.69-2.13-1.47
			V76.56c0-0.78,0.85-1.47,2.13-1.47L72.49,75.08L72.49,75.08z M69.7,79.35v14.39h2.79c1.76,0,2.82-1,2.82-3.14v-8.12
			c0-2.13-1.07-3.14-2.82-3.14L69.7,79.35L69.7,79.35z"/>
		<path class="st0" d="M83.06,90.8v-8.31c0-5.39,3.36-7.4,7.68-7.4c4.33,0,7.71,2.01,7.71,7.4v8.31c0,5.39-3.39,7.4-7.71,7.4
			C86.42,98.2,83.06,96.19,83.06,90.8z M93.57,82.49c0-2.16-1.07-3.14-2.82-3.14s-2.79,0.97-2.79,3.14v8.31
			c0,2.16,1.03,3.14,2.79,3.14s2.82-0.97,2.82-3.14V82.49z"/>
		<path class="st0" d="M109.06,79.35c-1.79,0-2.82,1-2.82,3.14v8.31c0,2.13,1.03,3.14,2.85,3.14c2.51,0,2.67-1.91,2.76-3.14
			c0.09-1.16,1.16-1.47,2.41-1.47c1.69,0,2.48,0.44,2.48,2.32c0,4.17-3.39,6.55-7.87,6.55c-4.11,0-7.53-2.01-7.53-7.4v-8.31
			c0-5.39,3.42-7.4,7.53-7.4c4.48,0,7.87,2.26,7.87,6.24c0,1.88-0.78,2.32-2.45,2.32c-1.32,0-2.38-0.35-2.45-1.47
			C111.82,81.36,111.73,79.35,109.06,79.35z"/>
	</g>
</g>`
  }
});
Icon.register({
  "c-award": {
    width: 150,
    height: 150,
    raw: `
<g>
	<path class="st0" d="M124.25,56.7l-9.75-10.07l1.96-13.88c0.3-2.12-1.14-4.1-3.25-4.47l-13.8-2.42l-6.57-12.38
		c-1-1.89-3.33-2.65-5.25-1.71L75,17.92l-12.59-6.15c-1.93-0.94-4.25-0.19-5.25,1.71l-6.57,12.38l-13.81,2.42
		c-2.11,0.37-3.55,2.35-3.25,4.47l1.96,13.88L25.75,56.7c-1.49,1.54-1.49,3.99,0,5.53l9.75,10.07l-1.96,13.88
		c-0.3,2.12,1.14,4.1,3.25,4.47l11.05,1.94v42.97c0,1.38,0.71,2.66,1.88,3.38c0.64,0.39,1.36,0.59,2.09,0.59
		c0.61,0,1.22-0.14,1.78-0.42L75,128.4l21.42,10.71c1.23,0.62,2.69,0.55,3.87-0.17c1.17-0.72,1.88-2,1.88-3.38V92.58l11.05-1.94
		c2.11-0.37,3.55-2.35,3.25-4.47l-1.96-13.88l9.75-10.07C125.74,60.68,125.74,58.24,124.25,56.7z M76.78,120.41
		c-1.12-0.56-2.43-0.56-3.55,0l-17.45,8.72v-26.28l1.38,2.6c1,1.89,3.33,2.65,5.26,1.71L75,101l12.59,6.16
		c0.56,0.27,1.16,0.4,1.74,0.4c1.43,0,2.8-0.77,3.51-2.11l1.38-2.6v26.28L76.78,120.41z M107.45,68.16
		c-0.85,0.88-1.25,2.11-1.08,3.32l1.7,12l-11.93,2.09c-1.21,0.21-2.25,0.97-2.82,2.05l-5.68,10.7l-10.88-5.32
		c-0.55-0.27-1.15-0.4-1.75-0.4c-0.6,0-1.2,0.13-1.75,0.4l-10.88,5.32l-5.68-10.7c-0.57-1.08-1.62-1.84-2.82-2.05l-11.93-2.09
		l1.7-12c0.17-1.21-0.23-2.44-1.08-3.32l-8.42-8.71l8.42-8.71c0.85-0.88,1.25-2.11,1.08-3.32l-1.7-11.99l11.93-2.09
		c1.21-0.21,2.25-0.97,2.82-2.05l5.68-10.7l10.88,5.32c1.1,0.54,2.39,0.54,3.49,0l10.88-5.32l5.68,10.7
		c0.57,1.08,1.62,1.84,2.82,2.05l11.93,2.09l-1.7,11.99c-0.17,1.21,0.23,2.44,1.08,3.32l8.42,8.71L107.45,68.16z"/>
	<path class="st0" d="M75,32.29c-14.98,0-27.17,12.19-27.17,27.17c0,14.98,12.19,27.18,27.17,27.18c14.98,0,27.17-12.19,27.17-27.18
		C102.17,44.48,89.98,32.29,75,32.29z M75,78.69c-10.6,0-19.23-8.63-19.23-19.23c0-10.6,8.63-19.22,19.23-19.22
		c10.6,0,19.22,8.62,19.22,19.22C94.23,70.06,85.6,78.69,75,78.69z"/>
	<path class="st0" d="M83.1,49.38L71.36,61.11l-4.47-4.46c-1.55-1.55-4.07-1.55-5.62,0c-1.55,1.55-1.55,4.07,0,5.62l7.28,7.27
		c0.78,0.78,1.79,1.16,2.81,1.16c1.02,0,2.03-0.39,2.81-1.16L88.72,55c1.55-1.55,1.55-4.07,0-5.62
		C87.17,47.83,84.65,47.83,83.1,49.38z"/>
</g>`
  }
});

Icon.register({
  "continue-arrow-color": {
    width: 150,
    height: 150,
    raw: `
    <svg id="Capa_2" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.27 19.94">
    <defs>
      <style>
        .cls-1 {
          fill: #00b9ae;
        }
      </style>
    </defs>
    <g id="Capa_13" data-name="Capa 13">
      <g id="Capa_21" data-name="Capa 21">
        <polygon class="cls-1" points="0 19.94 17.27 9.97 0 0 0 19.94"/>
      </g>
    </g>
  </svg>`,
  },
});
Icon.register({
  "continue-arrow-color-materials": {
    width: 150,
    height: 150,
    raw: `
    <svg id="Capa_2" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.27 19.94">
    <defs>
      <style>
        .cls-1 {
          fill: #E84855;
        }
      </style>
    </defs>
    <g id="Capa_13" data-name="Capa 13">
      <g id="Capa_21" data-name="Capa 21">
        <polygon class="cls-1" points="0 19.94 17.27 9.97 0 0 0 19.94"/>
      </g>
    </g>
  </svg>`,
  },
});

Icon.register({
  "continue-arrow-white": {
    width: 150,
    height: 150,
    raw: `
    <svg id="Capa_2" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.27 19.94">
    <defs>
      <style>
        .cls-1-1 {
          fill: #fff;
        }
      </style>
    </defs>
    <g id="Capa_13" data-name="Capa 13">
      <g id="Capa_21" data-name="Capa 21">
        <polygon class="cls-1-1" points="0 19.94 17.27 9.97 0 0 0 19.94"/>
      </g>
    </g>
  </svg>`,
  },
});

Icon.register({
  "selector-arrow-color": {
    width: 150,
    height: 150,
    raw: `
    <svg id="Capa_2" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.94 17.27">
    <defs>
      <style>
        .cls-1 {
          fill: #00b9ae;
        }
      </style>
    </defs>
    <g id="Capa_13" data-name="Capa 13">
      <g id="Capa_21" data-name="Capa 21">
        <polygon class="cls-1" points="9.97 17.27 19.94 0 0 0 9.97 17.27"/>
      </g>
    </g>
  </svg>
   `     
  }
})
Icon.register({
  "download-white": {
    width: 150,
    height: 150,
    raw: `
    <svg id="Capa_2" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.82 32">
  <defs>
    <style>
      .download-white {
        fill: #fff;
      }
    </style>
  </defs>
  <g id="Capa_13" data-name="Capa 13">
    <g id="Capa_20" data-name="Capa 20">
      <g>
        <path class="download-white" d="M29.82,25.12v4.59c0,1.26-1.03,2.29-2.29,2.29H2.29c-1.26,0-2.29-1.03-2.29-2.29v-4.59c0-1.26,1.03-2.29,2.29-2.29h7.4l2.9,3.99c.54,.73,1.41,1.17,2.32,1.17s1.78-.44,2.32-1.18l2.9-3.98h7.4c1.26,0,2.29,1.03,2.29,2.29h0Z"/>
        <path class="download-white" d="M9.14,16.06h2.33V1.15c0-.63,.51-1.15,1.15-1.15h4.59c.63,0,1.15,.51,1.15,1.15v14.91h2.33c.94,0,1.48,1.06,.93,1.82l-5.77,7.92c-.46,.63-1.4,.63-1.85,0l-5.77-7.92c-.55-.76-.01-1.82,.93-1.82Z"/>
      </g>
    </g>
  </g>
</svg>`,
  },
});
Icon.register({
  "download-color": {
    width: 150,
    height: 150,
    raw: `
    <svg id="Capa_2" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.82 32">
    <defs>
      <style>
        .download-color {
          fill: #00b9ae;
        }
      </style>
    </defs>
    <g id="Capa_13" data-name="Capa 13">
      <g id="Capa_21" data-name="Capa 21">
        <g>
          <path class="download-color" d="M29.82,25.12v4.59c0,1.26-1.03,2.29-2.29,2.29H2.29c-1.26,0-2.29-1.03-2.29-2.29v-4.59c0-1.26,1.03-2.29,2.29-2.29h7.4l2.9,3.99c.54,.73,1.41,1.17,2.32,1.17s1.78-.44,2.32-1.18l2.9-3.98h7.4c1.26,0,2.29,1.03,2.29,2.29h0Z"/>
          <path class="download-color" d="M9.14,16.06h2.33V1.15c0-.63,.51-1.15,1.15-1.15h4.59c.63,0,1.15,.51,1.15,1.15v14.91h2.33c.94,0,1.48,1.06,.93,1.82l-5.77,7.92c-.46,.63-1.4,.63-1.85,0l-5.77-7.92c-.55-.76-.01-1.82,.93-1.82Z"/>
        </g>
      </g>
    </g>
  </svg>`,
  },
});

Icon.register({
  "custom-star": {
    width: 150,
    height: 150,
    raw: `
    <svg id="Capa_2" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.36 29.82">
    <defs>
      <style>
        .star-class {
          fill: #f8b500;
          stroke: #1d1d1b;
          stroke-miterlimit: 10;
        }
      </style>
    </defs>
    <g id="Capa_8" data-name="Capa 8">
      <g id="Capa_10" data-name="Capa 10">
        <polygon class="star-class" points="15.68 1.13 20.19 10.27 30.28 11.74 22.98 18.86 24.7 28.91 15.68 24.16 6.65 28.91 8.38 18.86 1.07 11.74 11.17 10.27 15.68 1.13"/>
      </g>
    </g>
  </svg>`,
  },
});

// components
Vue.component("chart", Echarts);
Vue.component("multiselect", Multiselect);
Vue.component("star-rating", StarRating);
Vue.component("v-icon", Icon);
Vue.component("vue-context", VueContext);

// use
// Vue.use(VueAnalytics, {
//   id: process.env.VUE_APP_ANALYTICS_ID,
//   router
// });
Vue.use(VueGtag, {
  config: {
      id: process.env.VUE_APP_ANALYTICS_ID,
      params: {
          send_page_view: false
      }
  }
}, router)
Vue.use(VueAxios, axios);
Vue.use(VueAuthenticate, {
  providers: {
    facebook: {
      clientId: process.env.VUE_APP_FACEBOOK_ID, //your Facebook App ID e.g. 12345667890
      redirectUri: process.env.VUE_APP_BASE_URL,
      responseType: "token",
      authorizationEndpoint: "https://www.facebook.com/v3.2/dialog/oauth"
    },
    google: {
      clientId: process.env.VUE_APP_GOOGLE_ID,
      redirectUri: process.env.VUE_APP_BASE_URL,
      name: 'google',
      url: '/auth/google',
      responseType: "token",
      pluginName: "utopie",
      authorizationEndpoint: 'https://accounts.google.com/o/oauth2/auth',
      requiredUrlParams: ['scope'],
      optionalUrlParams: ['display'],
      scope: ['email', 'profile'],
      scopePrefix: 'openid',
      scopeDelimiter: ' ',
      display: 'popup',
      oauthType: '2.0',
      popupOptions: { width: 452, height: 633 },
    },
  }
});
Vue.use(BootstrapVue);
Vue.use(SocialSharing);
Vue.use(VueCarousel);

const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 2400000, // 40 minutes
  startAtIdle: false
});

Vue.use(vueFilterPrettyBytes);
Vue.use(Toasted, {
  position: "bottom-center",
  duration: 5000,
  theme: "outline",
  action: {
    text: "x",
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    }
  }
});

Vue.use(ToggleButton);
Vue.use(VueMoment, {moment});
Vue.use(
  VueNativeSock,
  process.env.VUE_APP_SOCKET_URL + "/socket/" + localStorage.getItem("token"),
  {
    connectManually: true,
    store: store,
    format: "json"
  }
);
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: -70,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});
Vue.use(InfiniteLoading);

// facebook
window.fbAsyncInit = function() {
  // eslint-disable-next-line
  FB.init({
    appId: process.env.VUE_APP_FACEBOOK_ID,
    xfbml: true,
    version: "v2.10"
  });
};
(function(d, s, id) {
  var js,
    fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) {
    return;
  }
  js = d.createElement(s);
  js.id = id;
  js.src = "//connect.facebook.net/en_US/sdk.js";
  fjs.parentNode.insertBefore(js, fjs);
})(document, "script", "facebook-jssdk");

// layouts
Vue.component("default", Default);
Vue.component("profile", Profile);
Vue.component("no_side_profile", NoSideProfile);
Vue.component("design", Design);
Vue.component("fullscreen", Fullscreen);
Vue.component("marketplace", Marketplace)
Vue.component('onlytext', OnlyText);
// app
new Vue({
  store: store,
  router,
  render: h => h(App)
}).$mount("#app");
