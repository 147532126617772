<template>
  <b-container fluid class="px-0">
    <div>
      <start id="start" ref="start" :route="routeName"></start>
      <about-us
        id="us"
        ref="us"
        @redirect-to-school="$emit('redirect-to-school')"
        :route="routeName"
        @free-trial-login="$emit('open-login')"
      ></about-us>
      <our-services
        id="services"
        ref="services"
        :route="routeName"
      ></our-services>
      <pricing
        id="pricing"
        ref="pricing"
        :route="routeName"
        v-if="routeName === 'redpie'"
      ></pricing>
      <subscribe
        id="subscribe"
        ref="subscribe"
        :route="routeName"
        v-if="routeName !== 'planification'"
      ></subscribe>
      <our-customers
        id="customers"
        ref="customers"
        :route="routeName"
      ></our-customers>
      <customer-experiences :route="routeName"></customer-experiences>
      <!-- <contact-us id="contact" ref="contact" :route="routeName"></contact-us> -->
    </div>
  </b-container>
</template>

<script>
import Start from "@/components/sales/miscellaneous/Start";
import AboutUs from "@/components/sales/miscellaneous/AboutUs";
import OurServices from "@/components/sales/miscellaneous/OurServices";
import Pricing from "@/components/sales/miscellaneous/Pricing";
import Subscribe from "@/components/sales/miscellaneous/Subscribe";
import OurCustomers from "@/components/sales/miscellaneous/OurCustomers";
import ContactUs from "@/components/sales/miscellaneous/ContactUs";
import CustomerExperiences from "@/components/sales/miscellaneous/CustomerExperiences";

export default {
  name: "Sales",
  components: {
    CustomerExperiences,
    ContactUs,
    OurCustomers,
    Subscribe,
    Pricing,
    OurServices,
    AboutUs,
    Start,
  },
  data() {
    return {
      logo: require("../../../assets/img/sales/logo-alt.svg"),
      section: "start",
    };
  },
  mounted() {
    if (!this.routeName) {
      this.$router.push({ name: "home" });
    }
  },
  props: {
    routeName: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped>
.nav-link {
  color: white !important;
}

.active {
  font-weight: bold;
  color: #e0a800 !important;
}
</style>
