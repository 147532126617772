<template>
    <b-container>
        <b-row>
            <h3>Elimina tu cuenta</h3>
        </b-row>
        <b-row>
            <b-button variant="danger" @click="deleteAccount">
                Eliminar
            </b-button>
        </b-row>
    </b-container>    
</template>
<script>
import userService from "../../../services/userService"

export default {
    data() {
        return {
            
        }
    },
    methods: {
        deleteAccount() {
            userService.deleteAccount().then(
                data => {
                    this.$toasted.success(data.message);
                    window.location.href = "/utopie#/";
                }
            )
        }
    }
}
</script>
<style lang="scss">

</style>
