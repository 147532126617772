<template>
  <b-modal
    centered
    ref="incomponent-modal-automatic-test"
    size="lg"
    hide-footer
    hide-header
  >
    <div class="incomponent-modal-automatic-test">
      <div
        class="close"
        title="Cerrar"
        v-on:click="$refs['incomponent-modal-automatic-test'].hide()"
      >
        <v-icon name="times" />
      </div>
      <b-container>
        <b-row class="text-center mb-5">
          <b-col>
            <h1>Tests</h1>
          </b-col>
        </b-row>
        <b-row class="border-bottom mb-2">
          <b-col class="font-weight-bold">Test</b-col>
          <b-col cols="2"></b-col>
        </b-row>
        <b-row
          class="border-bottom mb-2"
          v-for="(test, test_index) in tests"
          v-bind:key="test_index"
        >
          <b-col class="font-weight-bold">{{test.text}}</b-col>
          <b-col cols="2" class="text-center">
            <b-button
                class="btn btn-primary btn-sm"
                @click="applyTest(test.value)"
              >
              Realizar Test
              </b-button>
          </b-col>
        </b-row>

        <b-row class="buttons-footer text-right mt-5" >
          <b-col>
            <b-button
              variant="light"
              class="radius-box-16"
              v-on:click="$refs['incomponent-modal-automatic-test'].hide()"
              >Cerrar</b-button
            >
          </b-col>
        </b-row>
      </b-container>
    </div>
  </b-modal>
</template>

<script>
import testService from "../../../services/testService"

export default {
  created() {
    testService.getTests().then(
      data => {
        this.tests = data.tests.map(test => Object({value: test.test, text: test.test_name}));
      }
    )
  },
  data() {
    return {
      selected: null,
      tests: [],
    };
  },
  methods: {
    open() {
      this.$refs["incomponent-modal-automatic-test"].show();
    },
    applyTest: function(test) {
      this.new_test(test)
    },
    new_test: function(test) {
      testService
        .do_test({ test: test})
        .then(
          data => {
            this.$store.commit("login/setSchool", data.school_id);
            window.location.href = data.redirect;
          },
          error => {
            console.error(error);
            this.$toasted.error("Ha ocurrido al hacer el test");
          }
        );
    },
  }
};
</script>

<style lang="scss">
.background-white {
  background-color: white;
}

.incomponent-modal-automatic-test {
  position: relative;
  color: $dark !important;

  .title {
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }
  .close {
    position: absolute;
    right: 0;
    top: -12px;
    cursor: pointer;
  }

  .modal-container {
    width: 100%;
    padding: 32px 16px;

    .select-modal-option {
      margin: 24px 0;
    }

    .modal-by-selected-option {
      width: 100%;
      margin-bottom: 84px;

      .modal-by-selected-option-items {
        width: 100%;

        .option-item {
          padding: 8px 12px;
          position: relative;
          box-sizing: border-box;

          .checkbox {
            position: absolute;
            left: 6px;
            top: calc(50% - 14px);
          }
          .info-text {
            margin-left: 24px;
            margin-right: 64px;
            width: calc(100% - 24px - 64px);

            p {
              margin: 0;
              line-height: 1;
              font-size: 14px;
            }
          }
          .button-edit {
            position: absolute;
            right: 0;
            top: calc(50% - 14px);
            width: 64px;
            text-align: center;
          }
        }
        .option-item:hover {
          background-color: $ultralight;
        }
        .option-item.disabled {
          color: #9a9a9a;
        }
      }
    }

    .buttons-footer {
      position: relative;
      text-align: center;

      button {
        display: inline-block;
        padding-left: 32px;
        padding-right: 32px;
        color: $dark;
        font-weight: bold;
      }
    }
  }
}
</style>
