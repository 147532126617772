<template>
  <div class="custom-application">
    <ApplicationModal
      ref="application-modal"
      :application="selectedApplicationId"
      :context="context"
      :gradeCode="selectedGradeCode"
      :appStudents="students"
      @add-to-application="addToApplication"
      @create-application="createApplication"
    />
    <div v-if="!isCreatingMaterial">
      <b-row>
        <b-col cols="12">
          <SelectorComponent
            :options="gradesOptions"
            :default="selectedGrade"
            :disabled="isCreatingMaterial"
            @input="setGrade"
            placeHolder="Selecciona un nivel"
          />
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="8">
          <SelectorComponent
            :options="subjectsOptions"
            :default="selectedSubject"
            :disabled="isCreatingMaterial"
            @input="setSubject"
            placeHolder="Selecciona una asignatura"
          />
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="8">
          <SelectorComponent
            :options="appsOptions"
            :default="selectedApp"
            :disabled="isCreatingMaterial"
            @input="setApp"
            placeHolder="Selecciona una aplicación"
          />
        </b-col>
      </b-row>
    </div>
    <b-row class="px-3 table-header">
      <b-col cols="5" class="mr-0 d-flex flex-column">
        <b-row class="table-header-col flex-grow-1">
          <b-col cols="6">Nombre estudiante</b-col>
          <b-col cols="6">Estado de la aplicacion </b-col>
        </b-row>
      </b-col>
      <b-col cols="7" class="ml-0 pl-4 d-flex flex-column">
        <b-row class="table-header-col flex-grow-1">
          <b-col cols="4">Logrado</b-col>
          <b-col cols="4">Parcialmente Logrado</b-col>
          <b-col cols="4">No Logrado</b-col>
        </b-row>
      </b-col>
    </b-row>
    <div v-if="loading">
      <b-row>
        <b-col class="text-center">
          <b-spinner
            style="width: 3rem; height: 3rem"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <div v-if="!grades.length">No hay estudiantes en esta aplicación</div>
      <div v-for="(grade, gIndex) in grades" :key="gIndex + '-grade'">
        <span style="width: 100%; display: block" class="text-left px-3">
          <strong>{{ grade.name }}</strong>
        </span>
        <div
          v-for="(student, studentIdx) in grade.students"
          class="px-3 custom-table-body"
          :key="studentIdx"
        >
          <student-response-row :student="student" :test="test" />
        </div>
      </div>
    </div>
    <div class="d-md-flex justify-content-md-start mt-2">
      <div
        @click="openApplicationModal"
        variant="warning"
        style="
          color: #fff;
          font-size: 10pt;
          display: flex;
          justify-content: center;
          align-items: center;
        "
        class="btn-md my-auto custom-button"
      >
        <span style="margin-right: 10px">Añadir estudiante</span>
        <v-icon name="plus" fill="white" scale="0.8" />
      </div>
    </div>
  </div>
</template>
<script>
import StudentResponseRow from "./util-components/StudentResponseRow.vue";
import SelectorComponent from "./util-components/SelectorComponent.vue";
import ApplicationModal from "./ApplicationModal.vue";
import customMaterialService from "@/services/customMaterialService";
import informationService from "@/services/informationService";
import materialDocumentSocket from "@/mixins/materialDocumentSocket";

export default {
  name: "ApplicationComponent",
  components: {
    SelectorComponent,
    StudentResponseRow,
    ApplicationModal,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
    applicationId: {
      type: String,
      required: false,
    },
  },
  mixins: [materialDocumentSocket],
  data() {
    return {
      test: {
        test_id: 1,
        started: true,
      },
      selectedApplicationId: "",
      gradesOptions: [],
      gradesRawOptions: [],
      selectedGrade: {},
      selectedGradeCode: "",
      subjectsOptions: [],
      subjectsRawOptions: [],
      selectedSubject: {},
      selectedSubjectId: "",
      appsOptions: [],
      appsRawOptions: [],
      selectedApp: {},
      grades: [],
      isCreatingMaterial: false,
      loading: false,
      students: [],
      materialId: "",
    };
  },
  mounted() {
    if (this.applicationId) {
      this.selectedApplicationId = this.applicationId;
      this.getLevelLinks();
    }

    if (this.$store.state.chatbot.conversation.material) {
      this.isCreatingMaterial = true;
      this.materialId =
        this.$store.state.chatbot.conversation.material.createdMaterial.id;
      this.gradesOptions =
        this.$store.state.chatbot.conversation.grade.answers.map((o) => {
          return {
            name: o.name,
            value: o.code,
          };
        });
      this.selectedGrade = this.context.grade.original_name;
      this.selectedGradeCode = this.context.grade.code;
      this.subjectsOptions =
        this.$store.state.chatbot.conversation.subject.answers.map((o) => {
          return {
            name: o.name,
          };
        });
      this.selectedSubject = this.context.subject.name;
    }
    if (!this.$store.state.chatbot.conversation.material) {
      // get grades
      informationService.getPieGrades().then((data) => {
        data["grades"]
          .filter((g) => ![14, 15, 16].includes(g[0]))
          .forEach((g) => {
            this.gradesOptions.push({
              name: g[1],
              value: g[0],
            });
            this.gradesRawOptions.push(g);
          });
        // this.selectedGrade = this.gradesOptions[0];
        // this.selectedGradeCode = {}
      });
      // get subjects
      informationService.getCardSubjectsValues().then((data) => {
        data
          .filter((s) => [7, 29].includes(s[0]))
          .forEach((s) => {
            this.subjectsOptions.push({
              name: s[1],
              value: s[0],
            });
            this.subjectsRawOptions.push({
              name: s[1],
              value: s[0],
            });
          });
        this.selectedSubject = this.subjectsOptions[0];
        this.selectedSubjectId = this.subjectsRawOptions[0][0];
      });
      this.getApps();
    }
  },
  watch: {
    selectedGrade: function () {
      this.getApps();
    },
    selectedSubject: function () {
      this.getApps();
    },
    selectedApp: function () {
      this.getLevelLinks();
    },
    grades(val) {
      // grades es un arreglo que a lo mas tiene un elemento, por lo tanto si tiene un elemento toma el primero y trae el elemento students, dentro de cada student hay un student_grade que tiene un id, con esos ids se contruye el arreglo students
      this.students = val.length
        ? val[0].students.map((s) => s.student_grade.id)
        : [];
    },
  },
  methods: {
    openApplicationModal() {
      this.$refs["application-modal"].open();
    },
    addToApplication(data) {
      const payload = {
        data: data,
        year: this.$store.state.login.year,
        school: this.$store.state.login.school,
        material: this.materialId,
      };

      customMaterialService
        .addStudentsApplication(this.selectedApplicationId, payload)
        .then(() => {
          this.$toasted.success("Estudiantes añadidos!");
          this.getLevelLinks();
          this.$refs["application-modal"].hide();
        });
    },
    joinSchoolSocket() {
      this.$socket.sendObj({
        type: "join_school",
        school_id: this.$store.state.login.school,
      });
    },
    createApplication(data) {
      const payload = {
        data: data,
        year: this.$store.state.login.year,
        school: this.$store.state.login.school,
      };
      customMaterialService
        .createApplication(this.materialId, payload)
        .then((data) => {
          this.$toasted.success("Aplicación creada!");
          this.selectedApplicationId = data.id;
          this.joinApplication(data.id);
          customMaterialService
            .getLevelLinks(data.id, this.selectedGradeCode)
            .then((data) => {
              this.grades = data.grades;
            });
          this.$refs["application-modal"].hide();
        });
    },
    getApps() {
      if (this.selectedSubjectId && this.selectedGradeCode) {
        customMaterialService
          .getFilteredApplications(
            this.$store.state.login.school,
            this.selectedSubjectId,
            this.selectedGradeCode
          )
          .then((data) => {
            this.appsOptions = data.map((a) => {
              return {
                name: a.material.name,
                value: a.id,
                materialId: a.material.id,
              };
            });
            this.appsRawOptions = data;
          });
      }
    },
    getLevelLinks() {
      this.loading = true;
      customMaterialService
        .getLevelLinks(this.selectedApplicationId, this.selectedGradeCode)
        .then((data) => {
          this.grades = data.grades;
          this.loading = false;
        })
        .catch(() => {
          this.$toasted.error("Ocurrió un error al traer los links");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setGrade(grade) {
      this.selectedGrade = grade;
      this.selectedGradeCode = grade.value;
    },
    setSubject(subject) {
      this.selectedSubject = subject;
      this.selectedSubjectId = subject.value;
    },
    setApp(app) {
      this.selectedApp = app;
      this.selectedApplicationId = app.value;
      this.leaveApplication();
      this.joinApplication(app.value);
      this.joinSchoolSocket();
      this.materialId = app.materialId;
    },
  },
};
</script>
<style lang="scss">
.table-header-col {
  border: 1.5px solid #e84855;
  border-radius: 6px;
}

.custom-table-body {
  border-top: 1px dashed #e84855;
  border-bottom: 1px dashed #e84855;
}

.custom-application {
  background-color: #f5f5f5;
  border-radius: 6px;
  padding: 40px;
  border: 1px dashed #e84855;
}

.custom-button {
  background-color: #f9b60a;
  border-radius: 6px;
  padding: 0.2em 1em;
  border: 1px solid #f9b60a;
  cursor: pointer;

  &:hover {
    background-color: #f9b60a80;
  }
}
</style>
