<template>
	<b-modal class="justify-content-center" ref="modal" hide-header centered size="xl">
		<b-row class="px-3">
			<b-col style="max-height: 100%; overflow-y: scroll">
				<b-container>
					<div v-if="!loading" role="tablist">

						<div v-for="(grade, gIndex) in studentsByGrade" :key="gIndex + '-grade'">
							<span style="
										width: 100%;
										background-color: #d3d3d3;
										display: block;
									">
								{{ grade.grade_name ? grade.grade_name : grade.letter }}
							</span>
							<div>
								<b-button class="m-1" v-for="(student, sIndex) in grade.students" :key="sIndex + '-student'" :variant="selectedStudents.includes(student.id)
										? 'info'
										: 'outline-info'
									" pill @click="selectStudent(student.id)" :disabled="appStudents.filter((e) => e == student.id)
												.length > 0
											">
									{{ student.student_name }}
								</b-button>
							</div>
						</div>

					</div>
					<div v-else>
						<b-row>
							<b-col class="text-center">
								<b-spinner style="width: 3rem; height: 3rem" type="grow" label="Loading..."></b-spinner>
							</b-col>
						</b-row>
					</div>
				</b-container>
			</b-col>
		</b-row>
		<template #modal-footer>
			<b-row class="w-100 align-items-start">
				<b-col class="text-right">
					<b-button variant="primary" pill @click="application ? addToApplication() : createApplication()">
						{{ application ? "Añadir" : "Crear Aplicación" }}
					</b-button>
				</b-col>
			</b-row>
		</template>
	</b-modal>
</template>
<script>
import assistedMaterialService from '../../services/assistedMaterialService';

export default {
	name: 'ApplicationModal',
	props: {
		context: {
			type: Object,
			required: true
		},
		application: {
			type: String,
			required: false
		},
		gradeCode: {
			type: Number,
			required: false
		},
		appStudents: {
			type: Array,
			required: true,
			default: () => []
		}
	},
	computed: {
		schoolId() {
			return this.$store.state.login.school;
		},
		year() {
			return this.$store.state.login.year;
		}
	},
	data() {
		return {
			studentsByGrade: [],
			selectedStudents: [],
			loading: true,
		}
	},
	mounted() {

	},
	methods: {
		open() {
			this.$refs.modal.show();
			this.getData();
		},
		hide(){
			this.$refs.modal.hide();
		},
		addToApplication() {
			const data = { students: this.selectedStudents };
			this.$emit('add-to-application', data);
		},
		selectStudent(studentId) {
			if (this.selectedStudents.includes(studentId)) {
				this.selectedStudents = this.selectedStudents.filter((id) => id !== studentId);
			} else {
				this.selectedStudents.push(studentId);
			}
		},
		createApplication() {
			const data = { students: this.selectedStudents };
			this.$emit("create-application", data);
		},
		getData() {
			assistedMaterialService.getStudentsByGrade(this.schoolId, this.year, this.gradeCode)
			.then((response) => {

				this.studentsByGrade = response;
				this.loading = false;
			})
			.catch((error) => {
				this.loading = false;
				console.log(error);
			});
		}

	},
}
</script>
<style lang="">

</style>