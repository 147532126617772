<template>
	<div class="w-100">
		<div v-if="failed " class="text-center">
			<b-alert show variant="danger">
				No se pudo generar el texto, por favor intenta de nuevo
			</b-alert>
			<!-- add a buttom to remount component -->
			<b-button @click="generateHistory" variant="danger">Reintentar</b-button>
		</div>
		<div v-if="text.content_type === 0" class="custom-editable-text">
			<div> 
				<b-form-input v-model="text.title" placeholder="Título" class="my-1" />
				<b-form-textarea rows="20" v-model="text.content" placeholder="Contenido" class="my-1" ref="textarea" />
			</div>
		</div>
		<div v-if="text.content_type === 1 && !failed">
			<b-row>
				<b-col  v-if ="loading" cols="12" class="text-center">
					<b-progress 
						:max="loadingMax" 
						animated 
						height="2rem"
						variant="danger"
					>
					<b-progress-bar
						:value="loadingValue" 
						>
							{{ Math.floor((loadingValue/loadingMax) * 100) }}%
						</b-progress-bar>
					</b-progress>
				</b-col>
			</b-row>
			<div v-if="!loading">
				<CustomAudioSlider :content="text.story_chapters" />
			</div>
			<b-row v-if="loading" class="box-question-text my-2 mx-auto">
				<b-col>
					<p>Generando historia...</p>
					<p>Mientras creamos las imágenes y audios, te mostramos el texto que hemos generado para ti.</p>
				</b-col>
			</b-row>
			<b-row v-if="!loading" cols="12" class="box-question-text my-2 mx-auto">
				<b-col>
					<p>Historia generada</p>
					<p>Revisa la historia que hemos creado para ti.</p>
				</b-col>
			</b-row>
			<b-row class="box-question-text my-2 text-center mx-auto">
				<b-col>
					<h4>{{ text.title }}</h4>
					<p class="text-justify format">{{ text.content }}</p>
				</b-col>
			</b-row>
		</div>
	</div>
</template>
<script>
import assistedMaterialService from '@/services/assistedMaterialService'
import CustomAudioSlider from "@/components/custom-materials/miscellaneous/CustomAudioSlider";

export default {
	name: 'TextMessageComponent',
	props: {
		context: {
			type: Object,
			required: true
		}
	},
	components: {
		CustomAudioSlider
	},
	data() {
		return {
			text: {
				content_type: 0,
				title: '',
				content: '',
			},
			loading: true,
			loadingMax: 45,
			loadingValue: 0,
			failed: false
		}
	},
	mounted() {
		this.$store.commit('chatbot/updateMessage', {'name': 'validate_text', 'key': 'createdText', 'value': this.text})
		const charactersOptions = this.context["characters_options"] ? this.context["characters_options"]["name"] : "";
		const plotOptions = this.context["plot_options"] ? this.context["plot_options"]["name"] : "";
		const textTopic = this.context["text_topic"]["value"]
		const nPerson = this.context["final_settings"] ? this.context["final_settings"][0]["selected"]["name"] : "Narrador omnisciente";
		const type = this.context["final_settings"] ? 3 : 4;
		const details = this.context["text_topic_details"]["textInput"]
		const age = this.context["grade"]["code"] + 4
		const textStyle = this.context["final_settings"] ? this.context["final_settings"][1]["selected"]["name"] : "";
		const textType = this.context["final_settings"] ? this.context["final_settings"][2]["selected"]["name"] : "Solo texto";
		this.text.content_type = textType === "Solo texto" ? 0 : 1;

		this.text.title = this.context["plot_options"] ? this.context["plot_options"]["title"] : "";
		const interval = setInterval(() => {
					this.loadingValue += 1;
					if (this.loadingValue === this.loadingMax) {
						clearInterval(interval);
					}
				}, 1000);
	
		const objectContext = this;
		assistedMaterialService.generateText(
			charactersOptions, 
			plotOptions, 
			textTopic, 
			nPerson, 
			type, 
			details, 
			age,
			textStyle
		).then((response) => {
			const reader = response.getReader()
			const decoder = new TextDecoder()
			// if type is 4, we need to get the title from the text
			let titleBuffer = '';
			let isTitleProcessed = type === 4 ? false : true;
				
			function processChunk({ done, value}){
				if (value) {
					let decoded = decoder.decode(value)
					let matrix = []

					if (!isTitleProcessed) {
						// Process title if not already processed
						const regex = /<([^>]*)>/;
						const found = decoded.match(regex);
						if (found) {
								const array = found[1].split(';').map((item) => {
										const stringWithoutBrackets = item.slice(1, -1);
										return stringWithoutBrackets;
								});
								for (const text of array) {
										titleBuffer += text;
								}
								// remove the title from the decoded text
								decoded = decoded.replace(found[0], '').replace(/^\n/, '');
								isTitleProcessed = true; // Set the flag to true
								objectContext.text.title = titleBuffer;
						} else if (decoded.includes('>')) {
								console.log("includes >")
								isTitleProcessed = true; // Set the flag to true if chunk contains '>'
								let array = decoded.split(';').map( (item) => {
								let stringWithoutBrackets = item.slice(1,-1)
								return stringWithoutBrackets
							}) 
							for (const text of array) {
								matrix.push(text)
							}
							
							decoded = decoded.replace(decoded.split('>')[0], '');
								
						}
						decoded = decoded.replace('>', '').replace('<', '').replace(/^\n/, '');
					}
					matrix = []
					if (decoded.includes(';')) {
						let array = decoded.split(';').map( (item) => {
							let stringWithoutBrackets = item.slice(1,-1)
							return stringWithoutBrackets
						})
						for (const text of array) {
							matrix.push(text)
						}
					} else {
						let stringWithoutBrackets = decoded.slice(1, -1)
						matrix.push(stringWithoutBrackets)
					}
					for (const text of matrix) {
						if (isTitleProcessed) {
							objectContext.text.content += text;
							if (objectContext.text.content_type === 0) {
								objectContext.$nextTick(() => {
									objectContext.$refs.textarea.$el.scrollTop = objectContext.$refs.textarea.$el.scrollHeight
								})
							}
						}
						else {
							objectContext.text.title += text
						}

					}
				}
				if (done) {
					if (objectContext.text.content_type == 1 ){
						
						assistedMaterialService.generateHistory({
							title: objectContext.text.title,
							text: objectContext.text.content,
							school: objectContext.$store.state.login.school,
						}).then((response) => {
							objectContext.text = response
							objectContext.$store.commit('chatbot/updateMessage', {'name': 'validate_text', 'key': 'createdText', 'value': response})
	
							objectContext.loading = false
							console.log("data", response)
						}).catch(error => {
							objectContext.failed = true;
							console.log(error)
						})
					}
					return
				}
				return reader.read().then(processChunk)
			}
			return reader.read().then(processChunk)
		}).catch(error => {
			objectContext.failed = true;
			console.log(error)
		})

	},
	methods: {
		generateHistory() {
			this.loading = true
			this.failed = false
			this.text = {
				content_type: 1,
				title: '',
				content: '',
			}
			this.loadingValue = 0
			this.mounted()
		}
	}
}
</script>
<style scoped lang="scss">
.custom-editable-text {
	background-color: #ffffff;
	border-radius: 15px;
	border: 1.5px solid #E84855;
	padding: 1em 1.5em;
}

.format{
	white-space: pre-wrap !important;
}

.box-question-text {
	background-color: #ffffff;
	border-radius: 15px;
	border: 1.5px solid #E84855 !important;
	padding: 1em 1.5em;
}


</style>