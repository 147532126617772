<template>
  <div>
    <b-img fluid
      :src="bottomBanner"
      @click="goToSales()"
      class="bottom-img w-100 d-sm-flex d-md-flex d-lg-none" />
    <!-- <div
      v-else
      style="margin: 0 !important;"
      @mouseleave="show = false"
    >
      <div id="divFeedbackBtn">
        <b-button class="text-white font-weight-bold opinion" pill @click="$emit('open-feedback')">
        <i>¡Danos tu opinión!</i></b-button>
      </div>
      <b-img fluid
        :src="showImg"
        height="200"
        width="200"
        class="bottom-show" />
      <b-img fluid
        @click="$emit('open-tutorial')"
        :src="firstBubble"
        height="200"
        width="200"
        class="bottom-bubble" />
    </div> -->
  </div>
</template>
<script>
export default {
  created: function() {
    if (this.$router.currentRoute.path === '/tutorial') {
      this.$emit('open-tutorial');
    }
  },
  data: function () {
    return {
      show: false,
      hideImg: require('../../../assets/img/helpButtons/Nino Utopie Asomado.svg'),
      showImg: require('../../../assets/img/helpButtons/Nino Utopie Preguntando.svg'),
      firstBubble: require('../../../assets/img/helpButtons/Globo de texto Utopie.svg'),
      bottomBanner: require('../../../assets/img/Banner_mobile_nino_redpie.svg')
    }
  },
  methods: {
    goToSales: function() {
      window.location.href = "/comprar#/";
    },
  }
}
</script>
<style lang="scss">
.bottom-img {
  position: fixed !important;
  bottom: -10px !important;
  z-index: 999 !important;
}
.bottom-show {
  position: fixed !important;
  bottom: -29px !important;
  right: 100px !important;
}
.bottom-bubble {
  position: fixed !important;
  bottom: 20px !important;
  right: 200px !important;
  cursor: pointer;
}
.opinion {
  background-color: #E84855 !important;
  border: 1px solid #E84855  !important;
}

.opinion:hover {
  background-color: #ea3241 !important;
  border: 1px solid #E84855  !important;
}
</style>
