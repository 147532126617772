<template>
  <b-modal
    centered
    size="lg"
    ref="country-modal"
    hide-footer
    hide-header
  >
    <b-container class="container">
      <div id="country" class="country">
        <div class="logo">
          <div class="image">
            <h2>topie</h2>
          </div>
        </div>
        <b-form>
          <b-col>
            <b-form-group label-for="dropdown-form-country">
              <b-form-select v-on:keyup.enter="send_country(country)" type="country" v-model="country">
                <option value="">Selecciona un país</option>
                <option value="1001">México</option>
                <option value="1002">Colombia</option>
                <option value="1003">España</option>
                <option value="1004">Argentina</option>
                <option value="1005">Venezuela</option>
                <option value="1006">Perú</option>
                <option value="1007">Chile</option>
                <option value="1008">Guatemala</option>
                <option value="1009">Ecuador</option>
                <option value="1010">Cuba</option>
                <option value="1011">Bolivia</option>
                <option value="1012">República Dominicana</option>
                <option value="1013">Honduras</option>
                <option value="1014">El Salvador</option>
                <option value="1015">Paraguay</option>
                <option value="1016">Nicaragua</option>
                <option value="1017">Costa Rica</option>
                <option value="1018">Puerto Rico</option>
                <option value="1019">Panamá</option>
                <option value="1020">Uruguay</option>
                <option value="1021">Guinea Ecuatorial</option>
              </b-form-select>
            </b-form-group>
            <b-row>
              <b-col cols="12" sm="12" class="text-center">
                <b-button
                  variant="primary"
                  @click="send_country(country)"
                  right
                  :disabled="!country"
                >Aceptar</b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-form>
      </div>
    </b-container>
  </b-modal>
</template>
<script>
import userService from "../../../services/userService";
export default {
  data: function() {
    return {
      country: "",
    };
  },
  methods: {
    open() {
      this.$refs["country-modal"].show();
    },
    send_country: function(country) {
      if (country){
        const payload = {country: country};
        if (this.$store.state.login.token) {
          userService.set_country(payload).then(
            data => {
              this.$store.commit('login/setCountry', data);
              this.$emit('get-posts');
            }
          );
        }
        else {
          this.$store.commit('login/setCountry', payload);
        }
        this.$refs['country-modal'].hide()
      }
      else {
        this.$tasted.error("Debes seleccionar un país para continuar");
      }
    },
  },
}
</script>

<style scoped lang="scss">
#country {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.country {
  border: 1px solid black;
  border-radius: 5px;
  padding: 1.5rem;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  .container-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    img {
      width: 2rem;
      height: 2rem;
    }
  }
  b-form {
    display: flex;
    flex-flow: column;
    *:not(:last-child) {
      margin-bottom: 1rem;
    }
    input {
      padding: 0.5rem;
    }
    button {
      padding: 0.5rem;
      background-color: lightgray;
      border: 1px solid gray;
      border-radius: 3px;
      cursor: pointer;
      &:hover {
        background-color: lightslategray;
      }
    }
  }
}
.container {
  .logo {
    width: 100%;
    height: $headerHeight;
    .image {
      justify-content: center;
      width: 100%;
      height: 100%;
      cursor: pointer;
      background: url(../../../assets/img/logo.svg) no-repeat right top;
    }
  }
}
</style>
