<template>
  <div>
    <ModalEmbed
      @change="onChange"
      ref="modal-embed"
      @watch-next="$emit('watch-next')"
      @watch-previous="$emit('watch-previous')"
      v-on:points-change="$emit('points-change')"
    />
    <b-card
      no-body
      img-top
      style="max-width: 20rem;"
      footer-tag="footer"
      footer-bg-variant="primary"
    >
      <b-card-img :src="'https://drive.google.com/thumbnail?id='+file.id_google_drive" />
      <b-card-body
        overlay
        class="cursor-pointer"
        v-on:click="$refs['modal-embed'].open({
        index: index,
        openUrl: file.view_link,
        title: file.short_name,
        stars: file.rate,
        fileid: file.id,
        subtags: file.subtags
      })"
      >
        <b-card-text>
          <div class="text-right">
            <b-badge variant="light">
              <v-icon name="star" class="icon" />
              {{file.rate}} / 5
            </b-badge>
          </div>
        </b-card-text>
      </b-card-body>
      <template v-slot:footer>
        <b-row>
          <b-col cols="9">
            {{file.post_text}}
            <br />
            <b-badge
              pill
              v-for="(subtag, index) in file.subtags"
              :key="'subtag-'+index"
              size="sm"
              href="#"
              variant="secondary"
              @click="$emit('addSubtag', subtag.text)"
            ><div class="text-light">{{subtag.text}}</div></b-badge>
          </b-col>
          <b-col cols="3" class="verticalLine text-center">
            <v-icon name="share" class="cursor-pointer" :id="file.id+'share'" />
          </b-col>
        </b-row>
      </template>
    </b-card>
    <b-popover triggers="hover" ref="popover" :target="file.id+'share'">
      <template v-slot:title>
        <b-button variant="outline" @click="$refs.popover.$emit('close')" class="close" aria-label="Close">
          <span class="d-inline-block" aria-hidden="true">&times;</span>
        </b-button>
        Compartir
      </template>
      <!-- <twitter-button :url="file.view_link" :description="file.short_name" />
      <facebook-button :url="file.view_link" :description="file.short_name" /> -->
    </b-popover>
  </div>
</template>
<script>
import ModalEmbed from "../modals/ModalEmbed";
// import FacebookButton from "vue-share-buttons/src/components/FacebookButton";
// import TwitterButton from "vue-share-buttons/src/components/TwitterButton";

export default {
  components: {
    ModalEmbed,
    // FacebookButton,
    // TwitterButton
  },
  props: ["file", "index", "watchIndex"],
  methods:{
    onChange: function(index, rate) {
      this.$emit("change", index, rate)
    }
  },
  watch: {
    watchIndex() {
      if (this.watchIndex===this.index) {
        this.$refs['modal-embed'].open({
          index: this.index,
          openUrl: this.file.view_link,
          title: this.file.short_name,
          stars: this.file.rate,
          fileid: this.file.id,
          subtags: this.file.subtags
        })
      }
      else {
        this.$refs['modal-embed'].close()
      }
    }
  }
};

</script>

<style lang="scss">
.cursor-pointer {
  cursor: pointer;
}
.verticalLine {
  border-left: 1px solid white;
}
.icon {
  color: $primary;
}
</style>
