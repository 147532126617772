<template>
	<b-overlay :show="loading" rounded="sm">
		<div class="publish radius-box-8 padding-box-16">
			<div class="left">
				<div class="input">
					<b-form-textarea
						class="input-form"
						v-model="text"
						@click="hasClassify = true"
						placeholder="Escribe tu post aquí..."
					/>
				</div>

				<div class="classify">
					<hr style="margin-top: 5px; margin-bottom: 5px;"/>
					<b-container>
						<b-row>
							<div
								v-for="(option, index) in customTags"
								v-bind:key="index"
							>
								<div v-for="(suboption, index2) in option.options" v-bind:key="index2">
									<b-badge
										v-if="suboption.checked"
										pill
										style="cursor: pointer"
										variant="primary"
										@click="suboption.checked = false"
									>
										{{ suboption.title }}
										<v-icon name="times" />
									</b-badge>
								</div>
							</div>
						</b-row>
						<b-row class="classify-title" style="font-size: 19px !important;">
							<b-col cols="5" xl="3" lg="3" md="4" class="pr-0 pl-0">
								<p style="cursor: pointer;" @click="hasClassify = !hasClassify">Clasifica tu post <v-icon :name="hasClassify ? 'chevron-up' : 'chevron-down'" /></p>
							</b-col>
							<b-col cols="9" class="text-left reward d-xl-block d-lg-block d-md-block d-none">
								<p class="pr-3">Ofrece recompensa:</p>
								<b-img :src="appleIcon" width="30px" class="pr-2"/>
								<div class="reward-value">
									<b-input-group size="sm">
										<!-- <b-input-group-prepend >
											<b-button class="left-button pt-0" size="sm" variant="primary" @click="reduceReward">
												<strong style="font-size: 15px !important;">-</strong>
											</b-button>
										</b-input-group-prepend> -->
										<b-form-input
											lazy-formatter
											size="sm"
											class="font-weight-bold"
											style="font-size: 17px !important; height: 25px !important;"
											:formatter="formatter"
											v-model="reward"></b-form-input>
										<!-- <b-input-group-append>
											<b-button class="right-button pt-0" size="sm" variant="primary" @click="increaseReward">
												<strong style="font-size: 15px !important;">+</strong>
											</b-button>
										</b-input-group-append> -->
									</b-input-group>
								</div>
							</b-col>
							<b-col class="d-inline d-xl-none d-lg-none d-md-none text-right">
								<div class="button">
									<b-button
										variant="primary"
										class="radius-box-24 padding-box-16-lr border-none bold"
										@click="publishPost"
									>Publicar</b-button>
								</div>
							</b-col>
						</b-row>

						<b-row v-if="hasClassify" class="classify-options">
							<b-col cols="5">
								<ul class="classify-main-options">
									<li
										v-for="(option, index) in customTags"
										v-bind:key="index"
										class="text-primary bg-ultralight"
										v-bind:class="{ active: selectedClassify === option }"
										@click="selectedClassify = option"
									>
										<!--+{{ option.points }}--> <v-icon name="apple-alt" />
										<span class="text-dark">
											{{ option.title }} <strong>({{ option.options.filter(m => m.checked).length }})</strong>
											<v-icon class="icon-right" name="caret-right" />
										</span>
									</li>
								</ul>
							</b-col>
							<b-col cols="7" class="classify-sub-options">
								<div v-if="selectedClassify">
									<b-form-checkbox
										v-for="(option, index) in selectedClassify.options"
										v-bind:key="index"
										v-model="option.checked"
										:value="option.id"
										:unchecked-value="null"
									>{{ option.title }}</b-form-checkbox>
								</div>
							</b-col>
						</b-row>
					</b-container>
				</div>

				<div v-if="hasPoll" class="poll-container">
					<hr style="margin-top: 1px; margin-bottom: 1px;"/>

					<b-collapse v-model="hasPoll">
						<b-card no-body class="mt-1">
							<b-card-body>
								<v-icon name="times-circle" class="close-poll" @click.native="hasPoll = false" />

								<b-form @submit.stop.prevent>
									<label for="text-password">Encuesta</label>

									<b-input type="text" v-model="poll.question" :state="Boolean(poll.question.length)" placeholder="Ej: ¿Cuál es tu color favorito?"></b-input>

									<b-form-text>
										<code>La encuesta debe tener por lo menos 2 alternativas</code>
									</b-form-text>

									<b-input-group class="mb-1" v-for="(option, index) in poll.options" v-bind:key="index">
										<b-form-input :state="Boolean(option.value.length)" :placeholder="'Ej: Opción ' + (index + 1)" v-model="option.value"></b-form-input>
										<b-input-group-append>
											<b-button variant="outline-danger" @click="poll.options.splice(index, 1)">Eliminar</b-button>
										</b-input-group-append>
									</b-input-group>
									<b-button variant="outline-info" class="w-100" @click="poll.options.push({ value: '' })">Agregar opción</b-button>
								</b-form>
							</b-card-body>
						</b-card>
					</b-collapse>
				</div>

				<div v-if="this.files && this.files.length" class="docs-data">
					<hr />

					<ul>
						<li v-for="(file, index) in this.files" v-bind:key="index">
							<v-icon name="c-doc" class="text-primary" />
							{{ file.name }}
							<v-icon name="window-close" class="delete-file" @click.native="files.splice(index, 1)" />
						</li>
					</ul>
				</div>

				<div class="end d-none d-xl-flex d-lg-flex d-md-flex">
					<hr />
					<!--<div class="tags">
						<multiselect v-model="value" :options="options" :taggable="true" :multiple="true" :custom-label="customLabel" placeholder="Añadir más etiquetas" select-label="Seleccionar" deselectLabel="Presiona INTRO para remover" tagPlaceholder="Presiona INTRO para añadir" label="name" track-by="code" @tag="addTag">
							<span slot="noOptions">Escribe la etiqueta y presiona INTRO</span>
							<span slot="noResult">Escribe la etiqueta y presiona INTRO</span>
						</multiselect>
					</div>-->
					<div class="button">
						<b-button
							variant="primary"
							class="radius-box-24 padding-box-16-lr border-none bold"
							@click="publishPost"
						>Publicar</b-button>
					</div>
				</div>
			</div>
			<div class="right">
				<div class="options">
					<b-form-file v-model="files" ref="file" style="display:none;" accept=".docx, .doc, .xlsx, .xls, .pdf, .ppt, .pptx, .png, .jpg, .jpeg" multiple></b-form-file>
					<v-icon name="c-attach" scale="1.75" class="icon" v-bind:class="{ active: files.length }" title="Adjuntar archivos (Recuerda no adjuntar material protegido por propiedad intelectual)" @click.native="$refs.file.$el.childNodes[0].click()" />
					<v-icon name="c-poll" scale="1.75" class="icon" v-bind:class="{ active: hasPoll }" :title="hasPoll ? 'Quitar encuesta' : 'Agregar encuesta'" @click.native="hasPoll = !hasPoll" />
				</div>
			</div>
		</div>
	</b-overlay>
</template>

<script>
	export default {
		components: {
		},
		props: {
			tags: { type: Array, default: () => [] }
		},
		data() {
			return {
				appleIcon: require('../../../assets/img/icono-manzana.svg'),
				customTags: [],
				text: '',
				value: [],
				files: [],
				hasPoll: false,
				hasClassify: false,
				poll: {
					question: '',
					options: [ { value: '' }, { value: '' } ],
				},
				reward: 0,
				selectedClassify: null,
				loading: false,
			}
		},
		watch: {
			tags() {
				for (var i = 0; i < this.tags.length; i++){
					let tag = {};
					tag.title = this.tags[i].text;
					tag.options = [];
					for (var j = 0; j < this.tags[i].subtags.length; j++) {
						let subtag = {};
						subtag.id = this.tags[i].subtags[j].id;
						subtag.title = this.tags[i].subtags[j].text;
						subtag.checked = false;
						tag.options.push(subtag)
					}
					this.customTags.push(tag)
				}
			}
		},
		created(){
			this.$emit('loadingFunc', this.changeLoading)
		},
		methods: {
			changeLoading() {
				this.loading = !this.loading;
			},
			addTag(newTag) {
				newTag = newTag.replace(/#/g, '')

				if (!newTag.length) {
					return
				}

				const tag = { name: newTag, code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)) }

				this.options.push(tag)
				this.value.push(tag)
			},
			customLabel ({ name }) {
				return `#${name}`
			},
			formatter: function(value) {
				if (value<=this.$store.state.login.points && value>= 0) {
					return value
				}
				else return 0;
			},
			reduceReward: function() {
				this.reward = Math.max(this.reward - 1, 0)
			},
			increaseReward: function() {
				this.reward = Math.min(this.reward + 1, this.$store.state.login.points)
			},
			resetValues: function() {
				this.text = '';
				this.value = [];
				this.options = [];
				this.files = [];
				this.hasPoll = false;
				this.hasClassify = false;
				this.poll = {
					question: '',
					options: [ { value: '' }, { value: '' } ],
				};
				this.reward = 0;
				this.selectedClassify = null;
				for (var i = 0; i < this.customTags.length; i++) {
					for (var j = 0; j < this.customTags[i].options.length; j++) {
						this.customTags[i].options[j].checked = false;
					}
				}
			},
			publishPost: function() {
				let subtagArr = []
				for (var i = 0; i < this.customTags.length; i++) {
					for (var j = 0; j < this.customTags[i].options.length; j++) {
						if (this.customTags[i].options[j].checked) {
							subtagArr.push(this.customTags[i].options[j]);
						}
					}
				}
				this.$emit("publish-post", {
					text: this.text,
					files: this.files,
					has_poll: this.hasPoll,
					question: this.poll.question,
					options: this.poll.options,
					subtags: subtagArr,
					reward: this.reward
				});
				this.resetValues()
			}
		}
	}
</script>

<style lang="scss">

	.custom-control-input:checked ~ .custom-control-label::before {
		border-color: $primary !important;
		background-color: $primary !important;
	}
	.custom-control-input:checked ~ .custom-control-label {
		font-weight: bold !important;
	}

	.publish {
		width: 100%;
		background-color: white;
		display: flex;
		justify-content: space-between;

		.left {
			width: calc(100% - 32px);

			.input {
				width: 100%;

				.input-form {
					height: 84px !important;
				}
			}

			.poll-container {
				position: relative;

				.close-poll {
					cursor: pointer;
					position: absolute;
					right: 6px;
					top: 6px;
				}
			}

			.classify {
				.classify-title {
					display: flex;
					justify-content: space-between;
					align-items: center;

					.reward {
						display: flex;
						justify-content: flex-start;
						align-items: center;
					}

					p {
						font-weight: bold;
						font-size: .8em;
						display: inline-block;
						padding: 0;
						margin: 0;
					}
					.reward-value {
						display: inline-block;
						padding: 0 !important;
						margin: 0 !important;

						input {
							width: 42px;
							padding: 0;
							text-align: center;
						}
						.right-button {
							border-top-right-radius: 12px !important;
							border-bottom-right-radius: 12px !important;
							height: 25px !important;
						}
						.left-button {
							border-top-left-radius: 12px !important;
							border-bottom-left-radius: 12px !important;
							height: 25px !important;
						}
					}
				}

				.classify-options {
					.classify-main-options {
						list-style: none;
						margin: 0;
						padding: 0;
						font-size: 11px;

						li {
							padding: 3px 6px;
							position: relative;
							cursor: pointer;

							span {
								font-size: 1.2em;
							}

							.icon-right {
								position: absolute;
								right: 6px;
							}
						}
						li:hover, li.active {
							background-color: white !important;
							font-weight: bold;
						}
					}

					.classify-sub-options {
						font-size: .9em;
					}
				}
			}

			.docs-data {
				width: 100%;
				padding: 0 24px 12px 24px;

				ul {
					list-style: none;
					margin: 0;
					padding: 0;

					li {
						transition: all .256s;
						background-color: white;
						padding: 6px 6px 6px 12px;
						font-size: 12px;
						font-weight: bold;
						border-left: solid 1px #000;
						cursor: pointer;
						position: relative;

						.delete-file {
							position: absolute;
							right: 24px;
							color: #666;
						}
						.delete-file:hover {
							color: red;
						}
					}
					li:hover {
						transition: all .256s;
						background-color: #efefef;
					}
				}
			}

			.end {
				margin-top: 6px;
				width: 100%;
				display: flex;
				justify-content: space-between;

				.tags {
					width: calc(100% - 144px);
				}
				.button {
					width: 144px;
					padding-left: 12px;
					margin-top: 3px;

					button {
						width: 100%;
					}
				}
			}
		}

		.right {
			width: 32px;

			.options {
				width: 32px;
				text-align: center;

				.icon {
					color: $light !important;
					cursor: pointer;
					transition:all .256s;
				}
				.icon:hover, .icon.active {
					color: $secondary !important;
					transition:all .256s;
				}
			}
		}
	}
</style>
