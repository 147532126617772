<template>
  <div class="list-info radius-box-8 padding-box-16">
    <div class="title">
      <h2 class="text-center" v-html=this.title />
    </div>
    <div class="options">
      <div class="item" v-for="(option, index) in this.data.options" :key="'item-'+index">
        <div class="button">
          <b-button
            :variant="option.colorDescription"
            class="radius-box-24 bold"
            @click="dispatch(option.onclick, option.test)"
          >{{ option.description }}</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: null },
    data: { type: Object, default: () => Object({ options: [] }) }
  },
  methods: {
    dispatch: function(f, arg) {
      this.$emit("apply", f, arg);
    }
  }
};
</script>

<style lang="scss">
.list-info {
  width: 100%;
  background-color: white;

  .title {
    width: 100%;

    h2 {
      margin: 0;
      padding: 0;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .options {
    padding: 12px 0;

    .item {
      width: 100%;
      padding: 3px 0;
      display: flex;
      justify-content: space-between;

      .button {
        width: 100%;

        button {
          width: 100%;
        }
      }

      .badge {
        width: 86px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
}
</style>
