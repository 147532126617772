import { render, staticRenderFns } from "./NewPlanification.vue?vue&type=template&id=94a9fa96"
import script from "./NewPlanification.vue?vue&type=script&lang=js"
export * from "./NewPlanification.vue?vue&type=script&lang=js"
import style0 from "./NewPlanification.vue?vue&type=style&index=0&id=94a9fa96&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports