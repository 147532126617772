<template>
  <b-container>
    <b-row>
      <b-container>
        <b-row>
          <h3>Agregar Usuario</h3>
        </b-row>
        <b-row>
          <b-col cols="3">
            Correo:*
          </b-col>
          <b-col>
            <b-form-input v-model="newUser.email" placeholder="Correo..." type="email"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="3">
            Contraseña:*
          </b-col>
          <b-col>
            <b-form-input v-model="newUser.password" placeholder="Clave..." type="password"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="3">
            Repetir contraseña:*
          </b-col>
          <b-col>
            <b-form-input v-model="newUser.re_password" placeholder="Repetir..." type="password"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="3">
            Primer nombre:
          </b-col>
          <b-col>
            <b-form-input v-model="newUser.first_name" placeholder="Primer nombre..." type="text"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="3">
            Segundo nombre:
          </b-col>
          <b-col>
            <b-form-input v-model="newUser.middle_name" placeholder="Segundo nombre..." type="text"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="3">
            Apellido paterno:
          </b-col>
          <b-col>
            <b-form-input v-model="newUser.last_name" placeholder="Apellido paterno..." type="text"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="3">
            Apellido materno:
          </b-col>
          <b-col>
            <b-form-input v-model="newUser.mothers_family_name" placeholder="Apellido materno..." type="text"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="3">
            Rut:
          </b-col>
          <b-col>
            <b-form-input v-model="newUser.rut" lazy-formatter
              :formatter="formatter" placeholder="Rut..." type="text"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="3">
            Teléfono:
          </b-col>
          <b-col>
            <b-form-input v-model="newUser.phone" placeholder="Teléfono..." type="text"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="3">
            Profesión:
          </b-col>
          <b-col>
            <b-form-input v-model="newUser.specialty" placeholder="Profesión..." type="text"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="3">
            Secreduc:
          </b-col>
          <b-col>
            <b-form-input v-model="newUser.secreduc" placeholder="Secreduc..." type="text"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="3">
            Rol:
          </b-col>
          <b-col>
            <b-form-select v-model="newUser.role" class="mb-3">
              <b-form-select-option
                v-for="(role, index) in roles"
                :key="index+'-role'"
                :value="role[0]">{{role[1]}}</b-form-select-option>
            </b-form-select>
          </b-col>
        </b-row>
        <b-row>
          <b-button @click="addUser">Agregar</b-button>
        </b-row>
      </b-container>
    </b-row>
    <b-row>
      <b-container>
        <b-row>
          <h3>Cambiar contraseña usuario existente</h3>
        </b-row>
        <b-row>
          <b-col cols="3">
            Correo:
          </b-col>
          <b-col>
            <multiselect
              v-model="existingUser.email"
              label="email"
              track-by="email"
              :options="users"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="3">
            Contraseña:
          </b-col>
          <b-col>
            <b-form-input v-model="existingUser.password" placeholder="Clave..." type="password"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="3">
            Repetir contraseña:
          </b-col>
          <b-col>
            <b-form-input v-model="existingUser.re_password" placeholder="Repetir..." type="password"/>
          </b-col>
        </b-row>
        <b-row>
          <b-button @click="changePassword">Cambiar</b-button>
        </b-row>
      </b-container>
    </b-row>
  </b-container>
</template>

<script>
import informationService from "../../../services/informationService";
import authService from "../../../services/authService";

export default {
  created() {
    this.getUsersAndRoles();
  },
  data() {
    return {
      newUser: {
        email: "",
        password: "",
        re_password: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        mothers_family_name: "",
        rut: "",
        phone: "",
        secreduc: "",
        specialty: "",
        role: 0,
      },
      existingUser: {
        email: {},
        password: "",
        re_password: "",
      },
      users: [],
      roles: []
    };
  },
  methods: {
    getUsersAndRoles: function() {
      this.getUsers();
      this.getRoles();
    },
    clearNewUser: function() {
      this.newUser = {
        email: "",
        password: "",
        re_password: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        mothers_family_name: "",
        rut: "",
        phone: "",
        secreduc: "",
        specialty: "",
        role: 0,
      };
    },
    clearExistingUser: function() {
      this.existingUser = {
        email: {},
        password: "",
        re_password: "",
      };
    },
    getUsers: function() {
      informationService.getUsers().then(
        data => {
          this.users = data.users;
        }
      )
    },
    getRoles: function() {
      informationService.getRoles().then(
        data => {
          this.roles = Object.entries(data.roles);
        }
      )
    },
    clean: function (rut) {
      return typeof rut === 'string'
        ? rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase()
        : ''
    },
    validate: function (rut) {
      if (typeof rut !== 'string') {
        return false
      }
      if (!/^0*(\d{1,3}(\.?\d{3})*)-?([\dkK])$/.test(rut)) {
        return false
      }

      rut = this.clean(rut)

      var t = parseInt(rut.slice(0, -1), 10)
      var m = 0
      var s = 1

      while (t > 0) {
        s = (s + (t % 10) * (9 - m++ % 6)) % 11
        t = Math.floor(t / 10)
      }

      var v = s > 0 ? '' + (s - 1) : 'K'
      return v === rut.slice(-1)
    },
    formatter: function (rut) {
      rut = this.clean(rut)
      var result = rut.slice(-4, -1) + '-' + rut.substr(rut.length - 1)
      for (var i = 4; i < rut.length; i += 3) {
        result = rut.slice(-3 - i, -i) + '.' + result
      }
      if (!this.validate(rut)) {
        this.$toasted.error("Rut Invalido");
        return ''
      }
      return result
    },
    addUser: function() {
      authService.adminAddUser(this.newUser).then(
        data => {
          this.$toasted.success(data.message);
          this.clearNewUser();
        },
        error => {
          var message = "Ha ocurrido un error.";
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            message = error.response.data.message;
          }
          this.$toasted.error(message);
        }
      )
    },
    changePassword: function() {
      authService.adminChangePassword(this.existingUser).then(
        data => {
          this.$toasted.success(data.message);
          this.clearExistingUser();
        },
        error => {
          var message = "Ha ocurrido un error.";
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            message = error.response.data.message;
          }
          this.$toasted.error(message);
        }
      )
    },
  }
};
</script>

<style lang="scss">
</style>
