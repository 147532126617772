<template>
	<div class="tags-and-subtags radius-box-8 padding-box-16">
		<h2 class="title">Marca tus preferencias</h2>

    <div class="classify-options">
      <ul class="classify-main-options" v-if="tags.length > 0">
        <li v-for="(option, index) in tags" v-bind:key="index" class="text-primary bg-ultralight" v-bind:class="{ active: selectedClassify === option }" @click="selectedClassify = selectedClassify === option ? null : option">
          <span class="text-dark">{{ option.title }} <strong>({{ option.options.filter(m => !m.exclude).length }} activos de {{option.options.length}})</strong></span>
          <v-icon class="icon-right text-dark" :name="selectedClassify === option ? 'caret-down' : 'caret-right'" />
        </li>
      </ul>
			<strong v-else><a href="#" @click="$emit('open-login')">Inicia sesión</a> para filtrar!<br/>Es gratis</strong>
      <div v-if="selectedClassify" class="classify-sub-options">
        <b-form-checkbox v-for="(option, index) in selectedClassify.options" v-bind:key="index" v-model="value" :value="option" @change="changeSubtagValue(index)">{{ option.title }}</b-form-checkbox>
      </div>
    </div>
	</div>
</template>

<script>
import postService from "../../../services/postService";

export default {
	props: {
		data: { type: Array, default: () => [] }
	},
	data() {
		return {
      selectedClassify: null,
			tags: [],
			value: [],
		}
	},
	created() {
		this.get_tags()
	},
	computed: {
		country: function () {
			return this.$store.state.login.country
		}
	},
	watch: {
		country: function () {
			this.get_tags();
		},
	},
	methods: {
		get_posts: function() {
			this.$emit("get-posts");
		},
		get_files: function() {
			this.$emit("get-files");
		},
		get_tags: function() {
			this.tags = [];
			postService.get_tags().then(
				data => {
					for (var i = 0; i < data.tags.length; i++) {
						let tag = {}
						tag.title = data.tags[i].text;
						tag.options = [];
						for (var j = 0; j < data.tags[i].subtags.length; j++) {
							if (data.tags[i].subtags[j].text != "Publicidad"){
								let subtag = {};
								subtag.id = data.tags[i].subtags[j].id;
								subtag.title = data.tags[i].subtags[j].text;
								subtag.exclude = data.tags[i].subtags[j].exclude;
								tag.options.push(subtag)
							}
						}
						if (!this.tags.some(tg => tg.title === tag.title)) {
							this.tags.push(tag);
						}
					}
					this.set_excluded_subtags()
				},
				error => {
					console.error(error);
				}
			);
		},
		changeSubtagValue: function(subindex) {
      if (this.$store.state.login.token) {
        this.selectedClassify.options[subindex].exclude = !this.selectedClassify.options[subindex].exclude;
        // send ajax to save the preference
        postService
          .change_subtag_filter({
            text: this.selectedClassify.options[subindex].title,
            exclude: this.selectedClassify.options[subindex].exclude,
            type: "subtag"
          })
          .then(
            data => {
              if (this.$router.currentRoute.name==="media"){
                this.get_files()
              }
              else {
                this.get_posts();
              }
              this.set_excluded_subtags();
              this.$toasted.success(data.message);
            },
            error => {
              this.$toasted.error("Ha ocurrido un error al guardar la opción.");
              console.error(error);
            }
          );
      }
      else {
        this.$toasted.error("Debes estar inscrito en la plataforma para cambiar tus preferencias");
      }
    },
		set_excluded_subtags: function() {
      this.value = []
      this.tags.forEach(tag => {
        tag.options.forEach(subtag => {
          if (!subtag.exclude) {
            this.value.push(subtag)
          }
        })
      })
    },
	}
}
</script>

<style lang="scss">
.tags-and-subtags {
	width: 100%;
	background-color: white;

	.title {
		padding: 0;
		margin-bottom: 12px;
		font-size: 14px;
		font-weight: bold;
  }

  .classify-options {
    .classify-main-options {
      list-style: none;
      margin: 0;
      padding: 0;
      font-size: .8em;

      li {
        padding: 5px 12px;
        position: relative;
        cursor: pointer;

        span {
          font-size: 1.02em;
        }

        .icon-right {
          position: absolute;
          right: 6px;
        }
      }
      li:hover, li.active {
        background-color: white !important;
        font-weight: bold;

        .icon-right {
          color: $primary !important;
        }
      }
    }

    .classify-sub-options {
      font-size: .8em;
      margin-top: 12px;
    }
  }
}
</style>
