<template>
  <b-container fluid>
    <router-view ref="main" />
  </b-container>
</template>

<script>
import authService from "../../services/authService";
import { mapState } from "vuex";
export default {
  components: {},
  computed: mapState(["token"]),
  created() {
    const vue_scope = this;
    if (this.$store.state.login.token) {
      this.verify_token();
      if(!window.webpackHotUpdate){
        this.$connect();
      }
    }
    this.$options.sockets.onmessage = data =>
      vue_scope.$toasted.success(JSON.parse(data.data).message);
  },
  data: function() {
    return {};
  },
  methods: {
    verify_token: function() {
      authService.verify_token().then(
        // eslint-disable-next-line
        data => {
          // console.log("verifying token...");
        },
        // eslint-disable-next-line
        error => {
          this.$toasted.error("Tu sesión ha expirado.");
          this.$store.commit("login/setUserEmpty");
        }
      );
    }
  },
  watch: {
    // eslint-disable-next-line
    $route(to, from) {
      if (this.$store.state.login.token) {
        this.verify_token();
      }
    }
  }
};
</script>