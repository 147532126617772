import { render, staticRenderFns } from "./ChatMessageComponent.vue?vue&type=template&id=7580386d&scoped=true"
import script from "./ChatMessageComponent.vue?vue&type=script&lang=js"
export * from "./ChatMessageComponent.vue?vue&type=script&lang=js"
import style0 from "./ChatMessageComponent.vue?vue&type=style&index=0&id=7580386d&prod&scoped=true&lang=scss"
import style1 from "./ChatMessageComponent.vue?vue&type=style&index=1&id=7580386d&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7580386d",
  null
  
)

export default component.exports