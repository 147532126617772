<template>
  <b-card no-body>
    <b-tabs card>
      <b-tab title="Estudiante y Establecimiento" active>
        <b-card-text>
          <h3>Antecedentes de Identificación del Estudiante</h3>
          <b-row>
            <b-col md="6">
              <b-form-group label="Nombre:" label-cols-sm="4" label-cols-lg="3">
                <b-form-text>Felipe</b-form-text>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Nacionalidad:" label-cols-sm="4" label-cols-lg="3">
                <b-form-text>Chileno</b-form-text>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Run" label-cols-sm="4" label-cols-lg="3">
                <b-form-text>xxxxxxxxxxxx-x</b-form-text>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Dirección del Estudiante:" label-cols-sm="4" label-cols-lg="3">
                <b-form-text>xxxxxxx #1234</b-form-text>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Sexo:" label-cols-sm="4" label-cols-lg="3">
                <b-form-text>M</b-form-text>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Región:" label-cols-sm="4" label-cols-lg="3">
                <b-form-text>Región Metropolitana</b-form-text>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Comuna:" label-cols-sm="4" label-cols-lg="3">
                <b-form-text>Ñunoa</b-form-text>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Edad:" label-cols-sm="4" label-cols-lg="3">
                <b-form-text>16 años y 3 meses</b-form-text>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <b-form-group
                label="Curso de Ingreso al Establecimiento:"
                label-cols-sm="4"
                label-cols-lg="3"
              >
                <b-form-select>
                  <option value>---------</option>
                  <option value="xxxx">opcion 1</option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group label="Curso actual:" label-cols-sm="4" label-cols-lg="3">
                <b-form-text>1º básico</b-form-text>
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group label="Estudiante Prioritario:" label-cols-sm="4" label-cols-lg="3">
                <b-form-radio-group>
                  <b-form-radio value="si">Sí</b-form-radio>
                  <b-form-radio value="no">No</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group label="Estudiante Preferente:" label-cols-sm="4" label-cols-lg="3">
                <b-form-radio-group>
                  <b-form-radio value="si">Sí</b-form-radio>
                  <b-form-radio value="no">No</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group label="Beneficiario Junaeb*:" label-cols-sm="4" label-cols-lg="3">
                <b-form-radio-group>
                  <b-form-radio value="si">Sí</b-form-radio>
                  <b-form-radio value="no">No</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Participación Anterior en PIE*:"
                label-cols-sm="4"
                label-cols-lg="3"
              >
                <b-form-radio-group>
                  <b-form-radio value="si">Sí</b-form-radio>
                  <b-form-radio value="no">No</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group label="Número de Años:" label-cols-sm="4" label-cols-lg="3">
                <b-form-input></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Participación Anterior en Escuela Especial*:"
                label-cols-sm="4"
                label-cols-lg="3"
              >
                <b-form-radio-group>
                  <b-form-radio value="si">Sí</b-form-radio>
                  <b-form-radio value="no">No</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group label="Número de Años:" label-cols-sm="4" label-cols-lg="3">
                <b-form-select>
                  <option value="0">Seleccionar</option>
                  <option value="1800311508698137783">Escuela de Discapacidad Intelectual</option>
                  <option value="1800311508698137784">Escuela de Sordos</option>
                  <option value="1800311508698137785">Escuela de Ciegos</option>
                  <option value="1800311508698137786">Escuela de Autismo</option>
                  <option value="1800311508698137787">Escuela de Discapacidad Múltiple</option>
                  <option value="1800311508698137788">Escuela de Lenguaje</option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="¿Su lengua habitual es el Español?*:"
                label-cols-sm="4"
                label-cols-lg="3"
              >
                <b-form-radio-group>
                  <b-form-radio value="si">Sí</b-form-radio>
                  <b-form-radio value="no">No</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-text>
      </b-tab>
      <b-tab title="Diagnóstico y Equipo Evaluador">
        <b-card-text>Tab contents 2</b-card-text>
      </b-tab>
      <b-tab title="Antecedentes Evaluación Integral">
        <b-card-text>Tab contents 2</b-card-text>
      </b-tab>
      <b-tab title="Identificación de NEE">
        <b-card-text>Tab contents 2</b-card-text>
      </b-tab>
      <b-tab title="Cierre">
        <b-card-text>Tab contents 2</b-card-text>
      </b-tab>
      <b-tab title="Asignar Estudiante a comunidad escolar" v-if="true">
        <b-card-text>Tab contents 2</b-card-text>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import authService from "../../services/authService";

export default {
  created() {
    if (!this.$store.state.login.token) {
      this.$router.push({ name: "home" });
    }
    if (this.$store.state.login.token) {
      this.verify_token();
      if(!window.webpackHotUpdate){
        this.$connect();
      }
    }
    this.$options.sockets.onmessage = this.on_socket_message;
  },
  methods: {
    verify_token: function() {
      authService.verify_token().then(
        // eslint-disable-next-line
        data => {
          // console.log("verifying token...");
        },
        // eslint-disable-next-line
        error => {
          this.$toasted.error("Tu sesión ha expirado.");
          this.$store.commit("login/setUserEmpty");
        }
      );
    },
    on_socket_message: function(data) {
      this.$toasted.success(JSON.parse(data.data).message);
    }
  }
};
</script>

<style>
.bd-sidebar {
  border-right: 1px solid #eee;
}
.bd-toc {
  border-left: 1px solid #eee;
}
.no-hover-info {
  border-color: #17a2b8;
  color: #17a2b8;
}
.no-hover-info:hover {
  border-color: #17a2b8;
  color: #17a2b8;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-disabled {
  cursor: not-allowed;
}
.no-login {
  border-radius: 0.3rem;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(23, 162, 184);
  text-align: center;
}
.no-login-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.border-green {
  border: 1px solid rgba(23, 162, 184, 0.33);
}
</style>