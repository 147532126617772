<template>
  <b-container class="container">
    <div id="login" class="login">
      <div class="logo">
        <div class="image">
          <h2>topie</h2>
        </div>
      </div>
      <b-form @submit="authenticate_password({email: email, password: password})">
        <b-col>
          <b-form-group label-for="dropdown-form-email">
            <b-form-input v-on:keyup.enter="login({email: email, password: password})" type="email" placeholder="Dirección de correo electrónico" v-model="email"></b-form-input>
          </b-form-group>
          <b-form-group label-for="dropdown-form-password">
            <b-form-input v-on:keyup.enter="login({email: email, password: password})" type="password" placeholder="Contraseña" v-model="password" />
          </b-form-group>
          <b-row>
            <b-col cols="12" sm="12" class="text-center">
              <b-button
                variant="primary"
                @click="login({email: email, password: password})"
                v-if="!$store.state.login.token"
                right
              >Iniciar Sesión</b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-form>
    </div>
  </b-container>
</template>
<script>
import { authMixin } from "../../../mixins/authMixin";
export default {
  mixins: [authMixin],
  data: function() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    login: function(payload) {
      this.authenticate_password(payload, this);
    },
  },
}
</script>

<style scoped lang="scss">
#login {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.login {
  border: 1px solid black;
  border-radius: 5px;
  padding: 1.5rem;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  .container-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    img {
      width: 2rem;
      height: 2rem;
    }
  }
  b-form {
    display: flex;
    flex-flow: column;
    *:not(:last-child) {
      margin-bottom: 1rem;
    }
    input {
      padding: 0.5rem;
    }
    button {
      padding: 0.5rem;
      background-color: lightgray;
      border: 1px solid gray;
      border-radius: 3px;
      cursor: pointer;
      &:hover {
        background-color: lightslategray;
      }
    }
  }
}
.container {
  .logo {
    width: 100%;
    height: $headerHeight;
    .image {
      justify-content: center;
      width: 100%;
      height: 100%;
      cursor: pointer;
      background: url(../../../assets/img/logo.svg) no-repeat right top;
    }
  }
}
</style>
