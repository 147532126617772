<template>
  <b-container>
    <div class="mt-5">
        <div class="d-flex">
            <div style="width: 70%;">
                <h1>Términos y condiciones de Utopie</h1>
            </div>
            <div style="width: 30%; margin: auto;">
                <b-button
                    variant="primary"
                    class="radius-box-5 padding-box-16-lr border-none bold float-right"
                    @click="$router.push({ name: 'home' })"
                >
                    Volver a Utopie
                </b-button>
            </div>
        </div>
        
        <b-card border-variant="light">
            <b-card-text>
                <p>
                    Utopie desarrolla y presta servicios relacionados
                    a la tecnología y la Educación Especial.
                </p>
                <p>
                    Nuestro propósito es interconectarte con todos
                    los profesionales de la Educación del mundo, facilitarte
                    el acceso a materiales educativos y brindarte herramientas
                    informáticas que te permitan ahorran tiempo y mejorar la
                    calidad de tu trabajo.
                </p>
                <p>
                    Puedes crear post, responderlos, valorarlos, catalogarlos,
                    compartirlos, hacer encuestas, descargar archivos realizar,
                    votar encuestas, participar de discusiones de casos
                    clínicos y supervisión, ordenar toda la información digital
                    sobre tus estudiantes y pacientes en un módulo específico.
                </p>
                <p>
                    Todas las marcas y productos externos mencionados en este
                    sitio son propiedad total y exclusiva de sus respectivos
                    dueños, su mención en este sitio es únicamente referencial.
                </p>
            </b-card-text>
        </b-card>
    </div>

    <div class="mt-5">
        <h1>Política de Privacidad</h1>
        <b-card border-variant="light">
            <b-card-text>
                <p>Utopie te informa sobre su Política de Privacidad respecto del tratamiento y protección de los datos de carácter personal de los usuarios y clientes que puedan ser recabados por la navegación o contratación de servicios a través del sitio Web www.utopie.cl.</p>
                <p>El uso de sitio Web implica la aceptación de esta Política de Privacidad así como las condiciones incluidas en el Aviso Legal.</p>
                <h2 class="privacy-subtitle">Obtención de datos personales</h2>
                <p>Para navegar por www.utopie.cl no es necesario que facilites ningún dato personal. Los casos en los que sí proporcionas tus datos personales son los siguientes:</p>
                <ul>
                    <li>Al iniciar sesión en www.utopie.cl</li>
                    <li>Al utilizar la plataforma Redpie</li>
                </ul>
                <h2 class="privacy-subtitle">Tus derechos</h2>
                <p>El Titular te informa que sobre tus datos personales tienes derecho a:</p>
                <ul>
                    <li>Solicitar una rectificación o la cancelación (eliminación).</li>
                    <li>Solicitar la limitación de su tratamiento.</li>
                    <li>Oponerte al tratamiento.</li>
                </ul>
                <p>El ejercicio de estos derechos es personal y por tanto debe ser ejercido directamente por el interesado, solicitándolo directamente al Titular, lo que significa que cualquier cliente, suscriptor o colaborador que haya facilitado sus datos en algún momento puede dirigirse al Titular y pedir información sobre los datos que tiene almacenados y cómo los ha obtenido, solicitar la rectificación de los mismos, solicitar la portabilidad de sus datos personales, oponerse al tratamiento, limitar su uso o solicitar la cancelación de esos datos en los ficheros del Titular.</p>
                <p>Para ejercitar tus derechos de acceso, rectificación, cancelación, portabilidad y oposición tienes que enviar un correo electrónico a contacto@redpie.cl junto con la prueba válida en derecho como una fotocopia del Carnet o equivalente.</p>

                <h2 class="privacy-subtitle">Finalidad del tratamiento de datos personales</h2>
                <p>Cuando inicias sesión, estás facilitando información de carácter personal de la que el responsable es el Titular. Esta información incluye datos de carácter personal como lo son tu nombre, apellidos y dirección de correo electrónico. Al facilitar esta información, das tu consentimiento para que tu información sea recopilada, utilizada, gestionada y almacenada por Utopie, solo como se describe en la presente Política de Privacidad.</p>
                <p>Los datos personales y la finalidad del tratamiento por parte del Titular es diferente según el sistema de captura de información:</p>
                <ul>
                    <li>Inicio de sesión: El Titular solicita los siguientes datos personales: Nombre, apellidos y dirección de correo electrónico. El Titular utiliza esos datos para dar respuesta a tus mensajes, dudas, quejas, comentarios o inquietudes que puedas tener relativas a la información incluida en el sitio Web, y también para mostrar a otros usuarios tu identidad en caso de que añadas comentarios en la página información.</li>
                    <li>Uso de la plataforma Redpie: El Titular solicita los siguientes datos personales: Rut, teléfono, profesión, Registro secreduc, Cargo en el establecimiento.</li>
                </ul>
                
                <p>Existen otras finalidades por las que el Titular trata tus datos personales:</p>
                <ul>
                    <li>Para apoyar y mejorar los servicios que ofrece este sitio Web.</li>
                    <li>Para autocompletar campos relativos al llenado de documentos en la plataforma Redpie</li>
                    <li>Para gestionar las redes sociales. el Titular tiene presencia en redes sociales. Si te haces seguidor en las redes sociales del Titular el tratamiento de los datos personales se regirá por este apartado, así como por aquellas condiciones de uso, políticas de privacidad y normativas de acceso que pertenezcan a la red social que proceda en cada caso y que has aceptado previamente.</li>
                </ul>

                <p>Puedes consultar las políticas de privacidad de las principales redes sociales en estos enlaces:</p>
                <ul>
                    <li>Facebook</li>
                    <li>YouTube</li>
                    <li>Instagram</li>
                </ul>
                <p>El Titular tratará tus datos personales con la finalidad de administrar correctamente su presencia en la red social, informarte de sus actividades, productos o servicios, así como para cualquier otra finalidad que las normativas de las redes sociales permitan.</p>
                <p>En ningún caso el Titular utilizará los perfiles de seguidores en redes sociales para enviar publicidad de manera individual.</p>


                <h2 class="privacy-subtitle">Seguridad de los datos personales</h2>
                <p>Para proteger tus datos personales, el Titular toma todas las precauciones razonables y sigue las mejores prácticas de la industria para evitar su pérdida, mal uso, acceso indebido, divulgación, alteración o destrucción de los mismos.</p>
                <p>El sitio Web está alojado en Digitalocean. La seguridad de tus datos está garantizada, ya que toman todas las medidas de seguridad necesarias para ello. Puedes consultar su política de privacidad para tener más información.</p>

                <h2 class="privacy-subtitle">Contenido de otros sitios web</h2>
                <p>Las páginas de este sitio Web pueden incluir contenido incrustado (por ejemplo, vídeos, imágenes, artículos, etc.). El contenido incrustado de otras web se comporta exactamente de la misma manera que si hubieras visitado la otra web.</p>
                <p>Estos sitios Web pueden recopilar datos sobre ti, utilizar cookies, incrustar un código de seguimiento adicional de terceros, y supervisar tu interacción usando este código.</p>

                <h2 class="privacy-subtitle">Legitimación para el tratamiento de datos</h2>
                <p>La base legal para el tratamiento de tus datos es: el consentimiento. Para contactar con el Titular, o realizar comentarios en este sitio Web tienes que aceptar la presente Política de Privacidad.</p>

                <h2 class="privacy-subtitle">Categorías de datos personales</h2>
                <p>Las categorías de datos personales que trata el Titular son:</p>
                <ul>
                    <li>Datos identificativos.</li>
                </ul>

                <h2 class="privacy-subtitle">Conservación de datos personales</h2>
                <p>Los datos personales que proporciones al Titular se conservarán hasta que solicites su supresión.</p>

                <h2 class="privacy-subtitle">Destinatarios de datos personales</h2>
                <p>Google Analytics es un servicio de analítica web prestado por Google, Inc., una compañía de Delaware cuya oficina principal está en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, Estados Unidos (“Google”). Encontrarás más información en: https://analytics.google.com .</p>
                <p>Google Analytics utiliza “cookies”, que son archivos de texto ubicados en tu ordenador, para ayudar al Titular a analizar el uso que hacen los usuarios del sitio Web. La información que genera la cookie acerca del uso del sitio Web (incluyendo tu dirección IP) será directamente transmitida y archivada por Google en los servidores de Estados Unidos.</p>
                <p>También puedes ver una lista de los tipos de cookies que utiliza Google y sus colaboradores y toda la información relativa al uso que hacen de cookies publicitarias.</p>

                <h2 class="privacy-subtitle">Navegación Web</h2>
                <p>Al navegar por utopie.cl se pueden recoger datos no identificativos, que pueden incluir, la dirección IP, geolocalización, un registro de cómo se utilizan los servicios y sitios, hábitos de navegación y otros datos que no pueden ser utilizados para identificarte.</p>
                <p>El sitio Web utiliza los siguientes servicios de análisis de terceros:</p>
                <ul>
                    <li>Google Analytics</li>
                </ul>
                <p>El Titular utiliza la información obtenida para obtener datos estadísticos, analizar tendencias, administrar el sitio, estudiar patrones de navegación y para recopilar información demográfica.</p>

                <h2 class="privacy-subtitle">Exactitud y veracidad de los datos personales</h2>
                <p>Te comprometes a que los datos facilitados al Titular sean correctos, completos, exactos y vigentes, así como a mantenerlos debidamente actualizados.</p>
                <p>Como Usuario del sitio Web eres el único responsable de la veracidad y corrección de los datos que remitas al sitio exonerando al Titular de cualquier responsabilidad al respecto.</p>

                <h2 class="privacy-subtitle">Aceptación y consentimiento</h2>
                <p>Como Usuario del sitio Web declaras haber sido informado de las condiciones sobre protección de datos de carácter personal, aceptas y consientes el tratamiento de los mismos por parte del Titular en la forma y para las finalidades indicadas en esta Política de Privacidad.</p>

                <h2 class="privacy-subtitle">Revocabilidad</h2>
                <p>Para ejercitar tus derechos de acceso, rectificación, cancelación, portabilidad y oposición tienes que enviar un correo electrónico a contacto@redpie.cl junto con la prueba válida en derecho como una fotocopia del D.N.I. o equivalente. El ejercicio de tus derechos no incluye ningún dato que el Titular esté obligado a conservar con fines administrativos, legales o de seguridad.</p>

                <h2 class="privacy-subtitle">Cambios en la Política de Privacidad</h2>
                <p>El Titular se reserva el derecho a modificar la presente Política de Privacidad para adaptarla a novedades legislativas o jurisprudenciales, así como a prácticas de la industria. Estas políticas estarán vigentes hasta que sean modificadas por otras debidamente publicadas.</p>
            
            </b-card-text>
        </b-card>
    </div>


    <div class="mt-5">
        <h1>¿Qué esperamos de ti?</h1>
        <b-card border-variant="light">
            <b-card-text>
                <p>
                    Esta comunidad fue desarrollada con el propósito de mejorar
                    nuestra profesión en el mundo, a través del uso de tecnología.
                    La meta es y será siempre el bienestar de los niños, para lo
                    cual esperamos de ti que:
                </p>
                <ul>
                    <li>En ningún caso utilices este sitio como medio de publicidad.</li>
                    <li>Respetes a los profesionales que comparten y discuten en este sitio.</li>
                    <li>Utilices un lenguaje aceptable.</li>
                    <li>Proveas al sitio de contenido referido a la Educación Especial.</li>
                </ul>
                <p>
                    Si por algún motivo detectamos que no cumples esta simples
                    reglas tu cuenta podrá ser congelada e incluso eliminada.
                </p>
            </b-card-text>
        </b-card>
    </div>
    <div class="mt-5">
        <h1>¿Cómo se financia Utopie?</h1>
        <b-card border-variant="light">
            <b-card-text>
                <p>
                    Nuestro propósito es brindarle a cada profesional individual
                    la herramienta más poderosa para trabajar con los niños que
                    más lo necesitan del mundo.
                </p>
                <p>
                    Para los colectivos de profesionales, fundaciones,
                    corporaciones, colegios y escuelas del mundo se ofrece
                    de forma pagada la posibilidad de utilizar como colectivo
                    el sitio y sus funcionalidades para organizar su trabajo,
                    en cuyo caso tendrá un valor asociado dependiendo del plan
                    escogido.
                </p>
                <p>
                    Actualmente nos encontramos buscando nuevos modelos de
                    negocio y tan pronto oficialicemos uno, lo podrás ver
                    en este apartado.
                </p>
            </b-card-text>
        </b-card>
    </div>
    <div class="mt-5 pb-5">
        <h1>Propiedad y derechos de autor de los documentos</h1>
        <b-card border-variant="light">
            <b-card-text>
                <p>
                    Dejamos expresamente establecido que Utopie no es responsable
                    del tipo de archivos subidos por la comunidad, es
                    responsabilidad de cada usuario verificar que el
                    contenido subido sea acorde con las leyes vigentes
                    de propiedad intelectual en sus respectivos países.
                </p>
                <p>
                    El no cumplimiento de este elemento será causal de
                    eliminación de post, congelar cuentas e inclusive eliminarlas.
                </p>

            </b-card-text>
        </b-card>
    </div>
  </b-container>
</template>

<script>

export default {
  created() {
  },
  data: function() {
    return {
    };
  },
  methods: {
  }
};
</script>

<style lang="scss">
.privacy-subtitle {
	padding-top: 20px;
	padding-bottom: 10px;
}
</style>
