var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$router.currentRoute.name !== 'planification'
      ? 'general-container'
      : 'global-container'},[_c('loading-image-modal',{ref:"loading-modal"}),_c('TutorialModal',{ref:"tutorial-modal"}),_c('FeedbackButtonComponent',{ref:"feedback-modal"}),_c('MakeTestModal',{ref:"make-test-modal"}),_c('RedpieTutorialModal',{ref:"redpie-tutorial-modal"}),_c('SetCountryModal',{ref:"country-modal",on:{"get-posts":_vm.force_get_posts}}),_c('b-modal',{ref:"message-modal",attrs:{"hide-footer":"","hide-header":"","no-close-on-backdrop":""}},[_c('b-container',[_c('b-row',[_vm._v(" "+_vm._s(_vm.message)+" ")]),_c('b-row',[_c('b-img',{attrs:{"src":_vm.saveAsImg,"fluid":""}})],1),_c('b-row',[_c('b-col',{staticClass:"text-center"},[_c('b-button',{attrs:{"variant":"primary","pill":""},on:{"click":_vm.closeAndUpdate}},[_vm._v(" Entendido! ")])],1)],1)],1)],1),_c('HeaderComponent',{staticStyle:{"z-index":"999"},on:{"search-by-text":_vm.search_by_text,"set-not-new-notifications":_vm.set_not_new_notifications,"load-notifications":_vm.get_my_previous_notifications,"set-open-login-fun":_vm.setOpenLogin,"hidden":_vm.checkAndChange}}),_c('div',{staticClass:"mx-0 header-margin"},[_c('info-banner',{attrs:{"backgroundImg":_vm.mainBanner,"backgroundImgCell":_vm.mainCell,"orientation":"left","offset":2,"colsMD":10,"paddingTop":"4vw","title":"Utopie, el software más avanzado al servicio de la educación","text":"Tú trabajas con los niños, nosotros hacemos el resto.","txtColor":"black","titleSize":"3.5vw"}}),_c('b-container',{ref:"buttons",staticClass:"p-0",staticStyle:{"overflow-x":"hidden"},attrs:{"fluid":"","id":"buttons"}},[_c('b-row',{staticClass:"d-none d-md-flex"},_vm._l((_vm.options),function(option,oIndex){return _c('b-col',{key:oIndex + '-option',staticClass:"text-center",class:option.mode === 'redpie' && !_vm.$store.state.login.isPaid ? '' : ''},[_c('div',{staticClass:"text-center d-block mt-4"},[(
                _vm.$router.currentRoute.name !== option.mode &&
                _vm.service !== option.mode
              )?_c('b-img',{staticClass:"menu-button",style:('width: ' + option.width),attrs:{"src":option.icon},on:{"click":function($event){return _vm.changeView(option.mode)}}}):_c('div',[_c('b-button',{staticClass:"d-inline d-md-none",attrs:{"variant":"primary","pill":""},on:{"click":function($event){_vm.service = '';
                  _vm.$router.push('home');
                  _vm.service = '';}}},[_c('v-icon',{staticClass:"my-2 mx-1",attrs:{"name":"arrow-alt-circle-left","scale":"4"}})],1),_c('b-button',{staticClass:"d-none d-md-inline",attrs:{"variant":"primary","pill":""},on:{"click":function($event){_vm.service = '';
                  _vm.$router.push('home');
                  _vm.service = '';}}},[_c('v-icon',{staticClass:"my-2 mx-1",attrs:{"name":"arrow-alt-circle-left","scale":"4"}})],1)],1)],1),_c('strong',{staticClass:"d-none d-md-block mt-3",staticStyle:{"font-size":"2vw"}},[_c('p',{domProps:{"innerHTML":_vm._s(
                _vm.$router.currentRoute.name !== option.mode &&
                _vm.service !== option.mode
                  ? option.name
                  : 'Volver'
              )}})])])}),1),_c('b-row',{staticClass:"d-flex d-md-none"},_vm._l((_vm.options),function(option,oIndex){return _c('b-col',{key:oIndex + '-option',staticClass:"text-center",class:(option.hideOnCell ? 'd-none' : '') +
            (option.mode === 'redpie' && !_vm.$store.state.login.isPaid ? '' : ''),attrs:{"cols":option.hideOnCell ? 0 : ''}},[_c('div',{staticClass:"text-center d-block mt-4"},[(
                _vm.$router.currentRoute.name !== option.mode &&
                _vm.service !== option.mode
              )?_c('b-img',{staticClass:"menu-button",style:('width: ' + option.widthCell),attrs:{"src":option.icon},on:{"click":function($event){return _vm.changeView(option.mode)}}}):_c('b-button',{attrs:{"variant":"primary","pill":""},on:{"click":function($event){_vm.service = '';
                _vm.$router.push('home');
                _vm.service = '';}}},[_c('v-icon',{staticClass:"my-2 mx-1",attrs:{"name":"arrow-alt-circle-left","scale":"2.3"}})],1)],1),_c('strong',{staticClass:"d-block d-md-none mt-3",staticStyle:{"font-size":"15px"}},[_c('p',{domProps:{"innerHTML":_vm._s(
                _vm.$router.currentRoute.name !== option.mode &&
                _vm.service !== option.mode
                  ? option.name
                  : 'Volver'
              )}})])])}),1)],1),_c('transition',{attrs:{"name":"slide-fade","mode":"out-in"}},[_c('router-view',{key:_vm.reload,ref:"main",staticClass:"main-component",attrs:{"tags":_vm.tags,"bannerLoaded":_vm.bannerLoaded,"routeName":_vm.service},on:{"view-file":_vm.view_file,"points-change":_vm.reload_points,"create-school":_vm.createSchool,"create-school-docs":_vm.createSchoolDocs,"open-test-modal":function($event){return _vm.$refs['make-test-modal'].open()},"toggle-reload":function($event){_vm.reload = !_vm.reload},"try":_vm.changeView,"learn-more":_vm.goToServices,"open-login":_vm.openLoginTrial}})],1),_c('contact-us',{ref:"contact",attrs:{"id":"contact","route":_vm.service}})],1),_c('b-modal',{attrs:{"id":"bv-modal-view-file","size":"xl","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [(_vm.rate_loading)?_c('b-spinner',{attrs:{"variant":"success","type":"grow","label":"Spinning"}}):_c('star-rating',{on:{"rating-selected":_vm.rating_selected},model:{value:(_vm.bound_rating),callback:function ($$v) {_vm.bound_rating=$$v},expression:"bound_rating"}})]},proxy:true}])},[_c('iframe',{staticClass:"w-100",staticStyle:{"height":"66vh"},attrs:{"src":_vm.view_link}}),_c('a',{attrs:{"href":_vm.download_link}},[_c('b-button',{staticClass:"mt-1",attrs:{"variant":"info","block":""},on:{"click":_vm.downloaded}},[_vm._v("Descargar")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }