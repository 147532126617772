<template>
  <b-modal centered ref="incomponent-modal-embed" size="xl" hide-footer hide-header>
    <AddMaterialToFolders ref="add-material"/>
    <div class="incomponent-modal-embed">
      <h2 class="title">{{ data.title }}</h2>
      <div class="close" title="Cerrar" v-on:click="$refs['incomponent-modal-embed'].hide()">
        <v-icon name="times" />
      </div>

      <b-row class="ml-1 d-none d-md-block d-lg-block d-xl-block">
        <b-badge
          pill
          v-for="(subtag, index) in data.subtags"
          :key="'subtag-'+index"
          size="sm"
          variant="secondary"
          class="mt-1 ml-1"
        ><div class="text-light">{{subtag.text}}</div></b-badge>
      </b-row>

      <b-row class="ml-1 d-block d-md-none d-lg-none d-xl-none">
        <b-badge
          pill
          v-for="(subtag, index) in (data.subtags===undefined? []:data.subtags.slice(0, 2))"
          :key="'subtag-'+index"
          size="sm"
          variant="secondary"
          class="mt-1 ml-1"
        ><div class="text-light">{{subtag.text}}</div></b-badge>
        <b-button class="d-block-inline" v-if="data.subtags!==undefined && data.subtags.length > 2" 
          v-b-toggle.collapse-subtags size="sm" pill variant="unknown">Ver más...</b-button>
      </b-row>
      <b-row class="ml-1 d-block d-md-none d-lg-none d-xl-none">
        <b-collapse id="collapse-subtags" class="m-0 p-0">
          <b-badge
            pill
            v-for="(subtag, index) in (data.subtags===undefined? []:data.subtags.slice(2, data.subtags.length))"
            :key="'subtag-'+index"
            size="sm"
            variant="secondary"
            class="mt-1 ml-1"
          ><div class="text-light">{{subtag.text}}</div></b-badge>
        </b-collapse>
      </b-row>


      <b-row>
        <b-col cols="1" v-if="!this.data.commentid && !this.data.postid">
          <b-button block class="h-100" variant="unknown" @click="$emit('watch-previous')">
            <v-icon class="pb-2" name="chevron-left" scale="1.5"/>
          </b-button>
        </b-col>
        <b-col>
          <div class="modal-container">
            <embed :src="this.data.openUrl" width="100%" height="375" />
          </div>
        </b-col>
        <b-col cols="1" v-if="!this.data.commentid && !this.data.postid">
          <b-button block class="h-100" variant="unknown" @click="$emit('watch-next')">
            <v-icon class="pb-2" name="chevron-right" scale="1.5"/>
          </b-button>
        </b-col>
      </b-row>

      <div class="modal-footer">
        <b-container>
          <b-row>
            <b-col>
              <div class="stars">
                <div class="selected">
                  <v-icon name="c-star-on" class="text-primary" scale="1.5" />
                  <span>{{ this.data.stars }} / 5</span>
                  <StarRating :star-size="20" @rating-selected ="setRating"/>
                </div>
              </div>
            </b-col>
            <b-col class="text-right">
              <b-button
                size="sm"
                variant="primary"
                class="radius-box-24 padding-box-16-lr bold mt-4"
                @click="$refs['add-material'].open({id: data.fileid}, true)"
              >
                Agregar
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-left">
              <b-button
                size="sm"
                variant="outline-primary"
                v-b-toggle.collapse-response
                v-if="this.data.commentid"
                class="radius-box-24 padding-box-16-lr bold"
              >Responder</b-button>
              <b-button
                size="sm"
                variant="outline-primary"
                v-b-toggle.collapse-response
                v-if="this.data.postid"
                class="radius-box-24 padding-box-16-lr bold"
              >Comentar</b-button>
            </b-col>
            <b-col class="text-right">
              <div class="buttons">
                <b-button
                  size="sm"
                  variant="primary"
                  class="radius-box-24 padding-box-16-lr bold"
                  @click="download_file"
                >
                  Descargar
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-collapse id="collapse-response" class="mt-2">
              <b-card>
                <div v-if="this.data.postid">
                  <b-form-input v-model="comment" placeholder="Comentario"></b-form-input>
                  <b-button variant="primary" @click="sendComment" size="sm">Enviar comentario</b-button>
                </div>
                <div v-if="this.data.commentid">
                  <b-form-input v-model="text" placeholder="Respuesta"></b-form-input>
                  <b-button variant="primary" @click="sendMessage" size="sm">Enviar respuesta</b-button>
                </div>
              </b-card>
            </b-collapse>
          </b-row>
        </b-container>
      </div>
    </div>
  </b-modal>
</template>

<script>
import StarRating from "vue-star-rating";
import postService from "../../../services/postService";
import fileService from "../../../services/fileService";
import AddMaterialToFolders from "../../redpie/modals/AddMaterialToFolders"

export default {
  components: {
    StarRating,
    AddMaterialToFolders
  },
  data() {
    return {
      data: {},
      comment: "",
      text: "",
    };
  },
  methods: {
    open(data) {
      this.data = data;
      this.$refs["incomponent-modal-embed"].show();
    },
    close() {
      this.$refs["incomponent-modal-embed"].hide();
    },
    downloaded() {
      fileService.materialDownload();
    },
    download_file: function () {
      if (this.$store.state.login.token) {
        fileService.download_file({ file_id: this.data.fileid }).then(
          data => {
            const link = document.createElement('a');
            let filename = data.short_name;
            if (!filename) {
              filename = data.filename;
            }
            this.downloaded()
            link.href = data.download_link;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            this.$emit('points-change')
          },
          error => {
            this.$toasted.error("Ha ocurrido un error al descargar el archivo");
            console.error(error);
          }
        )
      }
      else {
        this.$toasted.error("Debes estar inscrito en la plataforma!");
      }
    },
    sendComment: function() {
      if (this.$store.state.login.token) {
        if (this.comment) {
          let formData = new FormData();
          formData.append("text", this.comment);
          formData.append("post", this.data.postid);
          postService.publish_comment(formData).then(
            data => {
              this.$toasted.success(data.message);
              this.comment = "";
              this.$emit("change", true);
            },
            error => {
              this.$toasted.error("Ha ocurrido un error al publicar");
              console.error(error);
            }
          );
        } else {
          this.$toasted.error("Debes escribir algo!");
          this.comment = "";
        }
      } else {
        this.$toasted.error("Debes estar inscrito en la plataforma!");
      }
    },
    sendMessage: function() {
      if (this.$store.state.login.token) {
        if (this.text) {
          let formData = new FormData();
          formData.append("text", this.text);
          formData.append("comment", this.data.commentid);
          postService.publish_message(formData).then(
            data => {
              this.$toasted.success(data.message);
              this.text = "";
              this.$emit("change", true);
            },
            error => {
              this.$toasted.error("Ha ocurrido un error al publicar");
              console.error(error);
            }
          );
        } else {
          this.$toasted.error("Debes escribir algo!");
          this.comment = "";
        }
      } else {
        this.$toasted.error("Debes estar inscrito en la plataforma!");
      }
    },
    setRating: function(rating) {
      if (this.$store.state.login.token) {
        postService
          .rate_file({ bound_rating: rating, id: this.data.fileid })
          .then(
            data => {
              this.data.stars = data.rate;
              this.$emit("change", this.data.index, data.rate);
              this.$emit('points-change')
            },
            error => {
              this.$toasted.error(error.message);
            }
          );
      } else {
        this.$toasted.error("Debes estar inscrito en la plataforma!");
      }
    }
  }
};
</script>

<style lang="scss">
.incomponent-modal-embed {
  position: relative;

  .title {
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }
  .close {
    position: absolute;
    right: 0;
    top: -12px;
    cursor: pointer;
  }
  .modal-footer {
    width: 100%;
    padding-top: 12px !important;
    padding-bottom: 0px !important;

    .stars {
      position: relative;
      text-align: left;

      .selected {
        display: inline-block;
        font-size: 12px;
        font-weight: bold;
      }
      .select {
        margin-left: 8px;
        padding-left: 8px;
        border-left: solid 1px $dark;
        display: inline-block;
      }
    }
    .buttons {
      text-align: right;

      button {
        padding: 4px 12px;
        font-size: 14px;
      }
    }
  }
}
</style>
