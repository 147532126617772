<template>
	<b-container class="custom-view w-100">
		<b-row>
			<!-- add text add right of the row -->
			<b-col cols="12" class="px-1">
				<p class="text-right font-weight-normal">
					Vitrina de Materiales Premium
					<img :src=starIcon width="22px" alt="start-icon" class="pl-1 mb-1"/>  
				</p>
			</b-col>
		</b-row>
		<b-row class="mx-5 mb-3">
			<b-col cols="4" class="px-1">
				<Selector-component :options="grades" />
			</b-col>
			<b-col cols="8" class="px-1">
				<Selector-component :options="subjects" />
			</b-col>
		</b-row>
		<b-row v-for="(material, matIdx) in materials" :key="matIdx" class="mx-4 my-2">
			<b-col cols="4" class="text-center">
				<img :src="material.img" alt="material-img" :style="{ width: '150px', height: '150px', borderRadius: '50%' }"/>
			</b-col>
			<b-col cols="8">
				<p :style="{ fontSize: '12px'}"> {{material.title}}</p>
				<div v-for="(tag, tagIdx) in material.tags" :key="tagIdx" class="custom-tag w-50">
					<p class="my-0"> {{ tag.name }}</p>	
				</div>
				<div class="button-container">
					<img :src="watchIcon" alt="ver">
					<img :src="appIcon" alt="aplicar">
				</div>
			</b-col>
		</b-row>
	</b-container>
</template>
<script>
import SelectorComponent from "@/components/botpie-assistant/util-components/SelectorComponent";

export default {
	name: 'PremiumViewComponent',
	components: {
		SelectorComponent
	},
	data(){
		return {		
			starIcon: require('@/assets/img/icono-estrella.svg'),
			watchIcon: require('@/assets/img/icono-ojo.svg'),
			appIcon: require('@/assets/img/icono-aplicacion.svg'),
			materials: [
				{
					title: "2do Basico - expresion oral - lectura de palabras y frases",
					tags: [
						{
							name: "OA 1: Escuchar y hablar",
						},
						{
							name: "OA 2: Leer",
						},
						{
							name: "OA3: Escribir"
						},
					],
					description: "Descripción del material 1",
					grade: "Primero",
					subject: "Matemáticas",
					img: require('@/assets/img/imagen-material-1.png')
				},
				{
					title: "2do Basico - expresion oral - lectura de palabras y frases",
					tags: [
						{
							name: "OA 1: Escuchar y hablar",
						},
						{
							name: "OA 2: Leer",
						},
						{
							name: "OA3: Escribir"
						},
					],
					description: "Descripción del material 2",
					grade: "Segundo",
					subject: "Lengua",
					img: require('@/assets/img/imagen-material-2.png')
				},
			]
		}
	},
	props: {
		grades: {
			type: Array,
			required: true,
			default: () => [
				"Primero",
				"Segundo",
			]
		},
		subjects: {
			type: Array,
			required: true,
			default:() => [
				"Matemáticas",
				"Lengua",
			]
		}
	}
}
</script>
<style scoped>
.custom-view {
	background-color: #F5F5F5;
	border-radius: 15px;
	border: 1.5px dashed #E84855;
	padding: 1em 1.5em;
}
.custom-img {
	border-radius: 50%;
	height: auto;
}
.custom-tag {
	border-radius: 6px;
	border: 1.5px solid #E84855;
	padding: 2px 5px;
	margin: 2px;
	font-size: 10px;
}

.button-container {
	display: flex;
	align-items: center; /* Para alinear verticalmente */
	justify-content: flex-start; /* Para alinear horizontalmente */
	max-height: 80px;
}
.button-container img {
	margin-right: 10px; /* Ajusta el margen entre las imágenes */
	cursor: pointer; /* Cambia el cursor al pasar por encima */
	width: 20%;
}
</style>