<template>
  <div id="sidebar-container" v-bind:class="{ opened: showSidebar }">
    <div class="sidebar-content">
      <div class="toggle-sidebar">
        <div class="circle-button" v-on:click="showSidebar = !showSidebar">
          <div class="vertical-icon"></div>
        </div>
      </div>
      <div class="show-data">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      showSidebar: false
    };
  },
  computed: mapState(["points"]),
  created: function() {
    if (process.browser) {
      window.addEventListener("resize", this.setSidebarHeight);
      this.setSidebarHeight();

      this.$nextTick(() => {
        this.setSidebarHeight();
      });
    }
  },
  destroyed: function() {
    if (process.browser) {
      window.removeEventListener("resize", this.setSidebarHeight);
    }
  },
  methods: {
    setSidebarHeight() {
      const sidebar = document.querySelector("#sidebar-container");
      const header = document.querySelector("#header");

      if (sidebar) {
        const height = window.innerHeight - header.offsetHeight;
        sidebar.style.height = `${height}px`;
        sidebar.style.top = `${header.offsetHeight}px`;
      }
    }
  },
  watch: {
    // eslint-disable-next-line
    /*
    points(new_value, old_value) {
      if(new_value===0 ||
          (new_value<=20 && old_value>new_value))
        this.showSidebar=true;
    }*/
  }
};
</script>

<style lang="scss">
#sidebar-container.opened {
  right: 0;
  transition: all 0.336s;
}
#sidebar-container {
  position: fixed;
  width: $sidebarWidth;
  height: 0;
  top: 0;
  z-index: 9;
  right: -$sidebarWidth;
  transition: all 0.336s;

  .sidebar-content {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 100% !important;
    background-color: $dark;

    .toggle-sidebar {
      position: absolute;
      margin-left: -11px;
      left: 0;
      width: 11px;
      height: 100%;
      background-color: $secondary !important;

      .circle-button {
        position: relative;
        width: 64px;
        height: 64px;
        margin-left: -32px;
        border-radius: 32px;
        top: 25%;
        background-color: $secondary !important;
        transition: all 0.256s;
        cursor: pointer;
        z-index: 10;

        .vertical-icon {
          position: absolute;
          width: 5px;
          height: 32px;
          margin: 16px 18px;
          background-color: $secondary-dark !important;
          border-radius: 2px;
        }
      }
      .circle-button:hover {
        background-color: $secondary-dark !important;
        transition: all 0.256s;

        .vertical-icon {
          background-color: $secondary !important;
        }
      }
    }

    .show-data {
      position: relative;
      width: 100%;
      height: 100%;
      overflow-y: auto;
    }
  }
}
</style>
