<template>
  <div>
    <loading-image-modal ref="loading-modal"/>
    <b-row class="pb-1">
      <b-img rounded class="d-block d-md-none d-lg-none d-xl-none" 
        style="width: 100%; height: 15vh;" :src="bannerSchool" @click="goToRedpie()"/>
      <b-img rounded class="d-none d-md-block d-lg-block d-xl-block" 
        style="width: 100%; height: 15vh;" :src="banner"/>
    </b-row>
  </div>
</template>
<script>
import LoadingImageModal from "../modals/LoadingImageModal";
import redpieService from "../../../services/redpieService";

export default {
  components: {
    LoadingImageModal
  },
  data() {
    return {
      banner: require('../../../assets/img/bannermanitos892x112.png'),
      bannerSchool: require('../../../assets/img/mi_colegio_hor.svg'),
      firstButton: require('../../../assets/img/banner/banner1.jpg'),
      secondButton: require('../../../assets/img/banner/banner2.jpg'),
      thirdButton: require('../../../assets/img/banner/banner3.jpg'),
      fourthButton: require('../../../assets/img/banner/banner4.jpg'),
    }
  },
  methods: {
    redirectToRedpie() {
      window.location.href = "/redpie#/";
    },
    goToSales(){
      window.location.href = "/comprar#/";
    },
    createSchool: function() {
      var payload = {
        socket: (this.$store.state.login.token === ""? this.$store.state.login.anonID : this.$store.state.login.user_id)
      }
      redpieService.createSchool(payload).then(
        () => {
          this.$refs['loading-modal'].open(true, false)
        }
      )
    },
    goToRedpie() {
      if (this.$store.state.login.school && this.$store.state.login.token) {
        this.redirectToRedpie();
      }
      // else {
      //   this.createSchool();
      // }
    }
  }
}
</script>