var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ModalEmbed',{ref:"modal-embed",on:{"change":_vm.onChange,"watch-next":function($event){return _vm.$emit('watch-next')},"watch-previous":function($event){return _vm.$emit('watch-previous')},"points-change":function($event){return _vm.$emit('points-change')}}}),_c('b-card',{staticStyle:{"max-width":"20rem"},attrs:{"no-body":"","img-top":"","footer-tag":"footer","footer-bg-variant":"primary"},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('b-row',[_c('b-col',{attrs:{"cols":"9"}},[_vm._v(" "+_vm._s(_vm.file.post_text)+" "),_c('br'),_vm._l((_vm.file.subtags),function(subtag,index){return _c('b-badge',{key:'subtag-'+index,attrs:{"pill":"","size":"sm","href":"#","variant":"secondary"},on:{"click":function($event){return _vm.$emit('addSubtag', subtag.text)}}},[_c('div',{staticClass:"text-light"},[_vm._v(_vm._s(subtag.text))])])})],2),_c('b-col',{staticClass:"verticalLine text-center",attrs:{"cols":"3"}},[_c('v-icon',{staticClass:"cursor-pointer",attrs:{"name":"share","id":_vm.file.id+'share'}})],1)],1)]},proxy:true}])},[_c('b-card-img',{attrs:{"src":'https://drive.google.com/thumbnail?id='+_vm.file.id_google_drive}}),_c('b-card-body',{staticClass:"cursor-pointer",attrs:{"overlay":""},on:{"click":function($event){return _vm.$refs['modal-embed'].open({
      index: _vm.index,
      openUrl: _vm.file.view_link,
      title: _vm.file.short_name,
      stars: _vm.file.rate,
      fileid: _vm.file.id,
      subtags: _vm.file.subtags
    })}}},[_c('b-card-text',[_c('div',{staticClass:"text-right"},[_c('b-badge',{attrs:{"variant":"light"}},[_c('v-icon',{staticClass:"icon",attrs:{"name":"star"}}),_vm._v(" "+_vm._s(_vm.file.rate)+" / 5 ")],1)],1)])],1)],1),_c('b-popover',{ref:"popover",attrs:{"triggers":"hover","target":_vm.file.id+'share'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('b-button',{staticClass:"close",attrs:{"variant":"outline","aria-label":"Close"},on:{"click":function($event){return _vm.$refs.popover.$emit('close')}}},[_c('span',{staticClass:"d-inline-block",attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" Compartir ")]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }