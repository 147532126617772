<template>
  <b-modal
    centered
    size="xl"
    ref="incomponent-tutorial-modal"
    hide-footer
    hide-header
  >
    <b-container>
      <b-row>
        <b-col class="text-center">
          <h3 style="display: inline-block">Tutorial</h3>
          <v-icon scale="1.5"
            class="close-button"
            name="times"
            @click="close()" />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="1">
          <b-button
            style="height: 100% !important;"
            variant="unknown"
            :disabled="index === 0"
            class="no-outline border-none text-dark"
            @click="goBack()"
          >
            <v-icon name="step-backward" />
          </b-button>
        </b-col>
        <b-col cols="10">
          <b-overlay :show="loading" rounded="sm">
            <b-img fluid :src="showingImage" @load="loading = false"/>
          </b-overlay>
        </b-col>
        <b-col cols="1">
          <b-button
            style="height: 100% !important;"
            variant="unknown"
            :disabled="index === finalImages.length-1"
            class="no-outline border-none text-dark"
            @click="goAhead()"
          >
            <v-icon name="step-forward" />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center font-weight-bold text-primary" style="white-space: pre-wrap;">
          {{ suggestions.length>index? suggestions[index].text : 'No hay más sugerencias'}}
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          <b-form-group>
            <b-form-radio-group id="radio-group-2" v-model="index" name="pages">
              <b-form-radio v-for="(image, i) in finalImages" v-bind:key="image" :value="i" />
            </b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>
<script>
import suggestionsService from "../../../services/suggestionsService";

export default {
  components: {
  },
  data () {
    return {
      images: [
        require('../../../assets/img/tutorial-utopie/1 -2.jpg'),
        require('../../../assets/img/tutorial-utopie/2 -2.jpg'),
        require('../../../assets/img/tutorial-utopie/tutoriales sin texto utopie.jpg'),
        require('../../../assets/img/tutorial-utopie/tutoriales sin texto utopie-2.jpg'),
        require('../../../assets/img/tutorial-utopie/tutoriales sin texto utopie-3.jpg'),
        require('../../../assets/img/tutorial-utopie/tutoriales sin texto utopie-4.jpg'),
        require('../../../assets/img/tutorial-utopie/tutoriales sin texto utopie-5.jpg'),
        require('../../../assets/img/tutorial-utopie/tutoriales sin texto utopie-6.jpg'),
        require('../../../assets/img/tutorial-utopie/tutoriales sin texto utopie-7.jpg'),
        require('../../../assets/img/tutorial-utopie/tutoriales sin texto utopie-8.jpg'),
        require('../../../assets/img/tutorial-utopie/tutoriales sin texto utopie-9.jpg'),
        require('../../../assets/img/tutorial-utopie/tutoriales sin texto utopie-10.jpg'),
        require('../../../assets/img/tutorial-utopie/tutoriales sin texto utopie-11.jpg'),
      ],
      index: 0,
      suggestions: [],
      obtainedImages: [],
      showingImage: "",
      loading: false,
    }
  },
  created() {
    this.getUtopieTutorialIndications();
    this.getUtopieTutorialSlides();
  },
  computed: {
    finalImages: function() {
      if (this.obtainedImages.length > 0) {
        return this.obtainedImages;
      }
      else {
        return this.images;
      }
    }
  },
  methods: {
    open() {
      this.index = 0;
      this.loading = true;
      this.$nextTick(() => {
        this.showingImage = this.finalImages[this.index];
      })
      this.$refs["incomponent-tutorial-modal"].show();
    },
    close() {
      this.$refs["incomponent-tutorial-modal"].hide();
    },
    getUtopieTutorialIndications: function () {
      suggestionsService.getUtopieTutorialIndications().then(
        data => {
          for(var i=0; i<data.length; i++) {
            this.suggestions.push(data[i])
          }
        }
      )
    },
    getUtopieTutorialSlides: function () {
      suggestionsService.getUtopieTutorialSlides().then(
        data => {
          this.obtainedImages = data.slides.map(
            (slide) => {
              return process.env.VUE_APP_BASE_URL+slide.slide});
        }
      )
    },
    goAhead() {
      this.loading = true;
      this.$nextTick(() => {
        this.index = (this.index < this.finalImages.length - 1) ? this.index + 1 : this.finalImages.length -1
        this.showingImage = this.finalImages[this.index];
      })
    },
    goBack() {
      this.loading = true;
      this.$nextTick(() => {
        this.index = (this.index > 0) ? this.index - 1 : 0
        this.showingImage = this.finalImages[this.index];
      })
    }
  }
}
</script>
<style lang="scss">
.row {
  padding-bottom: 5px;

  .custom-col {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 0px !important;
  }
}
.close-button {
  cursor: pointer !important;
  display: inline-block;
  position: absolute;
  right: 5px;
}
</style>
