<template>
  <div>
    <loading-image-modal ref="loading-modal"/>
    <div class="padding-box-normal" 
      style="cursor: pointer;"
      @click="$store.state.login.school && $store.state.login.token? goToRedpie(): 
        (!$store.state.login.token || (!$store.state.login.school && $store.state.login.token))? createSchool():(() => {})">
      <ImageAdvertising
              :custom="true"
              :src="goToRedpieImg" />
      <!-- <b-container v-if="$store.state.login.school && $store.state.login.token" class="w-100">
        <b-row align-h="center" class="w-100">
          <a href="/redpie#/">
            <ImageAdvertising
              :custom="true"
              :src="goToRedpieImg" />
          </a>
        </b-row>
      </b-container>
      <b-container v-if="!$store.state.login.token">
        <b-row
          align-h="center"
          style="cursor: pointer;"
          @click="createSchool()"
        >
          <ImageAdvertising
            :src="goToRedpieImg" />
        </b-row>
      </b-container> -->
      <!-- <ListInfoComponent
        title="Corregir automáticamente"
        ref="tests"
        v-on:apply="apply_function"
        v-if="!$store.state.login.school && $store.state.login.token && false"
      /> -->
    </div>
    <div
      style="cursor: pointer;"
      class="padding-box-normal"
      v-if="current==='media'"
      @click="goToHome"
    >
      <ImageAdvertising
        :src="goToMainImg" />
    </div>
    <!-- <div
      style="cursor: pointer;"
      class="padding-box-normal"
      v-else
      @click="goToMedia"
    >
      <ImageAdvertising
        :src="goToMediaImg" />
    </div> -->
    <div class="padding-box-normal" style="cursor: pointer;" @click="goToSales()">
      <ImageAdvertising :src="advertisingImg" />
    </div>
    <div class="padding-box-normal">
      <!-- <b-button
        variant="primary"
        class="radius-box-5 padding-box-16-lr border-none bold"
        @click="$router.push({ name: 'terms' })"
      >
        Términos y condiciones
      </b-button> -->
    </div>
  </div>
</template>

<script>
import AdvertisingComponent from "./AdvertisingComponent";
import ListInfoComponent from "./ListInfoComponent";
import testService from "../../../services/testService";
import ImageAdvertising from "../imported/ImageAdvertising";
// import redpieService from "../../../services/redpieService";
import LoadingImageModal from "../modals/LoadingImageModal";
import informationService from "../../../services/informationService";

export default {
  components: {
    LoadingImageModal,
    AdvertisingComponent,
    ListInfoComponent,
    ImageAdvertising
  },
  props: {
    tests: { type: Array, default: () => []}
  },
  created() {
    this.getCountries()
    if (this.$router.currentRoute.path === '/tutorial-redpie') {
      this.$emit('open-redpie-tutorial');
    }
    // this.get_tests();
  },
  computed: {
    token () {
      return this.$store.state.login.token
    }
  },
  data() {
    return {
      other_options: {
        options: this.get_other_options(),
      },
      goToRedpieImg: require('../../../assets/img/mi_colegio_c_font.svg'),
      goToMediaImg: require('../../../assets/img/Banner 1.jpg'),
      advertisingImg: require('../../../assets/img/Banner_web_nino_redpie.svg'),
      goToMainImg: require('../../../assets/img/Banner 3.jpg'),
      current: this.$router.currentRoute.name,
      countries: {},
      loading_first_school: false,
    };
  },
  methods: {
    createSchool: function() {
      if (!this.token) {
        this.$toasted.error("Inicia sesión para ver tu colegio!")
      }
      else {
        this.$toasted.error("No tienes colegio asociado!")
      }
      // this.$emit('open-login');
      // var payload = {
      //   socket: (this.$store.state.login.token === ""? this.$store.state.login.anonID : this.$store.state.login.user_id)
      // }
      // redpieService.createSchool(payload).then(
      //   () => {
      //     this.$refs['loading-modal'].open(true, false)
      //   }
      // )
    },
    getCountries: function() {
      informationService.getCountries().then(
        data => {
          this.countries = data.countries;
        }
      )
    },
    new_test: function(test) {
      testService
        .do_test({ test: test})
        .then(
          data => {
            this.$store.commit('login/setSchool', data.school_id)
            window.location.href = data.redirect;
          },
          error => {
            console.error(error);
            this.$toasted.error("Ha ocurrido al hacer el test");
          }
        );
    },
    download_test: function(id) {
      testService
        .download_test({id: id})
        .then(
            data => {
              console.log(data);
            }
        )
    },
    get_tests: function() {
      testService
        .get_my_lastest_tests()
        .then(
          data => {
            this.$refs.tests.data = {
              options: data.tests
            }
          }
        )
    },
    new_planification: function() {
      this.$router.push({ name: "new_planification" });
    },
    goToSales: function() {
      window.location.href = "/comprar#/";
    },
    goToMedia: function() {
      this.$router.push({ name: 'media' });
      this.current = 'media';
    },
    goToHome: function () {
      this.$router.push({name: 'home'});
      this.current = 'home';
    },
    goToRedpie: function() {
      if (this.$store.state.login.school && this.$store.state.login.isPaid){
        window.location.href = "/redpie#/";
      }
      else {
        this.$toasted.error("No tienes acceso a Redpie!")
      }
    },
    test_socket: function() {
      // $socket is [WebSocket](https://developer.mozilla.org/en-US/docs/Web/API/WebSocket) instance
      // or with {format: 'json'} enabled
      this.$socket.sendObj({ message: "sending message" });
    },
    get_other_options: function() {
      let res = [
        {
          description: "Ver Media",
          colorDescription: "primary",
          badge: "123 up",
          colorBadge: "primary",
          onClick: this.go_to_media,
          arguments: null
        }
      ];
      if (process.env.VUE_APP_TEST_SOCKET === process.env.VUE_APP_TRUE) {
        res.push({
          description: "Test Socket",
          colorDescription: "primary",
          badge: "123 up",
          colorBadge: "primary",
          onClick: this.test_socket,
          arguments: null
        })
      }
      return res
    },
    apply_function: function(f, arg) {
      this[f](arg);
    }
  },
  watch: {
    token () {
      // this.get_tests()
    }
  }
};
</script>
<style lang="scss">
</style>
