<template>
  <div class="global-container">
    <HeaderComponent
      @search-by-text="search_by_text"
    />
    <router-view ref="main" />
  </div>   
</template>

<script>
import authService from "../../services/authService";
import HeaderComponent from "../../components/utopie/miscellaneous/HeaderComponent";
import { mapState } from "vuex";

export default {
  components: {
    HeaderComponent
  },
  computed: mapState(["token"]),
  created() {
    const vue_scope = this;
    if (this.$store.state.login.token) {
      this.verify_token();
      if(!window.webpackHotUpdate){
        this.$connect();
      }
    }
    this.$options.sockets.onmessage = data =>
      vue_scope.$toasted.success(JSON.parse(data.data).message);
  },
  data: function() {
    return {};
  },
  methods: {
    verify_token: function() {
      authService.verify_token().then(
        // eslint-disable-next-line
        data => {
          // console.log("verifying token...");
        },
        // eslint-disable-next-line
        error => {
          this.$toasted.error("Tu sesión ha expirado.");
          this.$store.commit("login/setUserEmpty");
        }
      );
    },
    search_by_text: function(text) {
      this.$refs.main.search(text);
    }
  },
  watch: {
    // eslint-disable-next-line
    $route(to, from) {
      if (this.$store.state.login.token) {
        this.verify_token();
      }
    }
  }
};
</script>