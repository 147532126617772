<template>
  <div class="tutorial-sidebar">
    <div class="current-up">
      <b-badge variant="primary" class="badge">{{$store.state.login.points}} Aportes</b-badge>
    </div>
    <!--
    <div class="awards">
      <div class="award" v-for="(award, index) in awards" :key="'award-'+index">
        <div class="icon">
          <v-icon :name="award.icon" v-bind:class="[ 'text-' + award.iconColor ]" scale="2.2" />
        </div>
        <div class="detail">
          <p>{{ award.description }}</p>
        </div>
      </div>
    </div>
    -->
    <span>
      Utopie no es un lugar, eres tú, soy yo, somos todos!
    </span>
    <hr class="bg-light" />

    <div class="special-info">
      <h4>Consigue más Aportes</h4>
      <p>
        Si clasificas posts sin categorías
        conseguirás
        <strong
          class="text-primary"
          style="cursor: pointer;"
          @click="$emit('get-filtered-posts', 'Sin Tag')">
            4 Aportes.
        </strong>
      </p>
      <p>
        <strong
          class="text-primary"
          style="cursor: pointer;"
          @click="$emit('get-filtered-posts', 'Preguntas')">
            Responde preguntas
        </strong>
         para conseguir la recompensa
      </p>
      <p>
        Cada 5 comentarios o 5 likes en tus posts conseguirás
        <strong class="text-primary">2 Aporte.</strong>
      </p>
      <p>
        Calificando archivos conseguirás
        <strong class="text-primary">1 Aporte.</strong> por cada archivo
        nuevo que clasifiques
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      awards: [
        { icon: "c-award", iconColor: "primary", description: "100 Estrellas" },
        { icon: "c-award", iconColor: "primary", description: "500 Estrellas" },
        { icon: "c-award", iconColor: "primary", description: "50 Materiales" },
        { icon: "c-award", iconColor: "primary", description: "150 TAGS" },
        { icon: "c-award", iconColor: "primary", description: "70 Respuestas" },
        { icon: "c-award", iconColor: "primary", description: "300 TAGS" },
        {
          icon: "c-award",
          iconColor: "primary",
          description: "1000 Estrellas"
        },
        { icon: "c-award", iconColor: "light", description: "" },
        { icon: "c-award", iconColor: "light", description: "" },
        { icon: "c-award", iconColor: "light", description: "" }
      ]
    };
  }
};
</script>

<style lang="scss">
.tutorial-sidebar {
  width: 100%;
  padding: 18px 12px;
  color: white;

  .current-up {
    width: 100%;
    text-align: center;

    .badge {
      font-size: 18px;
    }
  }

  .awards {
    position: relative;
    width: 100%;
    margin: 16px 0;
    text-align: center;

    .award {
      width: 58px;
      margin: 4px 2px;
      display: inline-block;
      vertical-align: top;

      .detail {
        width: 100%;

        p {
          margin: 2px 0;
          font-size: 10px;
          line-height: 11px !important;
          font-weight: bold;
        }
      }
    }
  }

  .special-info {
    width: 100%;
    padding: 12px;

    h4 {
      width: 100%;
      margin: 0;
      font-size: 14px;
      font-weight: bold;
    }

    p {
      margin: 14px 0;
      font-size: 11px;
      line-height: 15px;
    }
  }
}
</style>
