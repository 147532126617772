<template>
  <div class="user-popup">
    <div class="user-profile">
      <!--<div class="user-avatar">
        <img :src="$store.state.login.thumbnail" />
      </div>
      <div class="user-badge">
        <b-badge variant="primary">{{$store.state.login.points}} Aportes</b-badge>
      </div>-->
    </div>
    <div class="user-details">
      <!--<h2>{{$store.state.login.first_name}} {{$store.state.login.last_name}}</h2>-->
      <!--<h4>
        Lvl Participación -
        <strong>{{profile_data.level}}</strong>
      </h4>-->
    </div>
    <div class="user-stats">
      <!--<b-progress
        :value="profile_data.current_level_exp"
        :max="profile_data.next_level_experience"
        variant="secondary"
      ></b-progress>-->
      <div class="labels pl-4 pr-3">
        <span class="left"> {{ countries[$store.state.login.country] }}</span>
        <span class="right">Internacional</span>
      </div>
    </div>
  </div>
</template>

<script>
import userService from "../../../services/userService";
import informationService from "../../../services/informationService";

// Import Vue FilePond
import vueFilePond from "vue-filepond";
// Import FilePond plugins
// Please note that you need to install these plugins separately
// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);

export default {
  components: {
    FilePond,
  },
  created() {
    this.get_user_data();
    this.getCountries();
  },
  data: function () {
    return {
      profile_loading: true,
      cv: [],
      profile_data: {
        cv: "",
        cv_link: "",
        points: 0,
        next_level_experience: 200,
        current_level_exp: 50,
        level: 1,
      },
      countries: {},
    };
  },
  methods: {
    erase_cv: function () {
      userService.delete_cv({}).then(
        (data) => {
          this.$toasted.success(data.message);
          this.profile_data.cv = "";
        },
        (error) => {
          this.$toasted.error("Ha ocurrido un error al borrar el curriculúm");
          console.error(error);
        }
      );
    },
    getCountries: function () {
      informationService.getCountries().then((data) => {
        this.countries = data.countries;
      });
    },
    save_cv: function () {
      this.profile_loading = true;
      let formData = new FormData();
      formData.append("cv", this.cv[0].file);
      if (this.$store.state.login.token) {
        if (this.cv[0]) {
          userService.save_cv(formData).then(
            (data) => {
              this.$toasted.success(data.message);
              this.profile_data.cv = data.cv_filename;
              this.profile_data.cv_link = data.cv_link;
              this.profile_loading = false;
            },
            (error) => {
              this.$toasted.error(
                "Ha ocurrido un error al subir el curriculúm"
              );
              console.error(error);
            }
          );
        } else {
          this.$toasted.error("Debes agregar un curriculúm!");
        }
      } else {
        this.$toasted.error("Debes estar inscrito en la plataforma!");
      }
    },
    get_user_data: function () {
      userService.get_user_data().then(
        (data) => {
          this.profile_data = data;
          this.profile_loading = false;
        },
        (error) => {
          // this.$toasted.error("Ha ocurrido un error al subir el currículum");
          console.error(error);
        }
      );
    },
  },
};
</script>

<style lang="scss">
.user-popup {
  width: 100%;

  .user-profile {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .user-avatar {
      width: 100%;

      img {
        width: 100%;
        border-radius: 50%;
        border: solid 4px $primary;
      }
    }
    .user-badge {
      padding: 24px 12px;
      font-size: 1.5em;
    }
  }

  .user-details {
    width: 100%;
    margin: 16px 0;

    h2,
    h4 {
      width: 100%;
      margin: 0;
      padding: 0;
    }

    h2 {
      font-size: 1.5em;
      font-weight: bold;
    }
    h4 {
      font-size: 1.1em;
    }
  }

  .user-stats {
    width: 100%;

    .progress {
      height: 12px;
      background: white;
    }

    .labels {
      width: 100%;
      display: flex;

      .left {
        width: 50%;
        text-align: left;
        color: $secondary;
        font-weight: bold;
      }
      .right {
        width: 50%;
        text-align: right;
      }
    }
  }
}
</style>
