var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',[_c('b-row',[_c('b-col',{staticClass:"p-0",attrs:{"cols":"1"}},[_c('b-button',{staticClass:"mx-auto btn-rounded mt-3 px-1",attrs:{"disabled":_vm.currentPage === _vm.previousPage,"variant":_vm.currentPage === _vm.previousPage ? 'light' : ''},on:{"click":function($event){return _vm.goBackPage()}}},[_c('v-icon',{staticClass:"pb-2",attrs:{"name":"chevron-left","scale":"1.5"}})],1)],1),_c('b-col',{staticClass:"text-center p-0",attrs:{"cols":"10"}},[_c('carousel',{ref:"redirect-carousel",attrs:{"per-page-custom":[
          [100, 3],
          [480, 3],
          [768, 3],
          [1024, 4],
          [1366, 4],
        ],"mouse-drag":false,"navigationEnabled":false,"paginationEnabled":false},on:{"page-change":function($event){return _vm.updatePages()}}},_vm._l((_vm.options),function(option,index){return _c('div',{key:'option-' + index},[(!option.require || (option.require && option.requisit))?_c('slide',[_c('b-container',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){option.emit
                  ? _vm.emitEvent(option.redirect_to)
                  : _vm.redirectToPage(option.redirect_to)}}},[_c('b-row',[_c('b-col',{staticClass:"text-center"},[_c('b-img',{class:option.extra_margin ? 'm-' + option.extra_margin : '',attrs:{"rounded":"circle","width":option.width,"src":option.icon_name}})],1)],1),_c('b-row',[_c('b-col',{staticClass:"text-center"},[_c('strong',{staticStyle:{"font-size":"15px"}},[_vm._v(" "+_vm._s(option.name)+" ")])])],1)],1)],1):_vm._e()],1)}),0)],1),_c('b-col',{staticClass:"p-0",attrs:{"cols":"1"}},[_c('b-button',{staticClass:"mx-auto btn-rounded mt-3 px-1",attrs:{"disabled":_vm.currentPage === _vm.nextPage,"variant":_vm.currentPage === _vm.nextPage ? 'light' : ''},on:{"click":function($event){return _vm.advancePage()}}},[_c('v-icon',{staticClass:"pb-2",attrs:{"name":"chevron-right","scale":"1.5"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }