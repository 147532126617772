<template>
  <div class="w-100" @click="edit_name(data.item.id)">
    <b-input-group size="sm" v-if="editting_name">
      <b-form-input maxlength="300" v-model="new_name" @keyup.enter="save_name(data.item.id)"></b-form-input>
      <b-input-group-append>
        <b-button
          variant="outline-success"
          @click="save_name(data.item.id)"
          :disabled="new_name==data.item.name"
        >Guardar</b-button>
      </b-input-group-append>
    </b-input-group>
    <strong v-else>{{data.item.name}}</strong>
  </div>
</template>

<script>
import testService from "../../../services/testService";
export default {
  created() {
    this.new_name = this.data.item.name;
  },
  data: function() {
    return {
      editting_name: false,
      new_name: ""
    };
  },
  props: ["data"],
  methods: {
    edit_name: function() {
      this.editting_name = true;
    },
    save_name: function(test_id) {
      var new_name = this.new_name;
      // check if new name is valid
      if (!new_name) {
        this.$toasted.error("Debes ingresar un nombre!");
        return;
      }
      // send the request
      testService.save_name_test({ test: test_id, name: new_name }).then(
        data => {
          this.$toasted.success(data.message);
          this.data.item.name = new_name;
          this.editting_name = false;
        },
        error => {
          this.$toasted.error("Ha ocurrido un error al obtener los tests.");
          console.error(error);
        }
      );
    }
  }
};
</script>
<style></style>