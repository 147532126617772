<template>
  <b-container>
    <h1 class="line line-primary mb-6">PIE without fines</h1>
    <div class="balloon balloon-top">
      <ul
        class="list-group list-group-horizontal-md nav-primary nav-router"
        role="navigation"
        aria-label="main"
      >
        <li class="nav-item list-group-item active">
          <a class="nav-link" href="#">e-Learning</a>
        </li>
        <li class="nav-item list-group-item">
          <a class="nav-link" href="#">Frequent Questions</a>
        </li>
        <li class="nav-item list-group-item disabled">
          <a class="nav-link" href="#" tabindex="-1" aria-disabled="true">Forum</a>
        </li>
      </ul>
      <h2 class="h3 mb-5">Training Modules</h2>
      <ul role="navigation" aria-label="steps" class="nav nav-primary nav-steps">
        <li class="nav-item nav-item-finished">
          <div class="text-center icon-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              class="icon"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
            </svg>
          </div>
          <a href="#" class="nav-link">Donec ac ligula auctor ipsum iaculis lobortis.</a>
        </li>
        <li class="nav-item nav-item-active">
          <a href="#" class="nav-link">Donec ac ligula auctor ipsum iaculis lobortis.</a>
        </li>
        <li class="nav-item">
          <a href="#" class="nav-link">Donec ac ligula auctor ipsum iaculis lobortis.</a>
        </li>
        <li class="nav-item disabled">
          <a
            href="#"
            tabindex="-1"
            aria-disabled="true"
            class="nav-link"
          >Donec ac ligula auctor ipsum iaculis lobortis.</a>
        </li>
      </ul>
      <div class="step-content">
        <h3 class="h4 mb-4">Lorem ipsum dolor sit amet</h3>
        <div class="row">
          <div class="col-lg-8 col-xxl-9">
            <iframe
              class="video mb-4 mb-lg-0"
              src="https://www.youtube.com/embed/Fj7Vklv5nDk"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="allowfullscreen"
            ></iframe>
          </div>
          <div class="col-lg-4 col-xxl-3">
            <div class="row">
              <div class="col-sm-6 col-lg-12">
                <div class="document">
                  <div class="document-name d-flex justify-content-center align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 24 24"
                      class="icon icon-lg"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z"
                      />
                    </svg>
                    <p class="text-left ml-1 mb-0">lorem ipsum dolor sit amet</p>
                  </div>
                  <a class="btn btn-primary" href="#" target="_blank">download</a>
                </div>
              </div>
              <div class="col-sm-6 col-lg-12 document-divider">
                <div class="document">
                  <div class="document-name d-flex justify-content-center align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 24 24"
                      class="icon icon-lg"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"
                      />
                    </svg>
                    <p class="text-left ml-1 mb-0">module activity</p>
                  </div>
                  <a class="btn btn-primary" href="#" target="_blank">download</a>
                </div>
                <div class="document">
                  <p class="document-name">upload your answer</p>
                  <div class="form-control form-control-file mb-3">
                    <input id="input-task" type="file" />
                    <div class="p-1">
                      <span id="task-text"></span>
                    </div>
                    <button
                      class="btn btn-light btn-file"
                      data-display="#task-text"
                      data-target="#input-task"
                    >select file</button>
                  </div>
                  <button class="btn btn-primary" type="button">upload</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "../../styles/main.scss";

body {
  font-family: Ubuntu, "times new roman", times, roman, serif;
}
</style>
