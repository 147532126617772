<template>
  <div>
    <b-nav align="center" vertical tabs class="tags-nav">
      <b-nav-item
        v-for="(tag, index) in tags"
        :key="'item-'+index"
        @click="setTag(tag)"
        :active="selectedTag.text===tag.text"
      >
        <div class="tag-name">
          <strong v-if="selectedTag.text===tag.text">
            <div style="display: inline;">
              {{tag.text}}
            </div>
            <span class="selected-arrow" style="display: inline;">
              ▸
            </span>
            <div style="font-size: 10px; display: inline;">
             {{tag.subtags.filter(function (e) {return !e.exclude;}).length}} de {{tag.subtags.length}} activos
            </div>
          </strong>
          <span v-else>
            <div style="display: inline;">
              {{tag.text}}
            </div>
            <span style="display: inline;">
              ▸
            </span>
            <div style="font-size: 10px; display: inline;">
             {{tag.subtags.filter(function (e) {return !e.exclude;}).length}} de {{tag.subtags.length}} activos
            </div>
          </span>
        </div>
      </b-nav-item>
    </b-nav>
    <b-container style="padding-top: 10px !important;">
      <b-form-group>
        <b-form-checkbox
          v-for="(subtag, subindex) in selectedTag.subtags"
          v-model="value"
          :key="'subtag-'+subindex"
          :value="subtag"
          name="flavour-3a"
          @change="change_subtag_value(subindex)"
        >
          {{ subtag.text }}
        </b-form-checkbox>
      </b-form-group>
    </b-container>
  </div>
</template>

<script>
import postService from "../../../services/postService";

export default {
  name: 'TagsAndSubtags',
  data: function() {
    return {
      selectedTag: {},
      value: [],
      tags: [],
    };
  },
  created() {
    this.get_tags()
  },
  props: {
  },
  methods: {
    setTag(tag) {
      this.selectedTag=tag
    },
    get_posts: function() {
      this.$emit("get-posts");
    },
    get_files: function() {
      this.$emit("get-files");
    },
    change_subtag_value: function(subindex) {
      if (this.$store.state.login.token) {
        this.selectedTag.subtags[subindex].exclude = !this.selectedTag.subtags[subindex].exclude;
        // send ajax to save the preference
        postService
          .change_subtag_filter({
            text: this.selectedTag.subtags[subindex].text,
            exclude: this.selectedTag.subtags[subindex].exclude,
            type: "subtag"
          })
          .then(
            data => {
              if (this.$router.currentRoute.name==="media"){
                this.get_files()
              }
              else {
                this.get_posts();
              }
              this.set_excluded_subtags();
              this.$toasted.success(data.message);
            },
            error => {
              this.$toasted.error("Ha ocurrido un error al guardar la opción.");
              console.error(error);
            }
          );
      }
      else {
        this.$toasted.error("Debes estar inscrito en la plataforma para cambiar tus preferencias");
      }
    },
    get_tags: function() {
      postService.get_tags().then(
        data => {
          for (var i = 0; i < data.tags.length; i++) {
            var subtags = []
            for (var j = 0; j < data.tags[i].subtags.length; j++) {
              if (data.tags[i].subtags[j].text != "Publicidad")
                subtags.push(data.tags[i].subtags[j])
            }
            this.tags.push({ text: data.tags[i].text, subtags: subtags })
          }
          this.set_excluded_subtags()
        },
        error => {
          console.error(error);
        }
      );
    },
    set_excluded_subtags: function() {
      this.value = []
      this.tags.forEach(tag => {
        tag.subtags.forEach(subtag => {
          if (!subtag.exclude) {
            this.value.push(subtag)
          }
        })
      })
    },
  },
  watch: {
    // eslint-disable-next-line
    tags(new_value, old_value) {
      this.set_excluded_subtags();
    }
  }
};
</script>

<style lang="scss">
hr.filter-divider {
  border-top: 1px solid rgb(248, 181, 0);
}
.selected-arrow {
  color: $primary;
}
.tag-name {
  color: black;
  display: flex;
  justify-content: space-between !important;
}
.tags-nav {
  background-color: lightgray;
  color: black;
}
.item {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .toggle {
    width: 38px;
  }
  .description {
    width: calc(100% - 38px);
    padding-left: 12px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 22px;
  }
}
</style>
