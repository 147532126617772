<template>
  <b-container>
    <b-card-text>
      <b-spinner variant="success" type="grow" label="Spinning" v-if="loading_points"></b-spinner>
      <b-list-group v-else>
        <b-list-group-item
          v-for="(point, index) in points"
          :key="'point-'+index"
          href="#"
          class="d-flex justify-content-between align-items-center"
        >
          <span v-html="point.message"></span>
          <b-badge variant="primary" pill v-if="point.points>=0">+{{point.points}}</b-badge>
          <b-badge variant="danger" pill v-else>{{point.points}}</b-badge>
        </b-list-group-item>
      </b-list-group>
    </b-card-text>
  </b-container>
</template>

<script>
import userService from "../../../services/userService";

export default {
  created() {
    this.get_my_points();
  },
  data: function() {
    return {
      points: [],
      loading_points: true
    };
  },
  methods: {
    get_my_points: function() {
      userService.get_my_points().then(
        data => {
          this.points = data.points;
          this.loading_points = false;
        },
        error => {
          this.$toasted.error(
            "Ha ocurrido un error al obtener el historial puntos",
          );
          console.error(error);
        }
      );
    }
  }
};
</script>

<style>
.bd-sidebar {
  border-right: 1px solid #eee;
}
.bd-toc {
  border-left: 1px solid #eee;
}
.no-hover-info {
  border-color: #17a2b8;
  color: #17a2b8;
}
.no-hover-info:hover {
  border-color: #17a2b8;
  color: #17a2b8;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-disabled {
  cursor: not-allowed;
}
.no-login {
  border-radius: 0.3rem;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(23, 162, 184);
  text-align: center;
}
.no-login-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.border-green {
  border: 1px solid rgba(23, 162, 184, 0.33);
}
</style>