<template>
  <div>
    <ModalEmbed
      v-on:points-change="$emit('points-change')"
      @change="getCommentData"
      ref="modal-embed" />

    <div class="header">
      <div class="avatar">
        <img :src="comment.user.thumbnail" />
      </div>
      <div class="title">
        <div>
          <h4>{{comment.user.first_name}} {{comment.user.last_name}}</h4>
          <p>{{$moment(comment.created_at).fromNow()}}</p>
        </div>
      </div>
      <div class="right-options">
        <div
          class="reward-badge tags"
          v-if="post.reward > 0 && $store.state.login.email === post.user.email
                && comment.user.email !== $store.state.login.email">
          <b-button
            size="sm"
            variant="secondary"
            @click="give_points(comment.id)"
          >
            Otorgar puntos
          </b-button>
        </div>
        <span v-if="comment.isBest">Mejor Comentario</span>
        <span v-if="!comment.can_like && $store.state.login.token">
          Valorado
          <v-icon name="c-star-on" class="text-primary" />
        </span>
        <span v-else style="cursor: pointer;" @click="like_comment(1, comment.id)">
          Valorar
          <v-icon name="c-star-off" class="text-primary" />
        </span>
      </div>
    </div>
    <p class="content">{{comment.text}}</p>

    <div v-if="comment.files && comment.files.length" class="docs-data">
      <ul>
        <li
          v-for="(doc, index) in comment.files"
          :key="'doc-'+index"
          v-on:click="$refs['modal-embed'].open({
            commentid: comment.id,
            openUrl: doc.view_link,
            title: doc.short_name,
            stars: doc.rate,
            fileid: doc.id,
          })"
        >
          <v-icon name="c-doc" class="text-primary" />
          {{doc.short_name}}
        </li>
      </ul>
    </div>

    <div class="to-answer simple-list">
      <b-container style="padding-left: 0px !important; padding-right: 0px !important">
        <b-row class="to-answer simple-list">
          <b-col class="message-col" cols="8">
            <b-row class="message-row">
              <div class="input-content">
                <b-form-input
                  v-model="text"
                  placeholder="Escribir Respuesta..."
                  class="input"
                  v-on:keyup.enter="publishMessage"
                />
                <b-button
                  class="button radius-box-24 bold"
                  variant="primary"
                  @click="publishMessage"
                >Responder</b-button>
              </div>
            </b-row>
          </b-col>
          <b-col cols="4" style="padding-left: 0px !important; padding-right: 0px !important">
            <ul>
              <li>
                <v-icon name="c-star-on" class="text-primary icon"/>
                {{comment.likes}}
              </li>
              <li>
                <span class="text-primary cursor-pointer" @click="toggleAnswers">
                  {{this.messages.length}} Respuestas
                </span>
              </li>
            </ul>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-if="showAnwsers" class="answers">
      <div
        v-for="(message, index) in messages"
        class="item-comment"
        :key="'message-'+index"
      >
        <div class="header">
          <div class="avatar">
            <img :src="message.user.thumbnail" />
          </div>
          <div class="title">
            <div>
              <h4>{{message.user.first_name}} {{message.user.last_name}}</h4>
              <p>{{$moment(message.created_at).fromNow()}}</p>
            </div>
          </div>
          <div class="right-options">
            <span>
              <v-icon name="c-star-on" class="text-primary" />
              {{message.likes}}
            </span>
          </div>
        </div>
        <p class="content">{{message.text}}</p>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
import postService from "../../../services/postService";
import ModalEmbed from "../modals/ModalEmbed";

export default {
  components: {
    ModalEmbed,
  },
  created() {
    this.get_messages()
  },
  data: function() {
    return {
      text: "",
      messages: [],
      expanded: false,
      reported: false,
      deleted: false,
      is_comment: true,
      message_id: null,
      writeMessage: false,
      showAnwsers: false,
    };
  },
  props: ["comment", "post", "index"],
  watch: {
    comment: function() {
      this.get_messages()
    },
  },
  methods: {
    toggleAnswers: function() {
      this.showAnwsers = !this.showAnwsers;
    },
    getCommentData: function() {
      postService.getCommentData(this.comment.id).then(
        data => {
          this.comment = data;
          this.messages = this.comment.messages;
        }
      )
    },
    give_points: function(comment_id) {
      postService.give_points({ id: comment_id}).then(
        () => {
          this.$emit("change")
        }
      )
    },
    publishMessage: function() {
      if (this.$store.state.login.token) {
        if (this.text) {
          postService
            .publish_message({ text: this.text, comment: this.comment.id })
            .then(
              data => {
                this.$toasted.success(data.message);
                this.text = "";
                this.get_messages();
                this.showAnwsers = true;
              },
              error => {
                this.$toasted.error("Ha ocurrido un error al publicar");
                console.error(error);
              }
            );
        } else {
          this.$toasted.error("Debes escribir algo!");
        }
      } else {
        this.$toasted.error("Debes estar inscrito en la plataforma!");
      }
    },
    get_messages: function() {
      postService.get_messages({ comment: this.comment.id }).then(
        data => {
          this.messages = data.messages;
        },
        error => {
          console.error(error);
        }
      );
    },
    like_comment: function(like, comment) {
      if (this.$store.state.login.token) {
        postService.like_comment({ like: like, comment: comment }).then(
          data => {
            this.$toasted.success(data.message);
            this.getCommentData();
          },
          error => {
            var message = "Ha ocurrido un error al publicar";
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              message = error.response.data.message;
            }
            this.$toasted.error(message);
          }
        );
      } else {
        this.$toasted.error("Debes estar inscrito en la plataforma!");
      }
    },
    like_message: function(like, message) {
      if (this.$store.state.login.token) {
        postService.like_message({ like: like, message: message }).then(
          data => {
            this.$toasted.success(data.message);
          },
          error => {
            var message = "Ha ocurrido un error al publicar";
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              message = error.response.data.message;
            }
            this.$toasted.error(message);
          }
        );
      } else {
        this.$toasted.error("Debes estar inscrito en la plataforma!");
      }
    },
    calculate_likes: function(likes, dislikes) {
      return likes - dislikes;
    },
    viewFile: function(links) {
      this.$emit("view-file", links);
    },
    hide_login_dropdown: function() {
      this.$refs.loginDropdown.hide();
    },
    show_modal_report: function() {
      this.is_comment = true;
      this.$refs["modal-report"].show();
    },
    show_modal_delete: function() {
      this.is_comment = true;
      this.$refs["modal-delete"].show();
    },
    show_modal_report_message: function(message_id) {
      this.message_id = message_id;
      this.is_comment = false;
      this.$refs["modal-report"].show();
    },
    show_modal_delete_message: function(message_id) {
      this.message_id = message_id;
      this.is_comment = false;
      this.$refs["modal-delete"].show();
    },
    report_comment: function(report_type) {
      postService
        .report_comment({ comment: this.comment.id, report_type: report_type })
        .then(
          data => {
            this.$toasted.success(data.message);
            this.reported = true;
          },
          error => {
            var message = "Ha ocurrido un error al denunciar";
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              message = error.response.data.message;
            }
            this.$toasted.error(message);
          }
        );
      this.$refs["modal-report"].hide();
    },
    delete_comment: function() {
      postService.delete_comment({ comment: this.comment.id }).then(
        data => {
          this.$toasted.success(data.message);
          this.reported = true;
        },
        error => {
          var message = "Ha ocurrido un error.";
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            message = error.response.data.message;
          }
          this.$toasted.error(message);
        }
      );
      this.$refs["modal-delete"].hide();
    },
    report_message: function(report_type) {
      postService
        .report_message({ message: this.message_id, report_type: report_type })
        .then(
          data => {
            this.$toasted.success(data.message);
            this.get_messages();
          },
          error => {
            var message = "Ha ocurrido un error al denunciar";
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              message = error.response.data.message;
            }
            this.$toasted.error(message);
          }
        );
      this.$refs["modal-report"].hide();
    },
    delete_message: function() {
      postService.delete_message({ message: this.message_id }).then(
        data => {
          this.$toasted.success(data.message);
          this.get_messages();
        },
        error => {
          var message = "Ha ocurrido un error.";
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            message = error.response.data.message;
          }
          this.$toasted.error(message);
        }
      );
      this.$refs["modal-delete"].hide();
    }
  }
};
</script>

<style lang="scss">

.cursor-pointer {
  cursor: pointer;
}

.header {
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;

  .avatar {
    width: 48px;
    height: 100%;

    img {
      width: 100%;
      border-radius: 50%;
    }
  }
  .title {
    width: calc(100% - 48px - 106px);
    height: 100%;
    padding: 0 12px;
    display: flex;
    align-items: center;

    h4,
    p {
      margin: 0;
      padding: 0;
    }
    h4 {
      font-size: 14px;
      font-weight: bold;
    }
    p {
      font-size: 11px;
    }
  }
  .right-options {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;

    .assessment {
      transition: all 0.256s;
      cursor: pointer;
      width: 106px;
      margin-right: 32px;
      text-align: right;
      font-size: 14px;

      .icon {
        margin-top: -6px;
      }
    }
    .assessment.on {
    }
    .assessment.off:hover {
      transition: all 0.256s;
      color: $secondary-dark;
    }

    .more {
      position: absolute;
      top: -12px;
      right: -12px;
    }
  }
}

.reward-badge {
  margin-bottom: 5px;
}

.tags {
  button {
    font-size: 10px;
    padding-left: 12px;
    padding-right: 12px;
    height: 24px;
    font-weight: bold;
  }
}

.content {
  width: 100%;
  padding: 8px 0;
  margin: 0;
  font-size: 15px;
  text-align: justify;
}

.to-answer.simple-list {
  display: flex;
  justify-content: flex-end;
}

.message-row {
  padding-top: 10px !important;
  padding-bottom: 10px !important;

  .message-col {
    padding-left: 1px !important;
    padding-right: 5px !important;
  }
}

.input-content {
  width: 100%;
  white-space: nowrap;

  .input {
    width: calc(100% - 78px);
    border: none;
    border-bottom: solid 2px #eee;
    display: inline;
  }
  .button {
    width: 78px;
    font-size: 11px;
    display: inline;
    padding: 3px 0;
  }
}

</style>
