<template>
  <b-container>
  </b-container>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "../../styles/main.scss";
</style>